import React from "react";
import { withTranslation } from 'react-i18next';

import { connect } from "react-redux";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import { apiLoginWithCredentials, apiLogonCompleted } from "../../middleware/auth/duck/api"
import { apiLoadProfile } from "../../middleware/customer/duck/api"

import { encryptAES } from "../../utilities/crypt"
import * as system from '../../utilities/system'
import './loginForm.css';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customerCode: "",
            usernameOrEmail: "",
            password: "",
            loginMessage: "",
            loginActive: false
        }
    }

    t = (key) => {
        return this.props.t(key)
    }

    handleValidateForm = () => {
        const { customerCode, usernameOrEmail, password } = this.state
        if (customerCode === '') {
            this.setState({ loginMessage: this.t('app.pages.loginForm.messages.missingCustomerCode') })
            return false
        }
        if (usernameOrEmail === '') {
            this.setState({ loginMessage: this.t('app.pages.loginForm.messages.missingUsername') })
            return false
        }
        if (password === '') {
            this.setState({ loginMessage: this.t('app.pages.loginForm.messages.missingPassword') })
            return false
        }
        return true
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ loginMessage: "" })
    }

    handleAuthenticate = (e) => {
        e.preventDefault();

        if (!this.handleValidateForm()) {
            return
        }

        const encryptedPassword = encryptAES(this.state.password)

        this.setState({loginMessage: this.t('app.pages.loginForm.messages.authInProgress'), loginActive: true})
        this.props.apiLoginWithCredentials(this.state.customerCode, this.state.usernameOrEmail, encryptedPassword)

        setTimeout(() => {
            this.setState({
                loginActive: false,
                loginMessage: !this.props.isAuthUser ? this.props.authMessage : this.t('app.pages.loginForm.messages.authSucceeded')
            });
        }, 500);
        
        setTimeout(() => {
            this.setState({
                loginActive: false,
                loginMessage: !this.props.isAuthUser ? this.props.authMessage : this.t('app.pages.loginForm.messages.loadingProfile')
            })
            
            if (![system.roles.ROLE_SUPERADMIN].includes(this.props.userRole)) {
                this.props.apiLoadProfile(this.state.customerCode, "")
            }
        }, 500);
    }

    handleRedirect = () => {
        if (this.props.isAuthUser) {
            setTimeout(() => {
                this.setState({
                    loginMessage: this.t('app.pages.loginForm.messages.loadingDashboard')
                })

                this.props.apiLogonCompleted()
                window.location = "/dashboard"
            }, 1200);
        }
    }

    handleEnterKey = (e) => {
        if (e.charCode === 13) {
            this.handleAuthenticate(e)
        }
    }

    render() {
        const { customerCode, usernameOrEmail, password, loginMessage } = this.state

        return (
            <div className="form-container">
                {this.handleRedirect()}
                <Form className="login-form">
                    <h5><span className="h5-form">{this.t('app.pages.loginForm.headlines.logonTitle')}</span></h5>
                    <div className="v-space-small"></div>
                    <FormGroup>
                        <Label className="float-left">{this.t('app.pages.loginForm.labels.customerId')}</Label>
                        <Input type="text" name="customerCode" placeholder={this.t('app.pages.loginForm.placeholders.customerCode')} value={customerCode} onChange={this.handleChange} autoFocus></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label className="float-left">{this.t('app.pages.loginForm.labels.username')}</Label>
                        <Input type="text" name="usernameOrEmail" placeholder={this.t('app.pages.loginForm.placeholders.usernameOrEmail')} value={usernameOrEmail} onChange={this.handleChange}></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label className="float-left">{this.t('app.pages.loginForm.labels.password')}</Label>
                        <Input type="password" name="password" placeholder={this.t('app.pages.loginForm.placeholders.password')} value={password} onChange={this.handleChange} onKeyPress={this.handleEnterKey} autoComplete="off"></Input>
                    </FormGroup>
                    <div className={this.state.loginActive || this.props.isAuthUser ? "login-success" : "login-failed"}>{loginMessage}</div>
                    <Button className="std-button" onClick={this.handleAuthenticate}>{this.t('app.pages.loginForm.buttons.login')}</Button>
                    <a className="std-link" href="/register">{this.t('app.pages.loginForm.strings.registerAsCustomer')}</a>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    // map current [store state] variable to the props [variable] of a component
    isAuthUser: state.session.isAuthUser,
    isLogonCompleted: state.session.isLogonCompleted,
    authMessage: state.session.error,
    userRole: state.session.profile.roleName || 'Standard'
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiLoginWithCredentials: (customerCode, login, password) => dispatch(apiLoginWithCredentials(customerCode, login, password)),
    apiLogonCompleted: () => dispatch(apiLogonCompleted()),
    apiLoadProfile: (customerCode, applicationCode) => dispatch(apiLoadProfile(customerCode, applicationCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginForm));
