import React, { useState } from 'react'
import { Row, Col, Form, FormGroup, Input, Label, Button, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'

import { apiInsertFolder, apiUpdateFolder, apiSelectFolderTypes } from "../../../../middleware/folder/duck/api"
import { setFieldValidationError, setFieldValidationReset } from "../../../../utilities/dom"

import '../../mainBoard.css'
import './folderFormView.css'

const deepEqual = require('deep-equal')

const FolderFormView = (props) => {
    const { t } = useTranslation();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [selectedItem, setSelectedItem] = useState(props.folderType || 'Default');

    const usePrevious = value => {
        const ref = React.useRef();
     
        React.useEffect(() => {
            ref.current = value;
        }, [value]);
     
        return ref.current;
    }
     
    const usePropState = datas => {
        const [dataset, setDataset] = useState(datas);
        const prevDatas = usePrevious(datas);
     
        const handleChangeDataset = data => setDataset(data);
     
        React.useEffect(() => {
            if (!deepEqual(datas, prevDatas)) {
                setDataset(datas);
                setFieldValidationReset('folderName')
                setPageInitForm()
            }
        }, [datas, prevDatas]);
     
        return [
            dataset,
            handleChangeDataset
        ]
    }

    const [componentState, setComponentState] = usePropState({
            customerCode: props.customerCode,
            folderId: props.folderId,
            folderName: props.folderName,
            folderDisplayName: props.folderDisplayName,
            folderDescription: props.folderDescription,
            folderType: props.folderType || selectedItem,
            folderCreator: props.login,
            isActive: true,
            isLocked: false,
        })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setComponentState({ ...componentState, [name]: value });
        setFieldValidationReset(name)
    };

    const handleValidateForm = () => {
        const { folderName, folderType } = componentState
        
        if (folderName === '') {
            setFieldValidationError("folderName")
            return false
        }
        if (folderType === '') {
            setFieldValidationError("folderType")
            return false
        }

        return true
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()

        if (!handleValidateForm()) {
            return
        }

        if (componentState.folderId === null) {
            props.apiInsertFolder(componentState.customerCode,
                componentState.folderType, componentState.folderName, componentState.folderDescription,
                componentState.folderDisplayName, componentState.isActive, componentState.isLocked, componentState.folderCreator)
            
            setComponentState({ ...componentState,
                folderId: null,
                folderType: decodeFolderType('Default'),
                folderName: '',
                folderDisplayName: '',
                folderDescription: ''
            });

            if (props.onRefreshTableHandler !== null) {
                setTimeout(() => props.onRefreshTableHandler(), 500)
            }
        } else {
            console.log('Updating the folder with id = ' + componentState.folderId)
            props.apiUpdateFolder(componentState.customerCode, componentState.folderId,
                encodeFolderType(componentState.folderType), componentState.folderName, componentState.folderDescription,
                componentState.folderDisplayName, componentState.isActive, componentState.isLocked, componentState.folderOwner)

            if (props.onRefreshTableHandler !== null) {
                setTimeout(() => props.onRefreshTableHandler(), 500)
            }
        }
    }

    const encodeFolderType = (folderType) => {
        if (folderType === t('app.pages.folderListView.buttons.dropdown.selectFolderType.menuitemdef')) {
            return 'Default'
        }
        else {
            return folderType
        }
    }

    const decodeFolderType = (folderType) => {
        if (folderType === "Default") {
            return t('app.pages.folderListView.buttons.dropdown.selectFolderType.menuitemdef')
        }
        else {
            return folderType
        }
    }

    const setPageInitForm = () => {
        props.apiSelectFolderTypes()
    }

    return (
        <Form className="folder-view-form" autoComplete="off">
            <Row style={{marginBottom: '0px', width: 'fit-content'}}>
                <Col xs="15">
                    <Label className="form-view-title">{t('app.pages.folderListView.title')}</Label>
                </Col>
            </Row>
            <Row style={{width: 'fit-content'}}>
                <Col xs="2">
                    <FormGroup>
                        <Label>{t('app.pages.folderListView.labels.folderType')}</Label>
                        <Dropdown className={dropdownOpen ? "transparency-dropdown-after" : "transparency-dropdown"} isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle caret>
                                {decodeFolderType(componentState.folderType)}
                            </DropdownToggle>
                            <DropdownMenu>
                                {props.folderTypeList.map((item, index) => {
                                    return (
                                        <DropdownItem key={index} onClick={() => {
                                            setSelectedItem(item.key);
                                            setComponentState({ ...componentState, folderType: item.key });
                                        }}>{decodeFolderType(item.value)}</DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </FormGroup>
                </Col>
                <Col xs="3">
                    <FormGroup>
                        <Label>{t('app.pages.folderListView.labels.name')}</Label>
                        <Input className="transparency" type="text" name="folderName" placeholder={t('app.pages.folderListView.placeholders.name')} value={componentState.folderName} onChange={handleOnChange} autoFocus></Input>
                    </FormGroup>
                </Col>
                <Col xs="4">
                    <FormGroup>
                        <Label>{t('app.pages.folderListView.labels.displayName')}</Label>
                        <Input className="transparency" type="text" name="folderDisplayName" placeholder={t('app.pages.folderListView.placeholders.displayName')} value={componentState.folderDisplayName} onChange={handleOnChange}></Input>
                    </FormGroup>
                </Col>
                <Col xs="3">
                    <FormGroup>
                        <Label>{t('app.pages.folderListView.labels.description')}</Label>
                        <Input className="transparency" type="text" name="folderDescription" placeholder={t('app.pages.folderListView.placeholders.description')} value={componentState.folderDescription} onChange={handleOnChange}></Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{width: 'fit-content'}}>
                <Col xs="2">
                    <FormGroup>
                        <Button className="transparency btn-large" type="button" onClick={handleOnSubmit}>{componentState.folderId === null ? t('app.pages.folderListView.buttons.create') : t('app.pages.folderListView.buttons.modify')}</Button>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerCode: state.session.customer,
    login: state.session.user,
    folderId: state.folder.folderId,
    folderType: state.folder.folderType,
    folderName: state.folder.folderName,
    folderDisplayName: state.folder.folderDisplayName,
    folderDescription: state.folder.folderDescription,
    folderTypeList: state.folder.folderTypeList
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiInsertFolder: (customerCode, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderCreator) =>
        dispatch(apiInsertFolder(customerCode, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderCreator)),
    apiUpdateFolder: (customerCode, folderId, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderOwner) =>
        dispatch(apiUpdateFolder(customerCode, folderId, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderOwner)),
    apiSelectFolderTypes: () =>
        dispatch(apiSelectFolderTypes())
})

export default connect(mapStateToProps, mapDispatchToProps)(FolderFormView);