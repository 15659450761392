import React from 'react'
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SideBarData } from './sideBarData'
import SubMenu from './sideBarSubMenu'
import { IconContext } from 'react-icons/lib'

import { apiSelectDeviceList } from "../../../middleware/customer/duck/api"
import { apiSetSideBarVisibility } from "../../../middleware/app/duck/api"
import * as system from '../../../utilities/system'

import { setElementClassAdd, setElementClassRemove, setElementIdProperty } from '../../../utilities/dom'

import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'

import './sideBar.css'

const Nav = styled.div`
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavIcon = styled(Link)`
    margin-left: 2rem;
    font-size: 1.5rem;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const SideBarNav = styled.nav`
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    width: 300px;
    height: calc(100vh - 228px);
    display: flex;
    justify-content: center;
    position: fixed;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    padding-top: 20px;
    z-index: 10;
`;

const SideBarWrap = styled.div`
    width: 100%;
`;

const SideBar = (props) => {
    const { t } = useTranslation();

    const showSideBar = () => {
        if (!props.sidebarVisibility) {
            setElementClassAdd("content-search", "content-search-offset")
            props.apiSetSideBarVisibility(true)
        } else {
            setElementClassRemove("content-search", "content-search-offset")
            props.apiSetSideBarVisibility(false)
        }
    }
    setElementIdProperty("root", "overflow-y", "hidden");

    const handleLoadDeviceList = () => {
        if (![system.roles.ROLE_SUPERADMIN].includes(props.userRole)) {
            props.apiSelectDeviceList(
                props.customerCode,
                "PG"
            )
        }
    }
    
    handleLoadDeviceList()

    return (
        <div className="sidebar">
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav>
                    {!props.sidebarVisibility ? (
                        <NavIcon to="#" data-balloon-visible aria-label={t('app.layout.hints.sidebarMenuHint')} data-balloon-pos="right" className="tooltip-orange">
                            <FaIcons.FaBars onClick={showSideBar} />
                        </NavIcon>
                    ) : (
                        <NavIcon to="#">
                            <AiIcons.AiOutlineClose onClick={showSideBar} />
                        </NavIcon>
                    )}
                </Nav>
                <SideBarNav sidebar={props.sidebarVisibility} className="sidebar-nav-main">
                    <SideBarWrap>
                        {SideBarData.map((item, index) => {
                            return <SubMenu path={props.path} item={item} key={index}/>;
                        })}
                    </SideBarWrap>
                </SideBarNav>
            </IconContext.Provider>
        </div>
    )
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerCode: state.session.customer,
    userRole: state.session.profile.roleName || 'Standard',
    sidebarVisibility: state.application.ui.sidebar.visibility
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSelectDeviceList: (customerCode, applicationCode) =>
        dispatch(apiSelectDeviceList(customerCode, applicationCode)),
    apiSetSideBarVisibility: (status) =>
        dispatch(apiSetSideBarVisibility(status))
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);