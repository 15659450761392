import React from 'react';
import LoginForm from "../components/ui/loginForm";
import '../app.css';

export default function SignUp() {
	return (
		<div className="sign-up">
			<LoginForm />
		</div>
	);
}
