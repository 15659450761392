import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import { ButtonEx } from './ui/button';
import { apiLogout } from "../middleware/auth/duck/api"
import { setElementIdProperty } from '../utilities/dom'

import './navbar.css';

function Navbar({isAuthUser, isLogonCompleted, apiLogout}) {
	const [click, setClick] = useState(false);
	const [button, setButton] = useState(true);

	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);

	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(false);
		} else {
			setButton(true);
		}
	};

	const { t } = useTranslation();

	useEffect(() => {
		showButton();
	}, []);

	window.addEventListener('resize', showButton);

	const handleSignOut = (e) => {
		e.preventDefault();
		setElementIdProperty("root", "overflow-y", "auto")
		apiLogout();
	};
	
	return (
		<>
			<nav className='navbar' style={{justifyContent: 'flex-end'}}>
				<div className='navbar-container'>
					<Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
						DzikaApka
						<i className='fab fa-watchman-monitoring'></i>
					</Link>
					<div className='menu-icon' onClick={handleClick}>
						<i className={click ? 'fas fa-times' : 'fas fa-bars'} />
					</div>
					<ul className={click ? 'nav-menu active' : 'nav-menu'}>
						<li className='nav-item'>
							{ !isAuthUser ? (
								<Link to='/' className='nav-links' onClick={closeMobileMenu}>
									<i className="fas fa-home"></i>
								</Link>
							) : (
								<Link to='/dashboard' className='nav-links' onClick={closeMobileMenu}>
									{t('app.layout.header.menuitem1')}
								</Link>
							) }
						</li>
						{ isLogonCompleted ? (
							<></>
						) : (
							<li className='nav-item'>
								<Link
									to='/register'
									className='nav-links'
									onClick={() => {
										closeMobileMenu()
										setElementIdProperty("root", "overflow-y", "auto");
										sessionStorage.removeItem('registerSelectedLicense')
										}}
									>
									{t('app.layout.header.menuitem2')}
								</Link>
							</li>
						)}

						{/* { isLogonCompleted && !isAuthUser ? (
							<li className='nav-item'>
								<Link
									to='/register'
									className='nav-links'
									onClick={() => {
										closeMobileMenu()
										setElementIdProperty("root", "overflow-y", "auto");
										}}
									>
									{t('app.layout.header.menuitem2')}
								</Link>
							</li>
						) : (
							<></>
						)} */}
						<li className='nav-item'>
							<Link
								to='/support'
								className='nav-links'
								onClick={closeMobileMenu}
							>
								{t('app.layout.header.menuitem3')}
							</Link>
						</li>
						<li>
							{ !isAuthUser ? (
								<Link
									to='/sign-up'
									className='nav-links-mobile'
									onClick={closeMobileMenu}
								>
									{t('app.layout.header.buttons.login')}
								</Link>
							) : (
								<Link
									to='/logout'
									className='nav-links-mobile'
									onClick={handleSignOut}
								>
									{t('app.layout.header.buttons.logout')}
								</Link>
							) }
						</li>
					</ul>
					{ !isAuthUser ? (
						button && <ButtonEx buttonStyle='btn--outline' actionName='/sign-up'>{t('app.layout.header.buttons.login')}</ButtonEx>
					) : (
						button && <ButtonEx buttonStyle='btn--outline' actionName='/logout' onClick={handleSignOut}>{t('app.layout.header.buttons.logout')}</ButtonEx>
					) }
				</div>
			</nav>
		</>
	);
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    isAuthUser: state.session.isAuthUser,
	isLogonCompleted: state.session.isLogonCompleted
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiLogout: () => dispatch(apiLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
