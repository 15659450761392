import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

import { setFieldValidationError, setFieldValidationReset } from "../../../../../utilities/dom"

import "./modalImageSelected.css";

const ModalImageSelected = (props) => {
    const { t } = useTranslation();

    const {
        popupOpenWith,
        popupOpenWithClass,
        popupOpenBtnTitle,
        popupHeaderTitle,
        popupBodyText,
        popupSize,
        popupClassName,
        popupHeaderIconClassName,
        popupSubmitBtnText,
        popupCancelBtnText,
        popupMediaTags,
        popupMediaCurrentTag,
        popupOnSubmitHandler
    } = props;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    const [tagFolderDropdownOpen, setTagFolderDropdownOpen] = useState(false);
    const toggleTagFolder = () => setTagFolderDropdownOpen(prevState => !prevState);
    const [tagFolder, setTagFolder] = useState(t('app.pages.deviceInfo.modals.modalImageSelected.buttons.dropdown.selectTag.menuitemdef'));
    const [tagFolderType, setTagFolderType] = useState('Default');

    const handleValidateForm = () => {
        if ((popupMediaTags.length > 0) && (tagFolder === "" || tagFolder === t('app.pages.deviceInfo.modals.modalImageSelected.buttons.dropdown.selectTag.menuitemdef'))) {
            setFieldValidationError("tagFolder")
            return false
        }

        return true
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()
        if (!handleValidateForm()) {
            return
        }

        popupOnSubmitHandler(tagFolder, tagFolderType)
        toggle()
    }

    return (
        <>
            {(popupOpenWith === "Icon") ? (
                <i className={popupOpenWithClass} title={popupOpenBtnTitle} onClick={toggle}></i>
            ) : (
                <div className={popupOpenWithClass} onClick={toggle}>{popupOpenBtnTitle}</div>
            )}

            <Modal isOpen={modal} toggle={toggle} className={popupClassName} size={popupSize || "sm"} draggable={true} centered={true}>
                <ModalHeader toggle={toggle} close={closeBtn}><i className={popupHeaderIconClassName}></i>&nbsp;{popupHeaderTitle}</ModalHeader>
                <ModalBody>
                    {popupBodyText}
                    {(popupMediaTags.length > 0) ? (
                        <>
                            <div className="divider-small"></div>
                            <Dropdown className={tagFolder} isOpen={tagFolderDropdownOpen} toggle={toggleTagFolder}>
                                <DropdownToggle className="tagFolder" caret>
                                    {tagFolder}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>{t('app.pages.deviceInfo.modals.modalImageSelected.buttons.dropdown.selectTag.header')}</DropdownItem>
                                    {popupMediaTags.map((item, index) => {
                                        if (popupMediaCurrentTag !== item.name) {
                                            return (
                                                <DropdownItem onClick={() => {
                                                        setTagFolder(item.name)
                                                        setTagFolderType(item.folderType)
                                                        setFieldValidationReset("tagFolder")
                                                    }
                                                } key={index}>{item.name}</DropdownItem>
                                            )
                                        }
                                        else {
                                            return (<div key={index}></div>)
                                        }
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </>
                    ) : (
                        <></>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" className="btn-large" onClick={handleOnSubmit}>{popupSubmitBtnText || "Close"}</Button>{' '}
                    <Button color="secondary" className="transparency btn-large" onClick={toggle}>{popupCancelBtnText}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ModalImageSelected;