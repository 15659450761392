// Author: Teldasoft(c) 2021
// Module: actions.js
// Description: Action creators for 'customer' middleware

import types from './types'

const loadProfile = (customerId, customerSymbol, customerType, customerTypeName, deviceCount, deviceMax, deviceMin,
    displayName, email, licenseInfo, licenseSymbol, licenseType, licenseTypeName, licenseFrom, licenseTo, storageMax,
    storageMin, storageSize, storageUnit, website, licenseExpired) => {
    return {
        type: types.API_CUSTOMER_LOAD_PROFILE,
        payload: {
            customerId: customerId,
            customerSymbol: customerSymbol,
            customerType: customerType,
            customerTypeName: customerTypeName,
            deviceCount: deviceCount,
            deviceMax: deviceMax,
            deviceMin: deviceMin,
            displayName: displayName,
            email: email,
            licenseInfo: licenseInfo,
            licenseSymbol: licenseSymbol,
            licenseType: licenseType,
            licenseTypeName: licenseTypeName,
            licenseFrom: licenseFrom,
            licenseTo: licenseTo,
            licenseExpired: licenseExpired,
            storageMax: storageMax,
            storageMin: storageMin,
            storageSize: storageSize,
            storageUnit: storageUnit,
            website: website
        }
    };
};

const loadLicenseUsage = (storageItemsCount, storageConnectedDeviceCount, licenseCode, licenseId, storageAvail,
    storageExceededWarnL1, storageExceededWarnL2, storageHard, storageSoft, storageUse,
    storageUseHardPcnt, storageUsePcnt, storageUseSoftPcnt) => {
        return {
            type: types.API_CUSTOMER_LOAD_LICENSE_USAGE,
            payload: {
                licenseCode: licenseCode,
                licenseId: licenseId,
                storageItemsCount: storageItemsCount,
                storageConnectedDeviceCount: storageConnectedDeviceCount,
                storageAvail: storageAvail,
                storageExceededWarnL1: storageExceededWarnL1,
                storageExceededWarnL2: storageExceededWarnL2,
                storageHard: storageHard,
                storageSoft: storageSoft,
                storageUse: storageUse,
                storageUseHardPcnt: storageUseHardPcnt,
                storageUsePcnt: storageUsePcnt,
                storageUseSoftPcnt: storageUseSoftPcnt
            }    
    };
};

const selectDevices = (deviceList) => {
    return {
        type: types.API_CUSTOMER_SELECT_DEVICES,
        payload: {
            deviceList: deviceList
        }
    };
};

const selectDeviceList = (deviceList) => {
    return {
        type: types.API_CUSTOMER_SELECT_DEVICE_SUMMARY,
        payload: {
            deviceSummaryList: deviceList
        }
    };
};

const selectDevice = (deviceTypeName, statusName, name, deviceMappingNo, imei, deviceEmail, phoneNo, deviceTypeCode) => {
    return {
        type: types.API_CUSTOMER_SELECT_DEVICE_ROW,
        payload: {
            deviceTypeName: deviceTypeName,
            deviceTypeCode: deviceTypeCode,
            statusName: statusName,
            name: name,
            deviceMappingNo: deviceMappingNo,
            imei: imei,
            deviceEmail: deviceEmail,
            phoneNo: phoneNo
        }
    };
}

const insertDevice = (status) => {
    return {
        type: types.API_CUSTOMER_INSERT_DEVICE,
        payload: {
            insertStatus: status
        }
    };
}

const activateDevice = (status) => {
    return {
        type: types.API_CUSTOMER_ACTIVATE_DEVICE,
        payload: {
            activationStatus: status
        }
    };
}

const deactivateDevice = (status) => {
    return {
        type: types.API_CUSTOMER_DEACTIVATE_DEVICE,
        payload: {
            deactivationStatus: status
        }
    };
}

const removeDevice = (status) => {
    return {
        type: types.API_CUSTOMER_REMOVE_DEVICE,
        payload: {
            removalStatus: status
        }
    };
}

const activateAllDevices = (status) => {
    return {
        type: types.API_CUSTOMER_ACTIVATE_ALL_DEVICE,
        payload: {
            activationStatus: status
        }
    };
}

const deactivateAllDevices = (status) => {
    return {
        type: types.API_CUSTOMER_DEACTIVATE_ALL_DEVICE,
        payload: {
            deactivationStatus: status
        }
    };
}

const editDevice = (status) => {
    return {
        type: types.API_CUSTOMER_EDIT_DEVICE,
        payload: {
            modificationStatus: status
        }
    };
}

const refreshDevices = () => {
    return {
        type: types.API_CUSTOMER_REFRESH_DEVICES,
        payload: {}
    };
};

const cleanupProfile = () => {
    return {
        type: types.API_CUSTOMER_CLEANUP_PROFILE,
        payload: {}
    }
};

const error = (msg) => {
    return {
        type: types.API_CUSTOMER_LOAD_ERROR,
        payload: msg
    };
};

const saSelectCustomer = (statusName, customerName, externalCode, registrationDate, email, phone) => {
    return {
        type: types.API_SA_CUSTOMER_SELECT_ROW,
        payload: {
            statusName: statusName,
            customerName: customerName,
            externalCode: externalCode,
            registrationDate: registrationDate,
            email: email,
            phone: phone
        }
    };
}

const saSelectCustomerList = (customerList) => {
    return {
        type: types.API_SA_CUSTOMER_SELECT_ALL,
        payload: {
            customerList: customerList
        }
    };
};

const saRejectCustomer = (response) => {
    return {
        type: types.API_SA_CUSTOMER_REJECT,
        payload: {
            response: response
        }
    };
}

const saVerifyCustomer = (response) => {
    return {
        type: types.API_SA_CUSTOMER_VERIFY,
        payload: {
            response: response
        }
    };
}

const saApproveCustomer = (response) => {
    return {
        type: types.API_SA_CUSTOMER_APPROVE,
        payload: {
            response: response
        }
    };
}

const saSuspendCustomer = (response) => {
    return {
        type: types.API_SA_CUSTOMER_SUSPEND,
        payload: {
            response: response
        }
    };
}

const saReactivateCustomer = (response) => {
    return {
        type: types.API_SA_CUSTOMER_REACTIVATE,
        payload: {
            response: response
        }
    };
}

const saResetCustomer = (response) => {
    return {
        type: types.API_SA_CUSTOMER_RESET_ACCOUNT,
        payload: {
            response: response
        }
    };
}

const clearResponse = (status) => {
    return {
        type: types.API_SA_CUSTOMER_CLEAR_DB_RESPONSE,
        payload: {
            response: status || ""
        }
    };
}

export default {
    loadProfile,
    cleanupProfile,
    loadLicenseUsage,
    selectDevices,
    selectDeviceList,
    selectDevice,
    insertDevice,
    activateDevice,
    deactivateDevice,
    activateAllDevices,
    deactivateAllDevices,
    editDevice,
    removeDevice,
    refreshDevices,
    saSelectCustomer,
    saSelectCustomerList,
    saRejectCustomer,
    saVerifyCustomer,
    saApproveCustomer,
    saSuspendCustomer,
    saReactivateCustomer,
    saResetCustomer,
    clearResponse,
    error
}
