// Author: Teldasoft(c) 2021
// Module: common.js
// Description: Common utility routines

export const timestampToDateTimeStr = (timestamp) => {
    if (timestamp === "") {
        return ""
    }
    return timestamp.substring(0, 4) + "-" +
           timestamp.substring(4, 6) + "-" + 
           timestamp.substring(6, 8) + " " +
           timestamp.substring(8, 10) + ":" +
           timestamp.substring(10, 12) + ":" +
           timestamp.substring(12, 14)
}

export const parse = (...args) => {
    const str = args[0];
    const params = args.filter((arg, index) => index !== 0);

    if (!str) return "";
    return str.replace(/%s[0-9]+/g, matchedStr => {
        const variableIndex = matchedStr.replace("%s", "") - 1;
        return params[variableIndex];
    });
}

export function getCurrentDate (separator = '-', shiftDays = null) {
    let newDate = new Date()

    let date = newDate.getDate() + (shiftDays !== null ? shiftDays : 0);
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

export function getShiftedDate(oldDate, offset, offsetType, separator = '-') {
    var year = parseInt(oldDate.getFullYear());
    var month = parseInt(oldDate.getMonth());
    var date = parseInt(oldDate.getDate());
    var hour = parseInt(oldDate.getHours());
    var newDate;

    switch (offsetType) {
        case "Y":
        case "y":
            newDate = new Date(year + offset, month, date, hour);
            break;

        case "M":
        case "m":
            var monthOffset = 0;
            if (offset <= 12) {
                monthOffset = offset;
            }

            newDate = new Date(year, month + monthOffset, date, hour);
            break;

        case "D":
        case "d":
            var o = oldDate.getTime();
            var n = o + (offset * 24 * 3600 * 1000);

            newDate = new Date(n);
            break;

        case "H":
        case "h":
            var o = oldDate.getTi();
            var n = o + (offset * 3600 * 1000);

            newDate = new Date(n);
            break;
    
        default:
            newDate = new Date(year + offset, month, date, hour);
    }

    return newDate
}

export const getFormattedDateYYYYMMDD = (newDate, separator = '-') => {
    let year = parseInt(newDate.getFullYear());
    let month = parseInt(newDate.getMonth() + 1);
    let date = parseInt(newDate.getDate());

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

export const convertToFloat = (value) => {
    var floatValue = +(value);
    return floatValue;
}
