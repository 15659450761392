import React from 'react'
import './textArea.css'

const TextArea = (props) => {
    return (
        <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">
                {props.title}
            </label>
            <textarea
                className={props.className !== "" ? (props.className + " form-control") : "form-control"}
                id={props.id}
                name={props.name}
                rows={props.rows}
                readOnly={props.readOnly || false}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
                autoFocus={props.autoFocus || false}
            />
        </div>
    )
}

export default TextArea;