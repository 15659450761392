// Author: Teldasoft(c) 2021
// Module: types.js
// Description: Action types supported for the 'user' middleware

const API_CUSTOMER_SELECT_USERS       = 'API_CUSTOMER_SELECT_USERS';
const API_CUSTOMER_SELECT_USER_ROW    = 'API_CUSTOMER_SELECT_USER_ROW';
const API_CUSTOMER_SELECT_USER_GROUPS = 'API_CUSTOMER_SELECT_USER_GROUPS';
const API_CUSTOMER_SELECT_USER_ROLES  = 'API_CUSTOMER_SELECT_USER_ROLES';
const API_CUSTOMER_INSERT_USER        = 'API_CUSTOMER_INSERT_USER';
const API_CUSTOMER_EDIT_USER          = 'API_CUSTOMER_EDIT_USER';
const API_CUSTOMER_ACTIVATE_USER      = 'API_CUSTOMER_ACTIVATE_USER';
const API_CUSTOMER_LOCK_USER          = 'API_CUSTOMER_LOCK_USER';
const API_CUSTOMER_UNLOCK_USER        = 'API_CUSTOMER_UNLOCK_USER';
const API_CUSTOMER_REMOVE_USER        = 'API_CUSTOMER_REMOVE_USER';
const API_CUSTOMER_CLEAR_DB_RESPONSE  = 'API_CUSTOMER_CLEAR_DB_RESPONSE';
const API_USER_ERROR                  = 'API_USER_ERROR';

export default {
    API_CUSTOMER_SELECT_USERS,
    API_CUSTOMER_SELECT_USER_ROW,
    API_CUSTOMER_SELECT_USER_GROUPS,
    API_CUSTOMER_SELECT_USER_ROLES,
    API_CUSTOMER_INSERT_USER,
    API_CUSTOMER_EDIT_USER,
    API_CUSTOMER_ACTIVATE_USER,
    API_CUSTOMER_LOCK_USER,
    API_CUSTOMER_UNLOCK_USER,
    API_CUSTOMER_REMOVE_USER,
    API_CUSTOMER_CLEAR_DB_RESPONSE,
    API_USER_ERROR
}
