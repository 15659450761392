import types from './types'

const initialState = {
    deviceTypeList: [],
    deviceSelectedImageList: [],
    selectedDeviceNo: null,
    error: null
}

function DeviceReducer(state = initialState, action) {
    let imageList = [];
    let index = -1;

    switch (action.type) {
        case types.API_DEVICE_TYPE_SELECT:
            return { ...state, error: null, deviceTypeList: action.payload.deviceTypeList };

        case types.API_DEVICE_SELECT_DEVICE_NO:
            return { ...state, selectedDeviceNo: action.payload.selectedDeviceNo };

        case types.API_DEVICE_SELECT_IMAGE_ID:
            imageList = state.deviceSelectedImageList;
            index = imageList.indexOf(action.payload.selectedDeviceImage);
            if (index < 0) {
                imageList.push(action.payload.selectedDeviceImage)
            }

            return { ...state, deviceSelectedImageList: imageList };
        
        case types.API_DEVICE_UNSELECT_IMAGE_ID:
            imageList = state.deviceSelectedImageList;
            index = imageList.indexOf(action.payload.selectedDeviceImage);
            if (index > -1) {
                imageList.splice(index, 1)
            }

            return { ...state, deviceSelectedImageList: imageList };

        case types.API_DEVICE_UNSELECT_IMAGE_ALL:
            return { ...state, deviceSelectedImageList: [] };

        case types.API_DEVICE_ERROR:
            return { ...state, error: action.payload };
    
        default:
            return state;
    }
};

export default DeviceReducer;