// Author: Teldasoft(c) 2021
// Module: types.js
// Description: Action types supported for the 'auth' middleware

const API_LOGIN           = 'API_LOGIN';
const API_LOGON_COMPLETED = 'API_LOGON_COMPLETED';
const API_LOGOUT          = 'API_LOGOUT';
const API_REGISTER        = 'API_REGISTER';
const API_PROFILE         = 'API_PROFILE';
const API_ERROR           = 'API_ERROR';

export default {
    API_LOGIN,
    API_LOGON_COMPLETED,
    API_LOGOUT,
    API_REGISTER,
    API_PROFILE,
    API_ERROR
}