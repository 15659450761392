// Author: Teldasoft(c) 2021
// Module: api.js
// Description: API calls to the underlying Service

import { default as apiDeviceActions } from './actions'
import { getApi } from '../../../utilities/api'

// selectDeviceTypes
const selectDeviceTypes = async () => {
    const response = await fetch(getApi('/api/v1/deviceTypes'),
        { method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}

// thunk
export const apiSelectDeviceTypes = () => async (dispatch) => {
    await selectDeviceTypes()
        .then((response) => {
            if (response) {
                dispatch(apiDeviceActions.selectDeviceTypes(response))
            } else {
                dispatch(apiDeviceActions.error('Fetch failed'))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiDeviceActions.error('Method failed'))
                }
            }
        });
}

// thunk
export const apiSelectDeviceItem = (name) => async (dispatch) => {
    dispatch(apiDeviceActions.selectDeviceItem(name))
}

// thunk
export const apiSelectDeviceImage = (id) => async (dispatch) => {
    dispatch(apiDeviceActions.selectDeviceImage(id))
}

// thunk
export const apiUnselectDeviceImage = (id) => async (dispatch) => {
    dispatch(apiDeviceActions.unselectDeviceImage(id))
}

// thunk
export const apiUnselectDeviceImageAll = () => async (dispatch) => {
    dispatch(apiDeviceActions.unselectDeviceImageAll())
}
