import React from 'react';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import '../app.css';
import { ButtonEx } from './ui/button';
import { ButtonLink } from './ui/buttonLink';

import './center.css';

const Center = (props) => {
    const { t } = useTranslation();

	return (
		<div className='center-container'>
			<h1>{t('app.main.headlines.welcomeTitle')}</h1>
			<p>{t('app.main.headlines.welcomeSubTitle')}</p>
			<div className='center-btns'>
				{(props.isAuthUser || false) === true ? (
					<ButtonEx
						className='btns'
						buttonStyle='btn--outline'
						buttonSize='btn--large'
						actionName='/dashboard'>{t('app.main.buttons.continue')}
					</ButtonEx>
				) : (
					<ButtonEx
						className='btns'
						buttonStyle='btn--outline'
						buttonSize='btn--large'
						actionName='/sign-up'>{t('app.main.buttons.getStarted')}
					</ButtonEx>
				)}

				{/* <ButtonLink
					className='btns'
					buttonStyle='btn--primary'
					buttonSize='btn--large'
					content={<div>{t('app.main.buttons.watchTrailer')} <i className='far fa-play-circle' /></div>}
					urlName='https://www.youtube.com/channel/UCTFzrJzgj8DZsedKQL4teww'>
				</ButtonLink> */}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    isAuthUser: state.session.isAuthUser
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(Center)