import React, { useState, useEffect, useCallback } from 'react';
import EscapeOutside from "react-escape-outside";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./popupAlert.css";

const PopupAlert = (props) => {
    const {
        popupOpenToggle,
        popupOpenWith,
        popupOpenWithClass,
        popupOpenBtnTitle,
        popupHeaderTitle,
        popupBodyText,
        popupSubmitBtnText,
        popupSize,
        popupClassName,
        popupEscapeListener,
        popupHeaderIconClassName,
        popupOnSubmitHandler
    } = props;

    const [modal, setModal] = useState(popupOpenToggle || false);
    const toggle = () => setModal(!modal);
    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    const handleEscapeOutside = () => {
        if ((popupEscapeListener || false) === true) {
            if (popupOnSubmitHandler !== undefined) {
                popupOnSubmitHandler()
            }
        }
    }

    const handleEscapeKey = useCallback((event) => {
        if ((popupEscapeListener || false) === true) {
            if ((event.keyCode === 27) || (event.isTrusted === true)) {
                if (popupOnSubmitHandler !== undefined) {
                    popupOnSubmitHandler()
                }
            }
        }
    }, []);

    useEffect(() => {
        if ((popupEscapeListener || false) === true) {
            document.addEventListener("keydown", handleEscapeKey, false);
        }
        return () => {
            if ((popupEscapeListener || false) === true) {
                document.removeEventListener("keydown", handleEscapeKey, false);
            }
        };
    }, []);

    return (
        <>
            {(popupOpenWith === "None") ? (
                <></>
            ) : (
                (popupOpenWith === "Icon") ? (
                    <i className={popupOpenWithClass} title={popupOpenBtnTitle} onClick={toggle}></i>
                ) : (
                    <Button className={popupOpenWithClass} type="button" title={popupOpenBtnTitle} onClick={toggle}>{popupOpenBtnTitle}</Button>
                )
            )}

            {((popupEscapeListener || false) === true) ? (
                <EscapeOutside onEscapeOutside={handleEscapeOutside}>
                    <Modal isOpen={modal} toggle={toggle} className={popupClassName} size={popupSize || "sm"} draggable={true} centered={true}>
                        <ModalHeader toggle={toggle} close={closeBtn}><i className={popupHeaderIconClassName}></i>&nbsp;{popupHeaderTitle}</ModalHeader>
                        <ModalBody>
                            {popupBodyText}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" className="transparency btn-large" onClick={() => {
                                toggle()
                                if (popupOnSubmitHandler !== undefined) {
                                    popupOnSubmitHandler()
                                }
                            }}>{popupSubmitBtnText || "Close"}</Button>
                        </ModalFooter>
                    </Modal>
                </EscapeOutside>
            ) : (
                <Modal isOpen={modal} toggle={toggle} className={popupClassName} size={popupSize || "sm"} draggable={true} centered={true}>
                    <ModalHeader toggle={toggle} close={closeBtn}><i className={popupHeaderIconClassName}></i>&nbsp;{popupHeaderTitle}</ModalHeader>
                    <ModalBody>
                        {popupBodyText}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="transparency btn-large" onClick={() => {
                            toggle()
                            if (popupOnSubmitHandler !== undefined) {
                                popupOnSubmitHandler()
                            }
                        }}>{popupSubmitBtnText || "Close"}</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}

export default PopupAlert;