import React from 'react';
import { connect } from "react-redux";
import { setElementIdProperty } from '../utilities/dom';

import '../app.css';

import Cards from '../components/ui/cards';
import Center from '../components/center';
import Footer from '../components/footer';

const Home = (props) => {
	const isUserLoggedIn = () => {
		return (props.isAuthUser || false)
	}

	if (isUserLoggedIn()) {
		setElementIdProperty("root", "overflow-y", "auto")
	}

	return (
		<>
			<Center />
			<Cards />
			<Footer />
		</>
	);
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    isAuthUser: state.session.isAuthUser
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
