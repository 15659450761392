// Author: Teldasoft(c) 2021
// Module: types.js
// Description: Action types supported for the 'customer' middleware

const API_CUSTOMER_LOAD_PROFILE          = 'API_CUSTOMER_LOAD_PROFILE';
const API_CUSTOMER_LOAD_LICENSE_USAGE    = 'API_CUSTOMER_LOAD_LICENSE_USAGE';
const API_CUSTOMER_LOAD_ERROR            = 'API_CUSTOMER_LOAD_ERROR';
const API_CUSTOMER_CLEANUP_PROFILE       = 'API_CUSTOMER_CLEANUP_PROFILE';
const API_CUSTOMER_SELECT_DEVICES        = 'API_CUSTOMER_SELECT_DEVICES';
const API_CUSTOMER_SELECT_DEVICE_SUMMARY = 'API_CUSTOMER_SELECT_DEVICE_SUMMARY';
const API_CUSTOMER_SELECT_DEVICE_ROW     = 'API_CUSTOMER_SELECT_DEVICE_ROW';
const API_CUSTOMER_INSERT_DEVICE         = 'API_CUSTOMER_INSERT_DEVICE';
const API_CUSTOMER_ACTIVATE_DEVICE       = 'API_CUSTOMER_ACTIVATE_DEVICE';
const API_CUSTOMER_ACTIVATE_ALL_DEVICE   = 'API_CUSTOMER_ACTIVATE_ALL_DEVICE';
const API_CUSTOMER_DEACTIVATE_DEVICE     = 'API_CUSTOMER_DEACTIVATE_DEVICE';
const API_CUSTOMER_DEACTIVATE_ALL_DEVICE = 'API_CUSTOMER_DEACTIVATE_ALL_DEVICE';
const API_CUSTOMER_EDIT_DEVICE           = 'API_CUSTOMER_EDIT_DEVICE';
const API_CUSTOMER_REMOVE_DEVICE         = 'API_CUSTOMER_REMOVE_DEVICE';
const API_CUSTOMER_REFRESH_DEVICES       = 'API_CUSTOMER_REFRESH_DEVICES';

const API_SA_CUSTOMER_SELECT_ROW         = 'API_SA_CUSTOMER_SELECT_ROW';
const API_SA_CUSTOMER_SELECT_ALL         = 'API_SA_CUSTOMER_SELECT_ALL';
const API_SA_CUSTOMER_REJECT             = 'API_SA_CUSTOMER_REJECT';
const API_SA_CUSTOMER_VERIFY             = 'API_SA_CUSTOMER_VERIFY';
const API_SA_CUSTOMER_APPROVE            = 'API_SA_CUSTOMER_APPROVE';
const API_SA_CUSTOMER_SUSPEND            = 'API_SA_CUSTOMER_SUSPEND';
const API_SA_CUSTOMER_REACTIVATE         = 'API_SA_CUSTOMER_REACTIVATE';
const API_SA_CUSTOMER_RESET_ACCOUNT      = 'API_SA_CUSTOMER_RESET_ACCOUNT';
const API_SA_CUSTOMER_CLEAR_DB_RESPONSE  = 'API_SA_CUSTOMER_CLEAR_DB_RESPONSE';

export default {
    API_CUSTOMER_LOAD_PROFILE,
    API_CUSTOMER_LOAD_LICENSE_USAGE,
    API_CUSTOMER_LOAD_ERROR,
    API_CUSTOMER_CLEANUP_PROFILE,
    API_CUSTOMER_SELECT_DEVICES,
    API_CUSTOMER_SELECT_DEVICE_SUMMARY,
    API_CUSTOMER_SELECT_DEVICE_ROW,
    API_CUSTOMER_INSERT_DEVICE,
    API_CUSTOMER_ACTIVATE_DEVICE,
    API_CUSTOMER_ACTIVATE_ALL_DEVICE,
    API_CUSTOMER_DEACTIVATE_DEVICE,
    API_CUSTOMER_DEACTIVATE_ALL_DEVICE,
    API_CUSTOMER_EDIT_DEVICE,
    API_CUSTOMER_REMOVE_DEVICE,
    API_CUSTOMER_REFRESH_DEVICES,
 
    API_SA_CUSTOMER_SELECT_ROW,
    API_SA_CUSTOMER_SELECT_ALL,
    API_SA_CUSTOMER_REJECT,
    API_SA_CUSTOMER_VERIFY,
    API_SA_CUSTOMER_APPROVE,
    API_SA_CUSTOMER_SUSPEND,
    API_SA_CUSTOMER_REACTIVATE,
    API_SA_CUSTOMER_RESET_ACCOUNT,
    API_SA_CUSTOMER_CLEAR_DB_RESPONSE
}