import React from 'react';
import { useTranslation } from 'react-i18next';

import './cards.css';
import CardItem from './cardItem';
import { default as licenseTypes } from '../../utilities/system/licenseTypes';

const Cards = (props) => {
	const { t } = useTranslation();
	
	const handleDemoOffer = () => {
		sessionStorage.setItem('registerSelectedLicense', licenseTypes.REG_LICENSE_DEMO);
		window.location = '/register'
	}

	return (
		<div className='cards'>
			<h1>{t('app.main.headlines.pricingOfferTitle')}</h1>
			<div className='cards__container'>
				<div className='cards__wrapper'>
					<ul className='cards__items'>
						<CardItem
							src='images/img-bg-card-05.jpg'
							text={t('app.pages.licenseCards.labels.buy')}
							label={t('app.pages.licenseCards.labels.license') + " " + t('app.pages.licenseCards.labels.basic')}
							maxDev={1}
							maxStorage={1}
							maxStorageUnit={"GB"}
							licenseType={licenseTypes.REG_LICENSE_BASIC}
							licensePrice={"10.00 PLN"}
							licensePricePeriod={t('app.pages.licenseCards.labels.period')}
							licenseTip={""}
							path='/register'
						/>
						<CardItem
							src='images/img-bg-card-01.jpg'
							text={t('app.pages.licenseCards.labels.buy')}
							label={t('app.pages.licenseCards.labels.license') + " " + t('app.pages.licenseCards.labels.advanced')}
							maxDev={6}
							maxStorage={6}
							maxStorageUnit={"GB"}
							licenseType={licenseTypes.REG_LICENSE_ADVANCED}
							licensePrice={"29.00 PLN"}
							licensePricePeriod={t('app.pages.licenseCards.labels.period')}
							licenseTip={""}
							path='/register'
						/>
						<CardItem
							src='images/img-bg-card-03.jpg'
							text={t('app.pages.licenseCards.labels.buy')}
							label={t('app.pages.licenseCards.labels.license') + " " + t('app.pages.licenseCards.labels.professional')}
							maxDev={30}
							maxStorage={60}
							maxStorageUnit={"GB"}
							licenseType={licenseTypes.REG_LICENSE_PROFESSIONAL}
							licensePrice={"99.00 PLN"}
							licensePricePeriod={t('app.pages.licenseCards.labels.period')}
							licenseTip={t('app.pages.licenseCards.headlines.licenseTip')}
							path='/register'
						/>
					</ul>
				</div>
				<br/>
				<h4>{t('app.pages.licenseCards.headlines.demoOffer')}</h4>
				<br/>
				<button className="btn btn--outline-fig btn--medium-fig" onClick={handleDemoOffer}>{t('app.pages.licenseCards.labels.demoOffer').toUpperCase()}</button>
			</div>
		</div>
	);
}

export default Cards;
