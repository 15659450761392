import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, FormGroup, Input,
         Label, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'

import PopupAlert from '../../../../../components/ui/popupAlert'
import { setFieldValidationError, setFieldValidationReset } from "../../../../../utilities/dom"
import { onValidateEmail, onValidatePhoneNumber, onValidatePasswordStrength } from '../../../../../utilities/validators'

import "./modalUserEdit.css";

const deepEqual = require('deep-equal')

const ModalUserEdit = (props) => {
    const { t } = useTranslation();

    const [dropdownOpenGroup, setDropdownOpenGroup] = useState(false);
    const [dropdownOpenRole, setDropdownOpenRole] = useState(false);
    const [dropdownOpenGender, setDropdownOpenGender] = useState(false);

    const toggleUserGroup = () => {
        setDropdownOpenGroup(prevState => !prevState);
        setFieldValidationReset('userGroup')
    }
    const toggleUserRole = () => {
        setDropdownOpenRole(prevState => !prevState);
        setFieldValidationReset('userRole')
    }
    const toggleUserGender = () => {
        setDropdownOpenGender(prevState => !prevState);
        setFieldValidationReset('userGender')
    }

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    const usePrevious = value => {
        const ref = React.useRef();
     
        React.useEffect(() => {
            ref.current = value;
        }, [value]);
     
        return ref.current;
    }
     
    const usePropState = datas => {
        const [dataset, setDataset] = useState(datas);
        const prevDatas = usePrevious(datas);
     
        const handleChangeDataset = data => setDataset(data);
     
        React.useEffect(() => {
            if (!deepEqual(datas, prevDatas)) {
                setDataset(datas);
                setPageInitForm()
            }
        }, [datas, prevDatas]);
     
        return [
            dataset,
            handleChangeDataset
        ]
    }

    const decodeGender = (gender) => {
        if (gender === "Male") {
            return t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitem1')
        }
        else if (gender === "Female") {
            return t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitem2')
        }
        else if (gender === "Other") {
            return t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitem3')
        }
        else {
            return gender
        }
    }

    const decodeGroup = (group) => {
        if (group === "Default") {
            return t('app.pages.userListView.modals.addUser.buttons.dropdown.userGroup.menuitem1')
        }
        else {
            return group
        }
    }

    const {
        modalIsPrimaryAccount,
        modalOpenMode,
        modalOpenWith,
        modalOpenWithClass,
        modalOpenBtnTitle,
        modalHeaderTitle,
        modalClassName,
        modalOnLoadHandler,
        modalOnSubmitHandler
    } = props;

    const [componentState, setComponentState] = usePropState({
        userGroupCode: "Default",
        userGroup: decodeGroup(props.selectedUser.userGroupName) || t('app.pages.userListView.modals.addUser.buttons.dropdown.userGroup.menuitemdef'),
        userRoleCode: "",
        userRole: props.selectedUser.userRole || t('app.pages.userListView.modals.addUser.buttons.dropdown.userRole.menuitemdef'),
        userLogin: props.selectedUser.login || "",
        userPassword: "",
        userGenderCode: props.selectedUser.genderCode || "",
        userGender: decodeGender(props.selectedUser.gender) || t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitemdef'),
        userFirstName: props.selectedUser.firstName || "",
        userLastName: props.selectedUser.lastName || "",
        userEmail: props.selectedUser.email || "",
        userEmailPlaceholder: t('app.pages.userListView.modals.addUser.placeholders.userEmail'),
        userPhone: props.selectedUser.phoneNo || "",
        userPasswordValidation: -1,
        userValidationMessage: ""
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setComponentState({ ...componentState, [name]: value, userValidationMessage: "" });
        setFieldValidationReset(name)
    }

    const handleValidateForm = () => {
        const { userGroup, userRole, userGender, userLogin, userPassword, userFirstName, userLastName, userEmail, userPhone } = componentState
        
        if ((userGroup === '') || (userGroup === t('app.pages.userListView.modals.addUser.buttons.dropdown.userGroup.menuitemdef'))) {
            setFieldValidationError("userGroup")
            return false
        }
        if ((userRole === '') || (userRole === t('app.pages.userListView.modals.addUser.buttons.dropdown.userRole.menuitemdef'))) {
            setFieldValidationError("userRole")
            return false
        }
        if ((userGender === '') || (userGender === t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitemdef'))) {
            setFieldValidationError("userGender")
            return false
        }
        if (userLogin === '') {
            setFieldValidationError("userLogin")
            return false
        }
        if (userLogin.length < 6) {
            setFieldValidationError("userLogin")
            setFormValidationMessage(t('app.pages.userListView.modals.addUser.messages.invalidUserLoginLen'))
            return false
        }

        if ((userPassword === '') && (modalOpenMode === "NEW")) {
            setFieldValidationError("userPassword")
            return false
        }
        if (userFirstName === '') {
            setFieldValidationError("userFirstName")
            return false
        }
        if (userLastName === '') {
            setFieldValidationError("userLastName")
            return false
        }

        if (userEmail !== '') {
			if (!onValidateEmail(userEmail)) {
                setFieldValidationError("userEmail")
                setFormValidationMessage(t('app.pages.userListView.modals.addUser.messages.expectedValidEmail'))
                return false
            }
        }
        if (userPhone !== '') {
			if (!onValidatePhoneNumber(userPhone)) {
                setFieldValidationError("userPhone")
                setFormValidationMessage(t('app.pages.userListView.modals.addUser.messages.expectedValidPhone'))
                return false
            }
        }
        if (userPassword !== '') {
            const passWeakness = onValidatePasswordStrength(userPassword)
            if (passWeakness < 3) {
                setFieldValidationError("userPassword")
                setComponentState({ ...componentState, userPasswordValidation: passWeakness });
                return false
            }
        }

        return true
    }

    const handleOnLoadForm = () => {
        modalOnLoadHandler()
        toggle()
        handleOnModalInit()
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()

        console.log('Submitting form...')
        if (!handleValidateForm()) {
            return
        }

        modalOnSubmitHandler(componentState)
        toggle()
    }

    const setFormValidationMessage = (message) => {
        setComponentState({ ...componentState, userValidationMessage: message });
    }

    const handleOnModalInit = () => {
        if (modalOpenMode === "NEW") {
            setComponentState({ ...componentState,
                userGroupCode: "Default",
                userGroup: t('app.pages.userListView.modals.addUser.buttons.dropdown.userGroup.menuitemdef'),
                userRoleCode: "",
                userRole: t('app.pages.userListView.modals.addUser.buttons.dropdown.userRole.menuitemdef'),
                userLogin: "",
                userPassword: "",
                userGenderCode: "",
                userGender: t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitemdef'),
                userFirstName: "",
                userLastName: "",
                userEmail: "",
                userEmailPlaceholder: t('app.pages.userListView.modals.addUser.placeholders.userEmail'),
                userPhone: "",
                userPasswordValidation: -1,
                userValidationMessage: ""
            });
        }
    }

    const setPageInitForm = () => {
    }
    
    return (
        <>
            {(modalOpenWith === "Icon") ? (
                <i className={modalOpenWithClass} title={modalOpenBtnTitle} onClick={handleOnLoadForm}></i>
            ) : (
                <Button className={modalOpenWithClass} type="button" title={modalOpenBtnTitle} onClick={handleOnLoadForm}>{modalOpenBtnTitle}</Button>
            )}

            {(componentState.userPasswordValidation >= 0 && componentState.userPasswordValidation < 3) ? (
                <PopupAlert
                    key={componentState.userPasswordValidation}
                    popupOpenToggle={true}
                    popupSize="lg"
                    popupOpenWith="None"
                    popupOpenWithClass=""
                    popupHeaderTitle={t('app.pages.userListView.modals.validatePassword.title')}
                    popupOpenBtnTitle=""
                    popupBodyText={t('app.pages.userListView.modals.validatePassword.bodyText')}
                    popupHeaderIconClassName="fas fa-exclamation-triangle font-red"
                    popupSubmitBtnText={t('app.pages.userListView.modals.validatePassword.cancelText')}
                    popupEscapeListener={false}
                    popupOnSubmitHandler={() => {
                        setComponentState({ ...componentState, userPasswordValidation: -1 });
                    }}
                />
            ) : (
                <></>
            )}

            <Modal isOpen={modal} toggle={toggle} className={modalClassName} size="xl" draggable={false} centered={true}>
                <ModalHeader toggle={toggle} close={closeBtn}><i className={modalOpenMode === "NEW" ? "far fa-plus-square" : "far fa-edit"}></i>&nbsp;{modalHeaderTitle}</ModalHeader>
                <ModalBody>
                    {((modalOpenMode === "NEW") || (modalOpenMode === "EDIT")) ? (
                        <Form className="user-add-new-form" autoComplete="off">
                            <Row>
                                <Col xs={{size: 3}} md={{size: 2}} style={{paddingLeft: '0px'}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.userListView.modals.addUser.labels.userGroup')}</Label>
                                        <Dropdown className={dropdownOpenGroup ? "btn-large transparency-dropdown-after" : "btn-large  transparency-dropdown"} isOpen={dropdownOpenGroup} toggle={toggleUserGroup}>
                                            <DropdownToggle name="userGroup" style={{textAlign: 'left', minWidth: '164px'}} caret>
                                                {decodeGroup(componentState.userGroup)}
                                            </DropdownToggle>
                                            <DropdownMenu
                                                modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true,
                                                        order: 890,
                                                        fn: (data) => {
                                                            return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflowY: 'auto',
                                                                maxHeight: '160px',
                                                            },
                                                            };
                                                        },
                                                    },
                                                }}
                                            >
                                                {props.userGroupList.map((item, index) => {
                                                    return (
                                                        <DropdownItem key={index} onClick={() => {
                                                            setComponentState({ ...componentState, userGroup: item.value, userGroupCode: item.key });
                                                        }}>{decodeGroup(item.value)}</DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FormGroup>                                    
                                </Col>
                                <Col xs={{size: 3}} md={{size: 2}} style={{paddingLeft: '0px'}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.userListView.modals.addUser.labels.userRole')}</Label>
                                        <Dropdown className={dropdownOpenRole ? "btn-large transparency-dropdown-after" : "btn-large  transparency-dropdown"} isOpen={dropdownOpenRole} toggle={toggleUserRole}>
                                            {(modalIsPrimaryAccount === 1) ? (
                                                <DropdownToggle name="userRole" style={{textAlign: 'left', minWidth: '164px'}} caret disabled>
                                                    {componentState.userRole}
                                                </DropdownToggle>
                                            ) : (
                                                <DropdownToggle name="userRole" style={{textAlign: 'left', minWidth: '164px'}} caret>
                                                    {componentState.userRole}
                                                </DropdownToggle>
                                            )}
                                            <DropdownMenu
                                                modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true,
                                                        order: 890,
                                                        fn: (data) => {
                                                            return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflowY: 'auto',
                                                                maxHeight: '160px',
                                                            },
                                                            };
                                                        },
                                                    },
                                                }}
                                            >
                                                {props.userRoleList.map((item, index) => {
                                                    return (
                                                        <DropdownItem key={index} onClick={() => {
                                                            setComponentState({ ...componentState, userRole: item.value, userRoleCode: item.key });
                                                        }}>{item.value}</DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FormGroup>
                                </Col>
                                <Col xs={{size: 3}} md={{size: 2}} style={{paddingLeft: '0px'}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.userListView.modals.addUser.labels.userGender')}</Label>
                                        <Dropdown className={dropdownOpenGender ? "btn-large transparency-dropdown-after" : "btn-large  transparency-dropdown"} isOpen={dropdownOpenGender} toggle={toggleUserGender}>
                                            <DropdownToggle name="userGender" style={{textAlign: 'left', minWidth: '164px'}} caret>
                                                {componentState.userGender}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem key="M" onClick={() => {setComponentState({ ...componentState, userGenderCode: "M", userGender: t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitem1')})}}>{t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitem1')}</DropdownItem>
                                                <DropdownItem key="F" onClick={() => {setComponentState({ ...componentState, userGenderCode: "F", userGender: t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitem2')})}}>{t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitem2')}</DropdownItem>
                                                {/* <DropdownItem key="O" onClick={() => {setComponentState({ ...componentState, userGenderCode: "O", userGender: t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitem3')})}}>{t('app.pages.userListView.modals.addUser.buttons.dropdown.userGender.menuitem3')}</DropdownItem> */}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FormGroup>
                                </Col>
                                <Col xs={{size: 4}} md={{size: 3}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.userListView.modals.addUser.labels.firstName')}</Label>
                                        <Input type="text" name="userFirstName" placeholder={t('app.pages.userListView.modals.addUser.placeholders.firstName')} value={componentState.userFirstName} onChange={handleOnChange} maxLength={25}></Input>
                                    </FormGroup>
                                </Col>
                                <Col xs={{size: 4}} md={{size: 3}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.userListView.modals.addUser.labels.lastName')}</Label>
                                        <Input type="text" name="userLastName" placeholder={t('app.pages.userListView.modals.addUser.placeholders.lastName')} value={componentState.userLastName} onChange={handleOnChange} maxLength={25}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{size: 6}} md={{size: 6}} style={{paddingLeft: '0px'}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.userListView.modals.addUser.labels.userEmail')}</Label>
                                        <Input type="text" name="userEmail" placeholder={componentState.userEmailPlaceholder} value={componentState.userEmail} onChange={handleOnChange}></Input>
                                    </FormGroup>
                                </Col>
                                <Col xs={{size: 6}} md={{size: 3}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.userListView.modals.addUser.labels.userPhone')}</Label>
                                        <Input type="text" name="userPhone" placeholder={t('app.pages.userListView.modals.addUser.placeholders.userPhone')} value={componentState.userPhone} onChange={handleOnChange}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{size: 6}} md={{size: 4}} style={{paddingLeft: '0px'}}>
                                    <FormGroup>
                                        <Label>&nbsp;</Label>
                                        <div style={{paddingTop: '14px'}}>
                                            <span style={{color: 'red'}}>{componentState.userValidationMessage}</span>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={{size: 3}} md={{size: 2}} style={{paddingLeft: '0px'}}>
                                </Col>
                                <Col xs={{size: 4}} md={{size: 3}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.userListView.modals.addUser.labels.login')}</Label>
                                        {(modalOpenMode === "EDIT") ? (
                                            (modalIsPrimaryAccount === 1) ? (
                                                <Input type="text" name="userLogin" placeholder={t('app.pages.userListView.modals.addUser.placeholders.login')} value={componentState.userLogin} readOnly></Input>
                                            ) : (
                                                /* Set login to be editable due to customer requirement
                                                */
                                                <Input type="text" name="userLogin" placeholder={t('app.pages.userListView.modals.addUser.placeholders.login')} value={componentState.userLogin} onChange={handleOnChange} maxLength={20}></Input>
                                            )
                                         ) : (
                                            <Input type="text" name="userLogin" placeholder={t('app.pages.userListView.modals.addUser.placeholders.login')} value={componentState.userLogin} onChange={handleOnChange} maxLength={20}></Input>
                                         )}
                                    </FormGroup>
                                </Col>
                                <Col xs={{size: 4}} md={{size: 3}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.userListView.modals.addUser.labels.password')}</Label>
                                        <Input type="password" name="userPassword" placeholder={t('app.pages.userListView.modals.addUser.placeholders.password')} value={componentState.userPassword} onChange={handleOnChange}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    ) : (
                        <></>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" className="btn-large" onClick={handleOnSubmit}>{modalOpenMode === "NEW" ? t('app.pages.userListView.modals.addUser.submitText') : t('app.pages.userListView.modals.addUser.submitText2')}</Button>{' '}
                    <Button color="secondary" className="transparency btn-large" onClick={toggle}>{t('app.pages.userListView.modals.addUser.cancelText')}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerCode: state.session.customer,
    userGroupList: state.user.userGroupList || [],
    userRoleList: state.user.userRoleList || [],
    selectedUser: state.user.userSelected
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalUserEdit);