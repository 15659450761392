import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { getCurrentDate } from '../../utilities/common';
import { default as licenseTypes } from '../../utilities/system/licenseTypes';

import "./popupRegister.css";

const PopupRegister = (props) => {
    const { t } = useTranslation();

    const {
        popupSize,
        popupOpenBtnTitle,
        popupHeaderTitle,
        popupRegisterData,
        popupClassName,
        popupHeaderIconClassName,
        popupSubmitBtnText,
        popupCancelBtnText,
        popupOnSubmitHandler,
        popupOnNextHandler
    } = props;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    let paymentBankAccNo = "99-9999-9999-9999-9999-9999-9999"
    const handleGetPaymentAccount = () => {
        if (popupRegisterData.customer.applicationProps) {
            popupRegisterData.customer.applicationProps.map((item, index) => {
                if (item.propertyName === "REGISTRATION_PAYMENT_BANK_ACC") {
                    paymentBankAccNo = item.propertyValue
                }
            })
        }
    }

    handleGetPaymentAccount()

    return (
        <>
            <Button className="btn btn-warning register-button" onClick={() => {
                popupOnNextHandler();
                toggle();
            }}>{popupOpenBtnTitle}</Button>
            <Modal isOpen={modal} toggle={toggle} className={popupClassName} size={popupSize || "sm"} draggable={true} centered={true}>
                <ModalHeader toggle={toggle} close={closeBtn}><i className={popupHeaderIconClassName}></i>&nbsp;{popupHeaderTitle}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label className="label-title">{t('app.pages.registerSummary.headlines.customerSummary')}</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="label-summary">{t('app.pages.registerSummary.labels.name')}</Label>
                                </Col>
                                <Col>
                                    <Label className="label-summary">{popupRegisterData.customer.companyName}</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="label-summary">{t('app.pages.registerSummary.labels.shortName')}</Label>
                                </Col>
                                <Col>
                                    <Label className="label-summary">{popupRegisterData.customer.companyShortName}</Label>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '15px'}}>
                                <Col>
                                    <Label className="label-title">{t('app.pages.registerSummary.headlines.productSummary')}</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="label-summary">{t('app.pages.registerSummary.labels.selectedLicense')}</Label>
                                </Col>
                                <Col>
                                    <Label className="label-summary label-orange">
                                        {(popupRegisterData.license === licenseTypes.REG_LICENSE_DEMO ? t('app.pages.licenseCards.labels.demo') :
                                            (popupRegisterData.license === licenseTypes.REG_LICENSE_BASIC ? t('app.pages.licenseCards.labels.basic') :
                                                (popupRegisterData.license === licenseTypes.REG_LICENSE_ADVANCED ? t('app.pages.licenseCards.labels.advanced') :
                                                    (popupRegisterData.license === licenseTypes.REG_LICENSE_PROFESSIONAL ? t('app.pages.licenseCards.labels.professional') : "UNKNOWN"))))
                                        }
                                    </Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="label-summary">{t('app.pages.registerSummary.labels.maxDevStorage')}</Label>
                                </Col>
                                <Col>
                                    {(popupRegisterData.license) && popupRegisterData.customer.licenseOffer.map((item, index) => {
                                        if (item.licenseType === popupRegisterData.license) {
                                            return (
                                                <Label className="label-summary" key={"MA_" + index}>
                                                    {item.licenseMaxDevices + " / " + item.licenseStorage + " " + item.licenseStorageUnit}
                                                </Label>
                                            )
                                        }
                                    })}
                                </Col>
                            </Row>
                            {(popupRegisterData.license === licenseTypes.REG_LICENSE_DEMO) && (
                                <Row>
                                    <Col>
                                        <Label className="label-summary">{t('app.pages.registerSummary.labels.licenseValidTo')}</Label>
                                    </Col>
                                    <Col>
                                        {getCurrentDate('-', 4)}
                                    </Col>
                                </Row>
                            )}
                            <Row style={{marginTop: '15px'}}>
                                <Col>
                                    <Label className="label-title">{t('app.pages.registerSummary.headlines.paymentSummary')}</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="label-summary">{t('app.pages.registerSummary.labels.price')}</Label>
                                </Col>
                                <Col>
                                    {(popupRegisterData.license) && popupRegisterData.customer.licenseOffer.map((item, index) => {
                                        if (item.licenseType === popupRegisterData.license) {
                                            return (
                                                <Label className="label-summary label-green" key={"PR_" + index}>
                                                    {item.licensePrice + " " + item.licenseCurrency}
                                                </Label>
                                            )
                                        }
                                    })}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className="label-summary">{t('app.pages.registerSummary.labels.paymentMethod')}</Label>
                                </Col>
                                <Col>
                                    {(popupRegisterData.license === licenseTypes.REG_LICENSE_DEMO) ? (
                                        <Label className="label-summary">{t('app.pages.registerSummary.labels.freeFee')}</Label>
                                    ) : (
                                        <Label className="label-summary">{t('app.pages.registerSummary.labels.bankTransfer')}</Label>
                                    )}
                                </Col>
                            </Row>
                            {(popupRegisterData.license !== licenseTypes.REG_LICENSE_DEMO) && (
                                <Row style={{marginTop: '15px'}}>
                                    <Col style={{columnSpan: 2}}>
                                        <Label className="label-summary">
                                            {t('app.pages.registerSummary.messages.text1')} <span className="label-bank-account">{paymentBankAccNo}</span>&nbsp;
                                            {t('app.pages.registerSummary.messages.text2')}
                                        </Label>
                                    </Col>
                                </Row>
                            )}
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="btn-large" onClick={toggle}>{popupCancelBtnText || "Cancel"}</Button>{' '}
                    <Button color="warning" className="btn-large" onClick={() => {toggle(); popupOnSubmitHandler();}}>{popupSubmitBtnText}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default PopupRegister;