// Author: Teldasoft(c) 2021
// Module: actions.js
// Description: Action creators for 'app' middleware

import types from './types'

const setSideBarVisibility = (status) => {
    return {
        type: types.APP_SET_SIDEBAR_NAV_VISIBILITY,
        payload: status
    };
};

const setSideBarVisibilityHiddenClass = (cssName) => {
    return {
        type: types.APP_SET_SIDEBAR_NAV_VISIBILITY_HIDDEN_CLASS,
        payload: cssName
    };
}

const sendTicketRequest = (status) => {
    return {
        type: types.API_APP_SEND_TICKET,
        payload: {
            requestStatus: status
        }
    };
}

const clearTicketRequest = (status) => {
    return {
        type: types.API_APP_CLEAR_TICKET,
        payload: {
            requestStatus: status || ""
        }
    };
}

export default {
    setSideBarVisibility,
    setSideBarVisibilityHiddenClass,
    sendTicketRequest,
    clearTicketRequest
}
