import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form,
         FormGroup, Input, Label, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'

import { setFieldValidationError, setFieldValidationReset, copyToClipboard } from "../../../../../utilities/dom"

import "./modalDeviceEdit.css";

const deepEqual = require('deep-equal')

const ModalDeviceEdit = (props) => {
    const { t } = useTranslation();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDeviceType = () => {
        setDropdownOpen(prevState => !prevState);
        setFieldValidationReset('deviceType')
    }

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    const usePrevious = value => {
        const ref = React.useRef();
     
        React.useEffect(() => {
            ref.current = value;
        }, [value]);
     
        return ref.current;
    }
     
    const usePropState = datas => {
        const [dataset, setDataset] = useState(datas);
        const prevDatas = usePrevious(datas);
     
        const handleChangeDataset = data => setDataset(data);
     
        React.useEffect(() => {
            if (!deepEqual(datas, prevDatas)) {
                setDataset(datas);
                setPageInitForm()
            }
        }, [datas, prevDatas]);
     
        return [
            dataset,
            handleChangeDataset
        ]
    }
    
    let keyCode;
    const onValidateNumber = (e) => {
        const keyCode = e.key || e.which;

        if (!/[0-9]/.test(keyCode) &&
            (keyCode !== 'ArrowLeft' && keyCode !== 'ArrowRight' &&
             keyCode !== 'Delete' && keyCode !== 'Backspace' &&
             keyCode !== 'Home' && keyCode !== 'End')) {
            e.preventDefault()
        }
    }

    const onValidateKeyCode = (e) => {
        keyCode = e.key || e.which;

        if (!/[0-9|a-z|A-Z]/.test(keyCode) &&
            (keyCode !== 'ArrowLeft' && keyCode !== 'ArrowRight' &&
             keyCode !== 'Delete' && keyCode !== 'Backspace' &&
             keyCode !== 'Home' && keyCode !== 'End')) {
            e.preventDefault()
        }
    }

    const onValidateSerial = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

    const onUpdateDeviceEmail = (e) => {
        if (e.target.value === "") {
            componentState.deviceEmailPlaceholder = t('app.pages.deviceListView.modals.addDevice.placeholders.deviceEmail')
        } else {
            componentState.deviceEmailPlaceholder = props.customerCode + "-PG" + e.target.value + "@photoguard.pl"
        }
    }

    const getOtherDeviceType = (name) => {
        return name === 'Other' ? t('app.pages.deviceListView.modals.addDevice.labels.' + name.toLowerCase()) : name
    }

    const {
        modalOpenMode,
        modalOpenWith,
        modalOpenWithClass,
        modalOpenBtnTitle,
        modalHeaderTitle,
        modalClassName,
        modalOnLoadHandler,
        modalOnSubmitHandler,
        licenseExceeded
    } = props;

    const [componentState, setComponentState] = usePropState({
        deviceNo: props.selectedDevice.deviceMappingNo || "",
        deviceName: props.selectedDevice.name || "",
        deviceTypeCode: props.selectedDevice.deviceTypeCode || "",
        deviceType: getOtherDeviceType(props.selectedDevice.deviceTypeName) || t('app.pages.deviceListView.modals.addDevice.buttons.dropdown.deviceType.menuitemdef'),
        deviceIMEI: props.selectedDevice.imei || "",
        deviceEmail: "",
        deviceEmailPlaceholder: props.selectedDevice.deviceEmail || t('app.pages.deviceListView.modals.addDevice.placeholders.deviceEmail'),
        devicePhone: props.selectedDevice.phoneNo || "",
        deviceSimCardType: "Postpaid"
    })

    const handleOnFocus = (e) => {
        e.target.select()
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setComponentState({ ...componentState, [name]: value });
        setFieldValidationReset(name)
    }

    const handleValidateForm = () => {
        const { deviceType, deviceName, deviceNo, deviceIMEI, devicePhone } = componentState
        
        if ((deviceType === '') || (deviceType === t('app.pages.deviceListView.modals.addDevice.buttons.dropdown.deviceType.menuitemdef'))) {
            setFieldValidationError("deviceType")
            return false
        }
        if (deviceName === '') {
            setFieldValidationError("deviceName")
            return false
        }
        if ((deviceNo === '') || ("" + deviceNo).length !== 4) {
            setFieldValidationError("deviceNo")
            return false
        }
        if ((deviceIMEI === '') || ("" + deviceIMEI).length !== 15) {
            setFieldValidationError("deviceIMEI")
            return false
        }
        if ((devicePhone === '') || ("" + devicePhone).length !== 9) {
            setFieldValidationError("devicePhone")
            return false
        }

        return true
    }

    const handleOnLoadForm = () => {
        modalOnLoadHandler()
        toggle()
        handleOnModalInit()
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()

        console.log('Submitting form...')
        if (!handleValidateForm()) {
            return
        }

        modalOnSubmitHandler(componentState)
        toggle()
    }

    const handleCopyToClipboard = () => {
        copyToClipboard('deviceEmail', t('app.pages.deviceListView.modals.addDevice.placeholders.deviceEmail'))
    }

    const handleOnModalInit = () => {
        if (modalOpenMode === "NEW") {
            setComponentState({ ...componentState,
                deviceNo: "",
                deviceName: "",
                deviceTypeCode: "",
                deviceType: t('app.pages.deviceListView.modals.addDevice.buttons.dropdown.deviceType.menuitemdef'),
                deviceIMEI: "",
                deviceEmail: "",
                deviceEmailPlaceholder: t('app.pages.deviceListView.modals.addDevice.placeholders.deviceEmail'),
                devicePhone: "",
                deviceSimCardType: "Postpaid"
            });
        }
    }

    const setPageInitForm = () => {
    }
    
    return (
        <>
            {(modalOpenWith === "Icon") ? (
                (licenseExceeded === "1") ? (
                    <i className={modalOpenWithClass} title={modalOpenBtnTitle} disabled></i>
                ) : (
                    <i className={modalOpenWithClass} title={modalOpenBtnTitle} onClick={handleOnLoadForm}></i>
                )
            ) : (
                (licenseExceeded === "1") ? (
                    <Button className={modalOpenWithClass} type="button" title={modalOpenBtnTitle} disabled>{modalOpenBtnTitle}</Button>
                ) : (
                    <Button className={modalOpenWithClass} type="button" title={modalOpenBtnTitle} onClick={handleOnLoadForm}>{modalOpenBtnTitle}</Button>
                )
            )}

            <Modal isOpen={modal} toggle={toggle} className={modalClassName} size="xl" draggable={false} centered={true}>
                <ModalHeader toggle={toggle} close={closeBtn}><i className={modalOpenMode === "NEW" ? "far fa-plus-square" : "far fa-edit"}></i>&nbsp;{modalHeaderTitle}</ModalHeader>
                <ModalBody>
                    {((modalOpenMode === "NEW") || (modalOpenMode === "EDIT")) ? (
                        <Form className="device-add-new-form" autoComplete="off">
                            <Row>
                                <Col xs={{size: 3}} md={{size: 2}} style={{paddingLeft: '0px'}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.deviceListView.modals.addDevice.labels.deviceType')}</Label>
                                        <Dropdown className={dropdownOpen ? "btn-large transparency-dropdown-after" : "btn-large  transparency-dropdown"} isOpen={dropdownOpen} toggle={toggleDeviceType}>
                                            <DropdownToggle name="deviceType" style={{textAlign: 'left', minWidth: '164px'}} caret>
                                                {componentState.deviceType}
                                            </DropdownToggle>
                                            <DropdownMenu
                                                modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true,
                                                        order: 890,
                                                        fn: (data) => {
                                                            return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflowY: 'auto',
                                                                maxHeight: '160px',
                                                            },
                                                            };
                                                        },
                                                    },
                                                }}
                                            >
                                                {props.deviceTypeList.map((item, index) => {
                                                    return (
                                                        <DropdownItem key={index} onClick={() => {
                                                            // setSelectedItem(item.key);
                                                            setComponentState({ ...componentState, deviceType: getOtherDeviceType(item.value), deviceTypeCode: item.key });
                                                        }}>{getOtherDeviceType(item.value)}
                                                        </DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FormGroup>                                    
                                </Col>
                                <Col xs={{size: 3}} md={{size: 4}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.deviceListView.modals.addDevice.labels.deviceName')}</Label>
                                        <Input type="text" name="deviceName" placeholder={t('app.pages.deviceListView.modals.addDevice.placeholders.deviceName')} value={componentState.deviceName} onChange={handleOnChange}></Input>
                                    </FormGroup>
                                </Col>
                                <Col xs={{size: 4}} md={{size: 3}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.deviceListView.modals.addDevice.labels.devicePhone')}</Label>
                                        <Input type="text" name="devicePhone" placeholder={t('app.pages.deviceListView.modals.addDevice.placeholders.devicePhone')} value={componentState.devicePhone} onChange={handleOnChange} onKeyDown={onValidateNumber} onFocus={handleOnFocus} onInput={onUpdateDeviceEmail} minLength={9} maxLength={9}></Input>
                                    </FormGroup>
                                </Col>
                                <Col xs={{size: 4}} md={{size: 3}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.deviceListView.modals.addDevice.labels.deviceIMEI')}</Label>
                                        <Input type="text" name="deviceIMEI" placeholder={t('app.pages.deviceListView.modals.addDevice.placeholders.deviceIMEI')} value={componentState.deviceIMEI} onChange={handleOnChange} onKeyDown={onValidateNumber} onFocus={handleOnFocus} minLength={15} maxLength={15}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{size: 6}} md={{size: 6}} style={{paddingLeft: '0px'}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.deviceListView.modals.addDevice.labels.deviceEmail')}</Label>
                                        <div id="input-container">
                                            <Input type="text" name="deviceEmail" placeholder={componentState.deviceEmailPlaceholder} value={componentState.deviceEmail} onChange={handleOnChange} readOnly></Input>
                                            <i className="far fa-clipboard fa-2x" id="input-img" title={t('app.pages.deviceListView.messages.copyToClipboard')} onClick={handleCopyToClipboard}></i>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={{size: 6}} md={{size: 3}}>
                                    <FormGroup>
                                        <Label>{t('app.pages.deviceListView.modals.addDevice.labels.deviceNo')}</Label>
                                        <Input type="text" name="deviceNo" placeholder={t('app.pages.deviceListView.modals.addDevice.placeholders.deviceNo')} value={componentState.deviceNo} onChange={handleOnChange} onKeyDown={onValidateKeyCode} onInput={onValidateSerial} minLength={4} maxLength={4}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    ) : (
                        <></>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" className="btn-large" onClick={handleOnSubmit}>{modalOpenMode === "NEW" ? t('app.pages.deviceListView.modals.addDevice.submitText') : t('app.pages.deviceListView.modals.addDevice.submitText2')}</Button>{' '}
                    <Button color="secondary" className="transparency btn-large" onClick={toggle}>{t('app.pages.deviceListView.modals.addDevice.cancelText')}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    deviceTypeList: state.device.deviceTypeList || [],
    customerCode: state.session.customer,
    selectedDevice: state.customer.deviceSelected
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeviceEdit);