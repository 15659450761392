import React, { useState } from 'react';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import { connect } from "react-redux";

import { useTranslation } from 'react-i18next';
import TextArea from '../components/ui/textArea';

import { setElementIdProperty } from '../utilities/dom'

import '../app.css';

import Bootstrap from '../components/bootstrap'

const deepEqual = require('deep-equal')

const Rodo = (props) => {
	const { t } = useTranslation();

	const usePrevious = value => {
        const ref = React.useRef();
     
        React.useEffect(() => {
            ref.current = value;
        }, [value]);
     
        return ref.current;
    }

	const usePropState = datas => {
        const [dataset, setDataset] = useState(datas);
        const prevDatas = usePrevious(datas);
     
        const handleChangeDataset = data => setDataset(data);
     
        React.useEffect(() => {
            if (!deepEqual(datas, prevDatas)) {
                setDataset(datas);
				if (isUserLoggedIn()) {
					setElementIdProperty("root", "overflow-y", "auto")
				}
                handleReadRODO()
            }
        }, [datas, prevDatas]);
     
        return [
            dataset,
            handleChangeDataset
        ]
    }

    const [componentState, setComponentState] = usePropState({
		rodoAggreement: ""
    })

	const isUserLoggedIn = () => {
		return (props.isAuthUser || false)
	}

    const handleReadRODO = () => {
		fetch("/RODO")
            .then((response) => response.text())
            .then((data) => {
                setComponentState({ ...componentState, rodoAggreement: data})
            })
	}
	
	return (
		<>
			<Bootstrap />
			<div className="wrapper">
				<div className="content">
					<div className="rodo-content-inside">
						<div className="rodo-container">
							<h5><span className="rodo-title">{t('app.pages.rodo.title')}</span></h5>
							<Form className="rodo-form">
								<div className="v-space-tiny"></div>

								<FormGroup>
									<Row>
										<Col>
											<FormGroup>
    											<TextArea name="rodoClause" className="rodoMain" rows="30" title={t('app.pages.rodo.labels.rodoClause')} readOnly={true} value={componentState.rodoAggreement} />
											</FormGroup>
										</Col>
									</Row>
								</FormGroup>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    isAuthUser: state.session.isAuthUser
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(Rodo)