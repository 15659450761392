// Author: Teldasoft(c) 2021
// Module: actions.js
// Description: Action creators for 'folder' middleware

import types from './types'

const insertFolder = (status) => {
    return {
        type: types.API_FOLDER_INSERT,
        payload: { 
            insertStatus: status
        }
    };
};

const updateFolder = (status) => {
    return {
        type: types.API_FOLDER_UPDATE,
        payload: {
            updateStatus: status
        }
    };
};

const deleteFolder = (status) => {
    return {
        type: types.API_FOLDER_DELETE,
        payload: {
            deleteStatus: status
        }
    };
};

const cleanupFolder = () => {
    return {
        type: types.API_FOLDER_CLEANUP,
        payload: {}
    };
};

const refreshFolders = () => {
    return {
        type: types.API_FOLDER_REFRESH,
        payload: {}
    };
};

const selectFolders = (folderList) => {
    return {
        type: types.API_FOLDER_SELECT,
        payload: {
            folderList: folderList
        }
    };
};

const selectFolder = (folderType, folderName, folderDisplayName, folderDescription, folderId) => {
    return {
        type: types.API_FOLDER_SELECT_ROW,
        payload: {
            folderType: folderType,
            folderName: folderName,
            folderDisplayName: folderDisplayName,
            folderDescription: folderDescription,
            folderId: folderId
        }
    };
};

const selectFolderTypes = (folderTypeList) => {
    return {
        type: types.API_FOLDER_TYPE_SELECT,
        payload: {
            folderTypeList: folderTypeList
        }
    };
};

const tagFolderImage = (customerCode, srcFolder, dstFolder, dstFolderType, itemResourceId, itemUser) => {
    return {
        type: types.API_FOLDER_TAG_IMAGE,
        payload: {
        }
    };
}

const tagFolderImages = (customerCode, srcFolder, dstFolder, dstFolderType, itemResourceList, itemUser) => {
    return {
        type: types.API_FOLDER_TAG_IMAGES,
        payload: {
        }
    };
}

const removeFolderImages = (customerCode, itemFolder, itemFolderType, itemResourceList) => {
    return {
        type: types.API_FOLDER_REMOVE_IMAGES,
        payload: {
        }
    };
}

const error = (msg) => {
    return {
        type: types.API_FOLDER_ERROR,
        payload: msg
    };
};

export default {
    insertFolder,
    updateFolder,
    deleteFolder,
    cleanupFolder,
    refreshFolders,
    selectFolders,
    selectFolder,
    selectFolderTypes,
    tagFolderImage,
    tagFolderImages,
    removeFolderImages,
    error
}
