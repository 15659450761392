import React from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Row, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { setFieldValidationError, setFieldValidationReset, setFocusOnInput, setCheckboxChecked } from "../utilities/dom"

import TextArea from '../components/ui/textArea';
import LicenseCards from '../components/ui/licenseCards';
import PopupAlert from '../components/ui/popupAlert'
import PopupVerifyEmail from '../components/ui/popupVerifyEmail'
import PopupRegister from '../components/ui/popupRegister'

import '../app.css';

import Bootstrap from '../components/bootstrap'
import queryString from 'query-string'

import { getApi } from "../utilities/api"
import { setElementClassAdd, setElementClassRemove } from "../utilities/dom"
import { onValidateURL, onValidateEmail, onValidatePhoneNumber, onValidateNIP, onValidateREGON, onValidatePostalCode } from '../utilities/validators'
import { default as licenseTypes } from '../utilities/system/licenseTypes';

const Checkbox = (props) => (
	<input type="checkbox" {...props} />
)

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companyName: "",
            companyShortName: "",
			contactEmail: "",
			companyWebSite: "",
			companyPhone1: "",
			companyPhone2: "",

			NIP: "",
			REGON: "",

			contactFirstName: "",
			contactLastName: "",
			contactMobile: "",
			addressCountry: "",
			addressCity: "",
			addressStreet: "",
			addressBuildingNo: "",
			addressPostalCode: "",

			registrationActive: false,
			registrationCompleted: false,

			issueInvoiceChecked: false,
			contactDetailsChecked: false,
			addressDetailsChecked: false,

			wizardStep: 1,
			wizardStepsCount: 3,
			wizardStepValidated: false,
			wizardEmailVerified: false,
			wizardEmailVerifiedError: "",
			wizardEmailReverification: false,
			
			tokenExpired: false,

			licenseOffer: [],
			licenseAggreement: "",
			licenseDemoConfirmed: (sessionStorage.getItem('registerSelectedLicense') === licenseTypes.REG_LICENSE_DEMO) || false,
			licenseConfirmed: false,
			licenseDefault: sessionStorage.getItem('registerSelectedLicense') || licenseTypes.REG_LICENSE_ADVANCED,
			licenseTypeSelection: localStorage.getItem('registerSelectedLicense'),

			rodoAggreement: "",
			rodoConfirmed: false,

			validationError: "",

			applicationCode: 'PG',
			applicationProps: []
        }
    }

	t = (key) => {
        return this.props.t(key)
    }

	componentDidMount() {
		this.handleReadLicense()
		this.handleReadLicenseOffer()
		this.handleReadRODO()
		console.log(sessionStorage.getItem('registerSelectedLicense'))
		const selectedLicenseType = localStorage.getItem('registerSelectedLicense')
		if (selectedLicenseType !== undefined) {
			localStorage.removeItem('registerSelectedLicense')
		}

		this.query = queryString.parse(window.location.search)

		if (this.isQueryTokenAvailable()) {
			console.log('Confirm verification')
			fetch(getApi(`/api/v1/register/confirmEmail?token=${this.query.token}`))
				.then((response) => response.json())
				.then((data) => {
					if (data && (data.statusCode === 200)) {
						this.setState({wizardEmailVerified: true, wizardEmailReverification: false})
					} else {
						this.setState({wizardEmailVerified: false, tokenExpired: true})
					}
				})
		}
	}

	isQueryTokenAvailable = () => {
		return (this.query && (this.query.token !== undefined) && (this.query.token !== ""))
	}

	isRegistrationCompleted = () => {
		return (this.state.registrationCompleted === true)
	}

	getIssueInvoiceDocType = () => {
		if (this.state.issueInvoiceChecked) {
			if ((this.state.companyShortName !== "") && (this.state.NIP !== "")) {
				return "FV"
			} else {
				return "P"
			}
		}
		return ""
	}

	handleReadAppProps = () => {
		console.log('Reading registration config info')
		fetch(getApi(`/api/v1/app/props/${this.state.applicationCode}`))
			.then((response) => response.json())
			.then((data) => {
				this.setState({applicationProps: data})
			})
	}

	handleVerifyEmailAddress = () => {
		console.log('Send verification')
		const reverificationEnabled = (this.state.wizardEmailReverification ? "&reverify=true" : "")
		fetch(getApi(`/api/v1/register/sendVerification?to=${this.state.contactEmail}${reverificationEnabled}`))
			.then((response) => response.json())
			.then((data) => {
				this.setState({wizardEmailVerified: false, wizardEmailReverification: false, wizardEmailVerifiedError: "", tokenExpired: false})
				if (typeof data === "string") {
					this.setState({wizardEmailVerifiedError: data})
				}
			})
	}

	handleConfirmEmailAddressVerification = (query) => {
		console.log('Check verification')
		fetch(getApi(`/api/v1/register/checkConfirmation?email=${this.state.contactEmail}`))
			.then((response) => response.json())
			.then((data) => {
				if (typeof data === "string") {
					this.setState({wizardEmailVerifiedError: data})
				} else {
					if (data && (data.statusCode === 200)) {
						this.setState({wizardEmailVerified: true, wizardEmailVerifiedError: "", wizardEmailReverification: false})
						this.handleRegistrationNext()
					} else {
						this.setState({wizardEmailVerified: false, wizardEmailReverification: false})
						if (data.statusCode === 404) {
							console.log('E-mail not verified')
							this.setState({wizardEmailVerifiedError: data.message})
						}
					}
				}
			})
	}

	handleValidationErrorMessage = (msg) => {
		this.setState({validationError: msg})
	}

	handleValidateForm = (stepNo) => {
		const { companyName, companyShortName, companyWebSite, contactEmail, companyPhone1,
			companyPhone2, contactMobile, NIP, REGON, contactFirstName, contactLastName,
			addressCountry, addressCity, addressStreet, addressBuildingNo, addressPostalCode } = this.state

		if (stepNo === 1) {
			if (companyName === '') {
				setFieldValidationError("companyName")
				this.handleValidationErrorMessage(this.t('app.pages.register.messages.misCompanyName'))
				return false
			}
			
			if (companyWebSite !== '') {
				if (!onValidateURL(companyWebSite)) {
					setFieldValidationError("companyWebSite")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.badWebSite'))
					return false
				}
			}
			
			if (contactEmail === '') {
				setFieldValidationError("contactEmail")
				this.handleValidationErrorMessage(this.t('app.pages.register.messages.misContactEmail'))
				return false
			}
			else if (!onValidateEmail(contactEmail)) {
				setFieldValidationError("contactEmail")
				this.handleValidationErrorMessage(this.t('app.pages.register.messages.badEmail'))
				return false
			}

			if (companyPhone1 === '') {
				setFieldValidationError("companyPhone1")
				this.handleValidationErrorMessage(this.t('app.pages.register.messages.misPhone'))
				return false
			}
			else if (!onValidatePhoneNumber(companyPhone1)) {
				setFieldValidationError("companyPhone1")
				this.handleValidationErrorMessage(this.t('app.pages.register.messages.badPhone'))
				return false
			}

			if (companyPhone2 !== '') {
				if (!onValidatePhoneNumber(companyPhone2)) {
					setFieldValidationError("companyPhone2")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.badPhone'))
					return false
				}
			}

			if (companyShortName !== '') {
				if (NIP === '') {
					setFieldValidationError("NIP")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.misNIP'))
					return false
				}
			}

			if (NIP !== '') {
				if (!onValidateNIP(NIP)) {
					setFieldValidationError("NIP")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.badNIP'))
					return false
				}
			}

			if (REGON !== '') {
				if (!onValidateREGON(REGON)) {
					setFieldValidationError("REGON")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.badREGON'))
					return false
				}
			}

			if (this.state.contactDetailsChecked) {
				if (contactFirstName === '') {
					setFieldValidationError("contactFirstName")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.misFirstName'))
					return false
				}

				if (contactLastName === '') {
					setFieldValidationError("contactLastName")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.misLastName'))
					return false
				}

				if (contactMobile !== '') {
					if (!onValidatePhoneNumber(contactMobile, true)) {
						setFieldValidationError("contactMobile")
						this.handleValidationErrorMessage(this.t('app.pages.register.messages.badPhone'))
						return false
					}
				}
			}

			if (this.state.addressDetailsChecked) {
				if (addressCountry === '') {
					setFieldValidationError("addressCountry")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.misCountry'))
					return false
				}

				if (addressCity === '') {
					setFieldValidationError("addressCity")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.misCity'))
					return false
				}

				if (addressStreet === '') {
					setFieldValidationError("addressStreet")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.misStreet'))
					return false
				}

				if (addressBuildingNo === '') {
					setFieldValidationError("addressBuildingNo")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.misFlat'))
					return false
				}

				if (addressPostalCode === '') {
					setFieldValidationError("addressPostalCode")
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.misPostalCode'))
					return false
				}

				if (addressPostalCode !== '') {
					if (!onValidatePostalCode(addressPostalCode)) {
						setFieldValidationError("addressPostalCode")
						this.handleValidationErrorMessage(this.t('app.pages.register.messages.badPostalCode'))
						return false
					}
				}
			}

			if (this.state.issueInvoiceChecked && !this.state.addressDetailsChecked) {
				//if ((companyShortName !== '') && (NIP !== '')) {
					this.handleValidationErrorMessage(this.t('app.pages.register.messages.misAddressForInvoice'))
					return false
				//}
			}

			this.handleValidationErrorMessage("")
			return true
		} else if (stepNo === 2) {
			//TODO

			return true
		} else if (stepNo === 3) {
			//TODO

			return true
		}

		return true
	}

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ loginMessage: "" })
		
		setFieldValidationReset(e.target.name)
		if (e.target.name === "contactEmail") {
			if (this.state.wizardEmailVerified) {
				this.setState({wizardEmailVerified: false})
				this.setState({wizardEmailReverification: true})
			}
			this.setState({wizardEmailVerified: false})
		}
		else if (e.target.name === 'companyShortName') {
			setFieldValidationReset('NIP')
		}
    }

	handleIssueInvoiceInfo = (e) => {
		const { companyShortName, NIP } = this.state
		if (e.type === 'click') {
			setCheckboxChecked("cbIssueInvoice", !this.state.issueInvoiceChecked)
		}
		this.setState({issueInvoiceChecked: !this.state.issueInvoiceChecked})

		if ((companyShortName !== '') && (NIP !== '')) {
			if (this.state.issueInvoiceChecked && !this.state.addressDetailsChecked) {
				return true
			}
			setCheckboxChecked("cbAddressDetails", !this.state.addressDetailsChecked)
			this.setState({addressDetailsChecked: !this.state.addressDetailsChecked})
		}
	}

	handleCheckContactInfo = (e) => {
		if (e.type === 'click') {
			setCheckboxChecked("cbContactDetails", !this.state.contactDetailsChecked)
		}
		this.setState({contactDetailsChecked: !this.state.contactDetailsChecked})
		setFocusOnInput("contactFirstName")
	}
	
	handleCheckAddressInfo = (e) => {
		if (e.type === 'click') {
			setCheckboxChecked("cbAddressDetails", !this.state.addressDetailsChecked)
		}
		this.setState({addressDetailsChecked: !this.state.addressDetailsChecked})
		setFocusOnInput("addressCountry")
	}

	handleRegistrationNext = (e) => {
        if (e) {
			e.preventDefault();
		}
		if (!this.handleValidateForm(this.state.wizardStep)) {
			localStorage.removeItem('wizardStepOneValidated')
			return
		} else {
			localStorage.setItem('wizardStepOneValidated', true)
		}

		if (this.state.wizardStep === this.state.wizardStepsCount) {
			console.log('Registering...')
		} else {
			if (this.state.wizardStep === 1) {
				if (this.state.wizardEmailVerified) {
					this.setState({wizardStep: (this.state.wizardStep + 1)})
				}
			} else {
				this.setState({wizardStep: (this.state.wizardStep + 1)})
			}
		}
	}

	handleRegistrationPrev = (e) => {
		e.preventDefault();

		this.setState({wizardStep: (this.state.wizardStep - 1)})
	}

	handleReadLicense = () => {
		fetch("/LICENSE")
            .then((response) => response.text())
            .then((data) => {
                this.setState({ licenseAggreement: data })
            })
	}

	handleReadRODO = () => {
		fetch("/RODO")
            .then((response) => response.text())
            .then((data) => {
                this.setState({ rodoAggreement: data })
            })
	}

	handleReadLicenseOffer = () => {
		fetch(getApi("/api/v1/license/registration"))
            .then((response) => response.json())
            .then((data) => {
                this.setState({ licenseOffer: data })
            })
	}

	handleLicenseDemoUpdateCards = () => {
		if (this.state.licenseDemoConfirmed) {
			localStorage.setItem('registerSelectedLicense', (this.state.licenseTypeSelection || licenseTypes.REG_LICENSE_ADVANCED))
		} else {
			this.setState({licenseTypeSelection: localStorage.getItem('registerSelectedLicense')})
			localStorage.setItem('registerSelectedLicense', licenseTypes.REG_LICENSE_DEMO)
		}
	}

	handleLicenseDemoConfirm = (e) => {
		if (sessionStorage.getItem('registerSelectedLicense')) {
			sessionStorage.removeItem('registerSelectedLicense')
		}
		if (e.type === 'click') {
			setCheckboxChecked("cbLicenseDemoConfirm", !this.state.licenseDemoConfirmed)
		}
		this.setState({licenseDemoConfirmed: !this.state.licenseDemoConfirmed})
		this.handleLicenseDemoUpdateCards()
	}

	handleLicenseConfirm = (e) => {
		if (e.type === 'click') {
			setCheckboxChecked("cbLicenseConfirm", !this.state.licenseConfirmed)
		}
		this.setState({licenseConfirmed: !this.state.licenseConfirmed})
	}

	handleRODOConfirm = (e) => {
		if (e.type === 'click') {
			setCheckboxChecked("cbRODOConfirm", !this.state.rodoConfirmed)
		}
		this.setState({rodoConfirmed: !this.state.rodoConfirmed})
	}

	handleCompleteRegistration = () => {
		const selectedLicense = localStorage.getItem('registerSelectedLicense') || this.state.licenseDefault;
		if (this.state.rodoConfirmed && this.state.licenseConfirmed && (selectedLicense !== undefined)) {
			let selectedLicenseId = null;
			this.state.licenseOffer.map((item, index) => {
				if (item.licenseType === selectedLicense) {
					selectedLicenseId = item.licenseId
				}
			})

			fetch(getApi('/api/v1/register/unapproved'),
				{ method: 'POST',
				  headers: { 'Content-Type': 'application/json' },
				  body: JSON.stringify({
					companyName: this.state.companyName,
					companyShortName: this.state.companyShortName,
					companyWebSite: this.state.companyWebSite,
					companyPhone1: this.state.companyPhone1,
					companyPhone2: this.state.companyPhone2,
		
					companyNip: this.state.NIP,
					companyRegon: this.state.REGON,
		
					contactEmail: this.state.contactEmail,
					contactFirstName: this.state.contactFirstName,
					contactLastName: this.state.contactLastName,
					contactMobile: this.state.contactMobile,

					addressCountry: this.state.addressCountry,
					addressCity: this.state.addressCity,
					addressStreet: this.state.addressStreet,
					addressBuildingNo: this.state.addressBuildingNo,
					addressPostalCode: this.state.addressPostalCode,
		
					isContact: this.state.contactDetailsChecked ? "true" : "false",
					isAddress: this.state.addressDetailsChecked ? "true" : "false",

					licenseId: selectedLicenseId,
					applicationCode: 'PG',
					issueFinDocType: this.getIssueInvoiceDocType()
					// token: ""
				})
				})
				.then((response) => response.json())
				.then((data) => {
					this.setState({registrationCompleted: true})
				})
		}
	}

	handleRedirectOnComplete = () => {
		window.location = "/"
	}

	render() {
		const { companyName, companyShortName, NIP, REGON, contactEmail,
			companyWebSite, companyPhone1, companyPhone2,
			issueInvoiceChecked, contactDetailsChecked, addressDetailsChecked,
			contactFirstName, contactLastName, contactMobile,
			addressCountry, addressCity, addressStreet, addressBuildingNo, addressPostalCode } = this.state

		return (
			<>
				<Bootstrap />
				<div className="wrapper">
					<div className="content">
						<div className="register-content-inside">
							<div className="register-container">
								<h5><span className="register-title">{!this.isQueryTokenAvailable() ? this.t('app.pages.register.title') : !this.state.tokenExpired ? this.t('app.pages.register.title1') : this.t('app.pages.register.title2')}&nbsp;&nbsp;{this.isQueryTokenAvailable() ? <i className={!this.state.tokenExpired ? "fas fa-check fa-green-color" : "fas fa-times fa-red-color"}></i> : <></>}</span></h5>
								{!this.isQueryTokenAvailable() ? (
									<h5><span className="register-step">{this.t('app.pages.register.headlines.step') + " " + this.state.wizardStep + " / " + this.state.wizardStepsCount}</span></h5>
								) : (
									<></>
								)}

								<Form className="register-form">
									<div className="v-space-tiny"></div>

									{(this.state.wizardEmailVerifiedError !== "") ? (
										<PopupAlert
											key={this.state.wizardEmailVerifiedError}
											popupOpenToggle={true}
											popupSize="lg"
											popupOpenWith="None"
											popupOpenWithClass=""
											popupHeaderTitle={this.t('app.pages.register.modals.emailNotVerified.title')}
											popupOpenBtnTitle=""
											popupBodyText={this.state.wizardEmailVerifiedError}
											popupHeaderIconClassName="fas fa-exclamation-triangle font-red"
											popupSubmitBtnText={this.t('app.pages.register.modals.emailNotVerified.cancelText')}
											popupEscapeListener={true}
											popupOnSubmitHandler={() => {
												this.setState({wizardEmailVerifiedError: ""})
											}}
										/>
									) : (
										<></>
									)}

									{this.isRegistrationCompleted() ? (
										<PopupAlert
											popupOpenToggle={true}
											popupSize="md"
											popupOpenWith="None"
											popupOpenWithClass=""
											popupHeaderTitle={this.t('app.pages.register.modals.registrationCompleted.title')}
											popupOpenBtnTitle=""
											popupBodyText={this.t('app.pages.register.modals.registrationCompleted.bodyText')}
											popupHeaderIconClassName="far fa-check-square"
											popupSubmitBtnText={this.t('app.pages.register.modals.registrationCompleted.cancelText')}
											popupEscapeListener={true}
											popupOnSubmitHandler={this.handleRedirectOnComplete}
										/>
									) : (
										<></>
									)}

									{this.isQueryTokenAvailable() ? (
										<FormGroup>
											<Row>
												<Col span={2}>
													{!this.state.tokenExpired ? (
														<Label className="float-left">{this.t('app.pages.register.messages.emailVerified')}</Label>
													) : (
														<Label className="float-left">{this.t('app.pages.register.messages.emailNotVerified')}</Label>
													)}
												</Col>
											</Row>
											<Row>
												<Col span={2}>
													<Button onClick={() => {
														window.open("about:blank", "_self");
														window.close();
													}} style={{textAlign: 'center', display: 'block', margin: '0 auto', width: '120px', marginTop: '20px'}}>{this.t('app.pages.register.buttons.close')}</Button>
												</Col>
											</Row>
										</FormGroup>
									) : (
										<>
											{(this.state.wizardStep === 1) ? (
												<>
													<FormGroup>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.companyName')} *</Label>
																<Input type="text" name="companyName" placeholder={this.t('app.pages.register.placeholders.companyName')} value={companyName} onChange={this.handleChange} autoFocus></Input>
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.companyShortName')}</Label>
																<Input type="text" name="companyShortName" placeholder={this.t('app.pages.register.placeholders.companyShortName')} value={companyShortName} onChange={this.handleChange}></Input>
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.webSite')}</Label>
																<Input type="text" name="companyWebSite" placeholder={this.t('app.pages.register.placeholders.webSite')} value={companyWebSite} onChange={this.handleChange}></Input>
															</Col>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.contactEmail')} *</Label>
																<Input type="text" name="contactEmail" placeholder={this.t('app.pages.register.placeholders.contactEmail')} value={contactEmail} onChange={this.handleChange}></Input>
															</Col>
														</Row>
													</FormGroup>
													<FormGroup>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.phone')} *</Label>
																<Input type="text" name="companyPhone1" placeholder={this.t('app.pages.register.placeholders.phone')} value={companyPhone1} onChange={this.handleChange} maxLength={20}></Input>
															</Col>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.secondaryPhone')}</Label>
																<Input type="text" name="companyPhone2" placeholder={this.t('app.pages.register.placeholders.secondaryPhone')} value={companyPhone2} onChange={this.handleChange} maxLength={20}></Input>
															</Col>
														</Row>
													</FormGroup>
													<div className="v-space-tiny"></div>
													<FormGroup>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.nip')}</Label>
																<Input type="text" name="NIP" placeholder={this.t('app.pages.register.placeholders.nip')} value={NIP} onChange={this.handleChange} maxLength={10}></Input>
															</Col>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.regon')}</Label>
																<Input type="text" name="REGON" placeholder={this.t('app.pages.register.placeholders.regon')} value={REGON} onChange={this.handleChange}></Input>
															</Col>
														</Row>
													</FormGroup>
													<div className="v-space-tiny"></div>
													<FormGroup>
														<Row>
															<Checkbox className="checkbox-register" name="cbContactDetails" data-name={"cb-contact-details"} title={this.t('app.pages.register.buttons.checkbox.contactInfo.hint')} defaultChecked={contactDetailsChecked} onChange={this.handleCheckContactInfo}></Checkbox>&nbsp;
															<Label className="checkbox-label" onClick={this.handleCheckContactInfo}>{this.t('app.pages.register.buttons.checkbox.contactInfo.label')}</Label>
														</Row>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.firstName')}</Label>
																{contactDetailsChecked ? (
																	<Input className="" type="text" name="contactFirstName" placeholder={this.t('app.pages.register.placeholders.firstName')} value={contactFirstName} onChange={this.handleChange} autoFocus></Input>
																) : (
																	<Input className="disabled" type="text" name="contactFirstName" placeholder={this.t('app.pages.register.placeholders.firstName')} value={contactFirstName} disabled></Input>
																)}
															</Col>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.lastName')}</Label>
																{contactDetailsChecked ? (
																	<Input className="" type="text" name="contactLastName" placeholder={this.t('app.pages.register.placeholders.lastName')} value={contactLastName} onChange={this.handleChange}></Input>
																) : (
																	<Input className={"disabled"} type="text" name="contactLastName" placeholder={this.t('app.pages.register.placeholders.lastName')} value={contactLastName} disabled></Input>
																)}
															</Col>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.mobile')}</Label>
																{contactDetailsChecked ? (
																	<Input className="" type="text" name="contactMobile" placeholder={this.t('app.pages.register.placeholders.mobile')} value={contactMobile} onChange={this.handleChange} maxLength={20}></Input>
																) : (
																	<Input className="disabled" type="text" name="contactMobile" placeholder={this.t('app.pages.register.placeholders.mobile')} value={contactMobile} disabled></Input>
																)}
															</Col>
														</Row>
													</FormGroup>
													<div className="v-space-tiny"></div>
													<FormGroup>
														<Row>
															<Checkbox className="checkbox-register" name="cbAddressDetails" data-name={"cb-address-details"} title={this.t('app.pages.register.buttons.checkbox.addressInfo.hint')} defaultChecked={addressDetailsChecked} onChange={this.handleCheckAddressInfo}></Checkbox>&nbsp;
															<Label className="checkbox-label" onClick={this.handleCheckAddressInfo}>{this.t('app.pages.register.buttons.checkbox.addressInfo.label')}</Label>
														</Row>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.country')}</Label>
																{addressDetailsChecked ? (
																	<Input className="" type="text" name="addressCountry" placeholder={this.t('app.pages.register.placeholders.country')} value={addressCountry} onChange={this.handleChange} autoFocus></Input>
																) : (
																	<Input className="disabled" type="text" name="addressCountry" placeholder={this.t('app.pages.register.placeholders.country')} value={addressCountry} disabled></Input>
																)}
															</Col>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.city')}</Label>
																{addressDetailsChecked ? (
																	<Input className="" type="text" name="addressCity" placeholder={this.t('app.pages.register.placeholders.city')} value={addressCity} onChange={this.handleChange}></Input>
																) : (
																	<Input className={"disabled"} type="text" name="addressCity" placeholder={this.t('app.pages.register.placeholders.city')} value={addressCity} disabled></Input>
																)}
															</Col>
															<Col>
															</Col>
														</Row>
														<div className="v-space-tiny"></div>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.street')}</Label>
																{addressDetailsChecked ? (
																	<Input className="" type="text" name="addressStreet" placeholder={this.t('app.pages.register.placeholders.street')} value={addressStreet} onChange={this.handleChange}></Input>
																) : (
																	<Input className="disabled" type="text" name="addressStreet" placeholder={this.t('app.pages.register.placeholders.street')} value={addressStreet} disabled></Input>
																)}
															</Col>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.flat')}</Label>
																{addressDetailsChecked ? (
																	<Input className="" type="text" name="addressBuildingNo" placeholder={this.t('app.pages.register.placeholders.flat')} value={addressBuildingNo} onChange={this.handleChange}></Input>
																) : (
																	<Input className={"disabled"} type="text" name="addressBuildingNo" placeholder={this.t('app.pages.register.placeholders.flat')} value={addressBuildingNo} disabled></Input>
																)}
															</Col>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.labels.postalCode')}</Label>
																{addressDetailsChecked ? (
																	<Input className="" type="text" name="addressPostalCode" placeholder={this.t('app.pages.register.placeholders.postalCode')} value={addressPostalCode} onChange={this.handleChange} maxLength={6}></Input>
																) : (
																	<Input className="disabled" type="text" name="addressPostalCode" placeholder={this.t('app.pages.register.placeholders.postalCode')} value={addressPostalCode} disabled></Input>
																)}
															</Col>
														</Row>
													</FormGroup>
													<div className="v-space-tiny"></div>
													<FormGroup>
														<Row>
															<Checkbox className="checkbox-invoice" name="cbIssueInvoice" data-name={"cb-issue-invoice"} title={this.t('app.pages.register.buttons.checkbox.issueInvoiceInfo.hint')} defaultChecked={issueInvoiceChecked} onChange={this.handleIssueInvoiceInfo}></Checkbox>&nbsp;
															<Label className="checkbox-label" onClick={this.handleIssueInvoiceInfo}>{this.t('app.pages.register.buttons.checkbox.issueInvoiceInfo.label')}</Label>
														</Row>
														<Row>
															<span style={{color: 'lightyellow', fontSize: '0.8em', marginLeft: '44px'}}>{this.t('app.pages.register.headlines.issueLicenseTip')}</span>
															<span style={{color: 'lightyellow', fontSize: '0.8em', marginLeft: '56px'}}>{this.t('app.pages.register.headlines.issueLicenseTip2')}</span>
														</Row>
														<div className="v-space-small"></div>
													</FormGroup>
												</>
											) : (
												<></>
											)}

											{(this.state.wizardStep === 2) ? (
												<>
													<FormGroup>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.headlines.selectLicense')}</Label>
															</Col>
														</Row>
														<div className="v-space-tiny"></div>
														<Row>
															<Col>
																<Checkbox className="checkbox-license" name="cbLicenseDemoConfirm" data-name={"cb-license-demo-confirm"} title={this.t('app.pages.register.buttons.checkbox.confirmLicenseDemo.hint')} defaultChecked={this.state.licenseDemoConfirmed} onChange={this.handleLicenseDemoConfirm}></Checkbox>&nbsp;
																<Label className="checkbox-label" onClick={this.handleLicenseDemoConfirm}>{this.t('app.pages.register.buttons.checkbox.confirmLicenseDemo.label')}</Label>
															</Col>
														</Row>
														<Row>
															<LicenseCards offer={this.state.licenseOffer} />
														</Row>
														<Row>
															<Col>
																<TextArea rows="5" title={this.t('app.pages.register.labels.licenseTerms')} readOnly={true} value={this.state.licenseAggreement} />
															</Col>
														</Row>
														<Row>
															<Col>
																<Checkbox className="checkbox-license" name="cbLicenseConfirm" data-name={"cb-license-confirm"} title={this.t('app.pages.register.buttons.checkbox.confirmLicense.hint')} defaultChecked={this.state.licenseConfirmed} onChange={this.handleLicenseConfirm}></Checkbox>&nbsp;
																<Label className="checkbox-label" onClick={this.handleLicenseConfirm}>{this.t('app.pages.register.buttons.checkbox.confirmLicense.label')}</Label>
															</Col>
														</Row>
													</FormGroup>
												</>
											) : (
												<></>
											)}

											{(this.state.wizardStep === 3) ? (
												<>
													<FormGroup>
														<Row>
															<Col>
																<Label className="float-left">{this.t('app.pages.register.headlines.readRODO')}</Label>
															</Col>
														</Row>
														<Row>
															<Col>
																<TextArea rows="30" className="rodo" title={this.t('app.pages.register.labels.rodoTerms')} readOnly={true} value={this.state.rodoAggreement} />
															</Col>
														</Row>
														<Row>
															<Col>
																<Checkbox className="checkbox-rodo" name="cbRODOConfirm" data-name={"cb-rodo-confirm"} title={this.t('app.pages.register.buttons.checkbox.confirmRODO.hint')} defaultChecked={this.state.rodoConfirmed} onChange={this.handleRODOConfirm}></Checkbox>&nbsp;
																<Label className="checkbox-label" onClick={this.handleRODOConfirm}>{this.t('app.pages.register.buttons.checkbox.confirmRODO.label')}</Label>
															</Col>
														</Row>
													</FormGroup>
												</>
											) : (
												<></>
											)}

											<FormGroup>
												<Row>
													<Col>
														<Label name="validation-errors" className="validation-error-message">{this.state.validationError}</Label>
													</Col>
													<Col span={2} style={{textAlign: 'end'}}>
														{(this.state.wizardStep > 1) ? (
															<Button className="register-button" onClick={this.handleRegistrationPrev}>&laquo; {this.t('app.pages.register.buttons.prev')}</Button>
														) : (
															<></>
														)}
														&nbsp;&nbsp;
														{(this.state.wizardStep < 3) ? (
															(this.state.wizardStep === 2) ? (
																(this.state.licenseConfirmed && (localStorage.getItem('registerSelectedLicense') !== undefined)) ? (
																	<Button className="register-button" onClick={this.handleRegistrationNext}>{this.t('app.pages.register.buttons.next')} &raquo;</Button>
																) : (
																	<Button className="register-button disabled" disabled>{this.t('app.pages.register.buttons.next')} &raquo;</Button>
																)
															) : (
																<PopupVerifyEmail
																	popupSize="lg"
																	popupHeaderTitle={this.t('app.pages.register.modals.verifyEmail.title')}
																	popupOpenBtnTitle={this.t('app.pages.register.buttons.next')}
																	popupBodyText={!this.state.wizardEmailReverification ? this.t('app.pages.register.modals.verifyEmail.bodyText1') : this.t('app.pages.register.modals.verifyEmail.bodyText2')}
																	popupBodyText2={this.t('app.pages.register.modals.verifyEmail.bodyText3')}
																	popupHeaderIconClassName="fas fa-check"
																	popupSubmitBtnText={!this.state.wizardEmailReverification ? this.t('app.pages.register.modals.verifyEmail.verifyText') : this.t('app.pages.register.modals.verifyEmail.reVerifyText')}
																	popupNextBtnText={this.t('app.pages.register.modals.verifyEmail.continueText')}
																	popupOnSubmitHandler={(mode) => {
																		if (mode === 'VERIFY') {
																			this.handleVerifyEmailAddress()
																		}
																		else if (mode === 'CONTINUE') {
																			this.handleConfirmEmailAddressVerification()
																			this.handleRegistrationNext()
																		}
																	}}
																	popupOnNextHandler={() => {
																		this.handleRegistrationNext()
																	}}
																/>
															)
														) : (

															(this.state.licenseConfirmed && this.state.rodoConfirmed && (localStorage.getItem('registerSelectedLicense') !== undefined)) ? (
																<PopupRegister
																	popupSize="lg"
																	popupHeaderTitle={this.t('app.pages.registerSummary.title')}
																	popupOpenBtnTitle={this.t('app.pages.register.buttons.register')}
																	popupRegisterData={{
																		customer: this.state,
																		license: (localStorage.getItem('registerSelectedLicense') || this.state.licenseDefault)
																	}}
																	popupHeaderIconClassName="far fa-list-alt"
																	popupSubmitBtnText={this.t('app.pages.registerSummary.submitText')}
																	popupCancelBtnText={this.t('app.pages.registerSummary.cancelText')}
																	popupOnSubmitHandler={() => {
																		this.handleRegistrationNext()
																		this.handleCompleteRegistration()
																	}} 
																	popupOnNextHandler={() => {
																		this.handleReadAppProps()
																		this.handleRegistrationNext()
																	}}
																/>
															) : (
																<Button className="btn btn-warning register-button disabled" disabled>{this.t('app.pages.register.buttons.register')}</Button>
															)
														)}
													</Col>
												</Row>
											</FormGroup>
										</>
									)}
								</Form>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default withTranslation()(Register);