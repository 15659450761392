// Author: Teldasoft(c) 2021
// Module: api.js
// Description: API calls to the underlying Service

import { default as apiUserActions } from './actions'
import { getApi } from '../../../utilities/api'

const selectUsers = async (customerCode, applicationCode) => {
    const response = await fetch(getApi('/api/v1/customer/users'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            applicationCode: applicationCode
          })
        })

    const data = await response.json()
    return data
}

const selectUserGroups = async () => {
    const response = await fetch(getApi('/api/v1/user/groups'),
        { method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}

const selectUserRoles = async () => {
    const response = await fetch(getApi('/api/v1/user/roles'),
        { method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}

const insertUser = async (customerCode, applicationCode, userGroup, userRole, userLogin,
                            userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName,
                                userWorkPhone1, userWorkPhone2, userGender, userDescription) => {
    const response = await fetch(getApi('/api/v1/customer/addUser'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            applicationCode: applicationCode,
            userGroup: userGroup,
            userRole: userRole,
            userLogin: userLogin,
            userPassword: userPassword,
            userFirstName: userFirstName,
            userLastName: userLastName,
            userEmail: userEmail,
            userPhone: userPhone,
            userDisplayName: userDisplayName,
            userWorkPhone1: userWorkPhone1,
            userWorkPhone2: userWorkPhone2,
            userGender: userGender,
            userDescription: userDescription
          })
        })

    const data = await response.json()
    return data
}

const editUser = async (customerUserId, customerCode, applicationCode, userLogin, userGroup, userRole,
                            userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName,
                                userWorkPhone1, userWorkPhone2, userGender, userDescription, userActiveFrom, userActiveTo) => {
   const response = await fetch(getApi('/api/v1/customer/editUser'),
       { method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({
           customerUserId: customerUserId,
           customerCode: customerCode,
           applicationCode: applicationCode,
           userLogin: userLogin,
           userGroup: userGroup,
           userRole: userRole,
           userPassword: userPassword,
           userFirstName: userFirstName,
           userLastName: userLastName,
           userEmail: userEmail,
           userPhone: userPhone,
           userDisplayName: userDisplayName,
           userWorkPhone1: userWorkPhone1,
           userWorkPhone2: userWorkPhone2,
           userGender: userGender,
           userDescription: userDescription,
           userActiveFrom: userActiveFrom,
           userActiveTo: userActiveTo
        })
       })

   const data = await response.json()
   return data
}

const activateUser = async (customerCode, applicationCode, userId) => {
    const response = await fetch(getApi('/api/v1/customer/activateUser'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            applicationCode: applicationCode,
            customerUserId: userId
          })
        })

    const data = await response.json()
    return data
}

const lockUser = async (customerCode, applicationCode, userId) => {
    const response = await fetch(getApi('/api/v1/customer/lockUser'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            applicationCode: applicationCode,
            customerUserId: userId
          })
        })

    const data = await response.json()
    return data
}

const unlockUser = async (customerCode, applicationCode, userId) => {
    const response = await fetch(getApi('/api/v1/customer/unlockUser'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            applicationCode: applicationCode,
            customerUserId: userId
          })
        })

    const data = await response.json()
    return data
}

const removeUser = async (customerCode, applicationCode, userId) => {
    const response = await fetch(getApi('/api/v1/customer/removeUser'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            applicationCode: applicationCode,
            customerUserId: userId
          })
        })

    const data = await response.json()
    return data
}

// thunk
export const apiSelectUsers = (customerCode, applicationCode) => async (dispatch) => {
    const response = await selectUsers(customerCode, applicationCode)
    if (response) {
        dispatch(apiUserActions.selectUsers(response))
    } else {
        dispatch(apiUserActions.error('Customer user accounts loading failed'))
    }
}

// thunk
export const apiSelectUser = (userGroupName, statusName, userRole, login, firstName, lastName, email, phoneNo, gender) => async (dispatch) => {
    dispatch(apiUserActions.selectUser(userGroupName, statusName, userRole, login, firstName, lastName, email, phoneNo, gender))
}

// thunk
export const apiSelectUserGroups = () => async (dispatch) => {
    await selectUserGroups()
        .then((response) => {
            if (response) {
                dispatch(apiUserActions.selectUserGroups(response))
            } else {
                dispatch(apiUserActions.error('Fetch failed'))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiUserActions.error('Method failed'))
                }
            }
        });
}

// thunk
export const apiSelectUserRoles = () => async (dispatch) => {
    await selectUserRoles()
        .then((response) => {
            if (response) {
                dispatch(apiUserActions.selectUserRoles(response))
            } else {
                dispatch(apiUserActions.error('Fetch failed'))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiUserActions.error('Method failed'))
                }
            }
        });
}

// thunk
export const apiInsertUser = (customerCode, applicationCode, userGroup, userRole, userLogin,
                                userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName,
                                    userWorkPhone1, userWorkPhone2, userGender, userDescription) => async (dispatch) => {
    const response = await insertUser(customerCode, applicationCode, userGroup, userRole, userLogin,
                                        userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName,
                                            userWorkPhone1, userWorkPhone2, userGender, userDescription)
    if (response) {
        dispatch(apiUserActions.insertUser(response))
    } else {
        dispatch(apiUserActions.error('Customer user creation failed'))
    }
}

// thunk
export const apiEditUser = (customerUserId, customerCode, applicationCode, userLogin, userGroup, userRole,
                                userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName,
                                    userWorkPhone1, userWorkPhone2, userGender, userDescription, userActiveFrom, userActiveTo) => async (dispatch) => {
    const response = await editUser(customerUserId, customerCode, applicationCode, userLogin, userGroup, userRole,
                                        userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName,
                                            userWorkPhone1, userWorkPhone2, userGender, userDescription, userActiveFrom, userActiveTo)
    if (response) {
        dispatch(apiUserActions.editUser(response))
    } else {
        dispatch(apiUserActions.error('Customer user modification failed'))
    }
}

// thunk
export const apiActivateUser = (customerCode, applicationCode, userId) => async (dispatch) => {
    const response = await activateUser(customerCode, applicationCode, userId)
    if (response) {
        dispatch(apiUserActions.activateUser(response))
    } else {
        dispatch(apiUserActions.error('Customer user activation failed'))
    }
}

// thunk
export const apiLockUser = (customerCode, applicationCode, userId) => async (dispatch) => {
    const response = await lockUser(customerCode, applicationCode, userId)
    if (response) {
        dispatch(apiUserActions.lockUser(response))
    } else {
        dispatch(apiUserActions.error('Customer user locking failed'))
    }
}

// thunk
export const apiUnlockUser = (customerCode, applicationCode, userId) => async (dispatch) => {
    const response = await unlockUser(customerCode, applicationCode, userId)
    if (response) {
        dispatch(apiUserActions.unlockUser(response))
    } else {
        dispatch(apiUserActions.error('Customer user unlocking failed'))
    }
}

// thunk
export const apiRemoveUser = (customerCode, applicationCode, userId) => async (dispatch) => {
    const response = await removeUser(customerCode, applicationCode, userId)
    if (response) {
        dispatch(apiUserActions.removeUser(response))
    } else {
        dispatch(apiUserActions.error('Customer user removal failed'))
    }
}

// thunk
export const apiClearResponse = () => async (dispatch) => {
    dispatch(apiUserActions.clearResponse(null))
}