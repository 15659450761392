// Author: Teldasoft(c) 2021
// Module: api.js
// Description: Common field validators

export const onInputValidateDeviceNo = (value, customerCode = null) => {
    if (value === "") {
        return ""
    }

    value = ("" + value).toUpperCase();
    if (value === "") {
        return "Specify device E-mail"
    } else {
        return (customerCode || "unknown") + "-PG" + value + "@photoguard.pl"
    }
}

export const onKeyDownValidateIMEI = (e) => {
    const onValidateKeyCode = (e) => {
        const keyCode = e.key || e.which;

        if (!/[0-9|a-z|A-Z]/.test(keyCode) &&
            (keyCode !== 'ArrowLeft' && keyCode !== 'ArrowRight' &&
             keyCode !== 'Delete' && keyCode !== 'Backspace' &&
             keyCode !== 'Home' && keyCode !== 'End')) {
            e.preventDefault()
        }
    }
    return onValidateKeyCode(e)
}

// validate URL
export const onValidateURL = (value) => {
    if (typeof value === "undefined") {
        return false
    }

    const pattern = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)
    return pattern.test(value)
}

// validate E-mail address
export const onValidateEmail = (value) => {
    if (typeof value === "undefined") {
        return false
    }

    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(value)
}

// validate Phone Number
export const onValidatePhoneNumber = (value, mobileOnly = false) => {
    if (typeof value === "undefined") {
        return false
    }

    const patternMobile = new RegExp(/^([0-9]{9})$/i)
    const patternMobileWithPrefix = new RegExp(/^((\+)|([0]{2}))(?:[0-9] ?){2}[0-9]{9}$/i)
    const patterFixedLine = new RegExp(/^(?:[1-9] ?){2}[0-9]{7}$/i)
    const patterFixedLineWithPrefix = new RegExp(/^((\+)|([0]{2}))(?:[0-9] ?){2}(?:[1-9] ?){2}[0-9]{7}$/i)

    if (mobileOnly === true) {
        return (patternMobileWithPrefix.test(value) || patternMobile.test(value))
    } else {
        return (patterFixedLineWithPrefix.test(value) ||
                patternMobileWithPrefix.test(value) ||
                patterFixedLine.test(value) ||
                patternMobile.test(value))
    }
}

// validate PESEL
export const onValidatePESEL = (value) => {
    if (typeof value === "undefined") {
        return false
    }

    const pattern = new RegExp(/^[0-9]{11}$/i);
    if (!pattern.test(value)) {
        return false
    }
    else {
        const digits = ("" + value).split("")
        if ((parseInt(value.substring(4, 6)) > 31) || (parseInt(value.substring(2, 4)) > 12)) {
            return false;
        }
         
        let checksum = (1 * parseInt(digits[0]) + 3 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 9 * parseInt(digits[3]) +
                        1 * parseInt(digits[4]) + 3 * parseInt(digits[5]) + 7 * parseInt(digits[6]) + 9 * parseInt(digits[7]) +
                        1 * parseInt(digits[8]) + 3 * parseInt(digits[9])) % 10
        if (checksum === 0) checksum = 10
            checksum = 10 - checksum
 
        return (parseInt(digits[10]) === checksum)
    }
}

// validate NIP
export const onValidateNIP = (value) => {
    if (typeof value === "undefined") {
        return false
    }

    const nipWithoutDashes = value.replace(/-/g, "")
    const pattern = new RegExp(/^[0-9]{10}$/i)

    if (pattern.test(nipWithoutDashes) === false) {
        return false
    }
    else {
        const digits = ("" + nipWithoutDashes).split("")
        const checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) +
                          3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11
         
        return (parseInt(digits[9]) === checksum)
    }
}

// validate REGON
export const onValidateREGON = (value) => {
    if (typeof value === "undefined") {
        return false
    }

    const pattern = new RegExp(/^[0-9]{9}$/i)
    if (!pattern.test(value)) {
        return false;
    }
    else {
        const digits = ("" + value).split("")
        let checksum = (8 * parseInt(digits[0]) + 9 * parseInt(digits[1]) + 2 * parseInt(digits[2]) + 3 * parseInt(digits[3]) +
                        4 * parseInt(digits[4]) + 5 * parseInt(digits[5]) + 6 * parseInt(digits[6]) + 7 * parseInt(digits[7])) % 11
        if (checksum === 10)
            checksum = 0

        return (parseInt(digits[8]) === checksum)
    }
}

// validate Postal Code
export const onValidatePostalCode = (value) => {
    if (typeof value === "undefined") {
        return false
    }

    const pattern = new RegExp(/[0-9]{2}-[0-9]{3}$/i)
    return pattern.test(value)
}


// validate Password Strength
export const onValidatePasswordStrength = (value) => {
    if (value.length < 6) {
        return 0;
    }

    var matchedCase = new Array();
    matchedCase.push("[!@#$]"); // Special Charector
    matchedCase.push("[A-Z]");  // Uppercase Alpabates
    matchedCase.push("[0-9]");  // Numbers
    matchedCase.push("[a-z]");  // Lowercase Alphabates

    var ctr = 0;
    for (var i = 0; i < matchedCase.length; i++) {
        if (new RegExp(matchedCase[i]).test(value)) {
            ctr++;
        }
    }

    var strength = "";
    switch (ctr) {
        case 0:
        case 1:
        case 2:
            strength = "Very Weak";
            break;
        case 3:
            strength = "Medium";
            break;
        case 4:
            strength = "Strong";
            break;
    }
    return ctr;
}

// validate date in format YYYY-MM-DD
export const onValidateDateYYYYMMDD = (value) => {
    if (typeof value === "undefined") {
        return false
    }

    const pattern = new RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)
    return pattern.test(value)
}
