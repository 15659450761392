// Author: Teldasoft(c) 2021
// Module: api.js
// Description: API wrapper call to Backend service

export const getWebUrl = () => {
    return process.env.REACT_APP_WEB_URL
}

export const getApi = (path) => {
    let apiUrlPath = ''
    if (path !== '') {
        apiUrlPath = path
    }
    return process.env.REACT_APP_BACKEND_SERVER_API_URL + apiUrlPath
}

export const objToQueryString = (obj) => {
    const keyValuePairs = []
    if (Object.keys(obj).length > 0) {
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
        }
        return keyValuePairs.join('&')
    }
    return ""
}