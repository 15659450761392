import React from 'react'

import { Container, Row, Col, Form, FormGroup, Label, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation, withTranslation } from 'react-i18next'

import { apiSASelectCustomerList, apiSASelectCustomer, apiSARejectCustomer, apiSAVerifyCustomer,
         apiSAApproveCustomer, apiSASuspendCustomer, apiSAReactivateCustomer, apiSAResetAccountCustomer, apiClearResponse } from "../../../../middleware/customer/duck/api"

import ApproveCustomer from './modals/approveCustomer'
import PopupAlert from '../../../ui/popupAlert'
import PopupConfirm from '../../../ui/popupConfirm'
import { convertToFloat } from "../../../../utilities/common"

import '../../mainBoard.css'
import './customersListView.css'


function DropDownItems(props) {
    const { t } = useTranslation();

    const isSelected = () => {
        return (props.clickedCustomerId != null)
    }

    const isVerified = () => {
        return (props.customerStatus === 'CV') /* Verified */
    }

    const isRejected = () => {
        return (props.customerStatus === 'CR') /* Rejected */
    }

    const isApproved = () => {
        return (props.customerStatus === 'CA') /* Approved */
    }

    const isSuspended = () => {
        return (props.customerStatus === 'CS') /* Suspended */
    }

    return (
        <>
            <DropdownItem header>{t('app.pages.sa.customersListView.buttons.dropdown.allActions.header')}</DropdownItem>
            {isSelected() && isApproved() && (
                <>
                    <DropdownItem className="font-orange">
                        <PopupConfirm
                            popupSize="lg"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.sa.customersListView.modals.suspendCustomer.title') + " [" + props.clickedCustomerName + "]"}
                            popupOpenBtnTitle={t('app.pages.sa.customersListView.buttons.dropdown.allActions.menuitem1')}
                            popupBodyText={t('app.pages.sa.customersListView.modals.suspendCustomer.bodyText')}
                            popupHeaderIconClassName="fas fa-power-off"
                            popupSubmitBtnText={t('app.pages.sa.customersListView.modals.suspendCustomer.submitText')}
                            popupCancelBtnText={t('app.pages.sa.customersListView.modals.suspendCustomer.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onSuspendCustomerHandler(props.clickedCustomerId)
                            }} />
                    </DropdownItem>
                </>
            )}
            {isSelected() && !isVerified() && !isApproved() && !isSuspended() && (
                <>
                    <DropdownItem className="font-red">
                        <PopupConfirm
                            popupSize="lg"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.sa.customersListView.modals.rejectRegistration.title') + " [" + props.clickedCustomerName + "]"}
                            popupOpenBtnTitle={t('app.pages.sa.customersListView.buttons.dropdown.allActions.menuitem2')}
                            popupBodyText={t('app.pages.sa.customersListView.modals.rejectRegistration.bodyText')}
                            popupHeaderIconClassName="fas fa-ban"
                            popupSubmitBtnText={t('app.pages.sa.customersListView.modals.rejectRegistration.submitText')}
                            popupCancelBtnText={t('app.pages.sa.customersListView.modals.rejectRegistration.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onRejectCustomerHandler(props.clickedCustomerId)
                            }} />
                    </DropdownItem>
                    <DropdownItem className="font-green">
                        <PopupConfirm
                            popupSize="lg"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.sa.customersListView.modals.verifyRegistration.title') + " [" + props.clickedCustomerName + "]"}
                            popupOpenBtnTitle={t('app.pages.sa.customersListView.buttons.dropdown.allActions.menuitem3')}
                            popupBodyText={t('app.pages.sa.customersListView.modals.verifyRegistration.bodyText')}
                            popupHeaderIconClassName="fas fa-user-check"
                            popupSubmitBtnText={t('app.pages.sa.customersListView.modals.verifyRegistration.submitText')}
                            popupCancelBtnText={t('app.pages.sa.customersListView.modals.verifyRegistration.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onVerifyCustomerHandler(props.clickedCustomerId)
                            }} />
                    </DropdownItem>
                </>
            )}
            {isSelected() && isVerified() && (
                <>
                    <DropdownItem divider />
                    <DropdownItem className="font-green">
                        <ApproveCustomer
                            popupSize="lg"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.sa.customersListView.modals.approveCustomer.title') + " [" + props.clickedCustomerName + "]"}
                            popupOpenBtnTitle={t('app.pages.sa.customersListView.buttons.dropdown.allActions.menuitem4')}
                            popupBodyText={t('app.pages.sa.customersListView.modals.approveCustomer.bodyText')}
                            popupHeaderIconClassName="fas fa-user-shield"
                            popupSubmitBtnText={t('app.pages.sa.customersListView.modals.approveCustomer.submitText')}
                            popupCancelBtnText={t('app.pages.sa.customersListView.modals.approveCustomer.cancelText')}
                            popupLicenseData={
                                {
                                    requestedLicenseVariant: props.clickedCustomerLicenseVariant,
                                    requestedLicenseCostMth: props.clickedCustomerLicenseCostMth,
                                    requestedLicenseDiscountPcnt: convertToFloat(props.clickedCustomerLicenseDiscountPcnt)
                                }
                            }
                            popupOnSubmitHandler={(data) => {
                                props.onApproveCustomerHandler(props.clickedCustomerId, data)
                            }} />
                    </DropdownItem>
                </>
            )}
            {isSelected() && isSuspended() && (
                <>
                    <DropdownItem className="font-green">
                        <PopupConfirm
                            popupSize="lg"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.sa.customersListView.modals.reactivateCustomer.title') + " [" + props.clickedCustomerName + "]"}
                            popupOpenBtnTitle={t('app.pages.sa.customersListView.buttons.dropdown.allActions.menuitem5')}
                            popupBodyText={t('app.pages.sa.customersListView.modals.reactivateCustomer.bodyText')}
                            popupHeaderIconClassName="fas fa-power-off"
                            popupSubmitBtnText={t('app.pages.sa.customersListView.modals.reactivateCustomer.submitText')}
                            popupCancelBtnText={t('app.pages.sa.customersListView.modals.reactivateCustomer.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onReactivateCustomerHandler(props.clickedCustomerId)
                            }} />
                    </DropdownItem>
                </>
            )}
            {isSelected() && isApproved() && !isSuspended() && (
                <>
                    <DropdownItem divider />
                    <DropdownItem className="font-red">
                        <PopupConfirm
                            popupSize="lg"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.sa.customersListView.modals.resetCustomer.title') + " [" + props.customerPrimaryAccount + "]"}
                            popupOpenBtnTitle={t('app.pages.sa.customersListView.buttons.dropdown.allActions.menuitem6')}
                            popupBodyText={t('app.pages.sa.customersListView.modals.resetCustomer.bodyText')}
                            popupHeaderIconClassName="fas fa-retweet"
                            popupSubmitBtnText={t('app.pages.sa.customersListView.modals.resetCustomer.submitText')}
                            popupCancelBtnText={t('app.pages.sa.customersListView.modals.resetCustomer.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onResetAccountCustomerHandler(props.clickedCustomerId)
                            }} />
                    </DropdownItem>
                </>
            )}
        </>
    )
}

class SuperAdminCustomerListView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isScrollable: false,
            clickedCustomerId: null,
            clickedCustomerName: null,
            clickedCustomerPrimaryAccount: null,
            clickedCustomerLicenseVariant: null,
            clickedCustomerLicenseCostMth: null,
            clickedCustomerLicenseDiscountPcnt: null,
            dropdownOpen: false
        }
    }

    t = (key) => {
        return this.props.t(key)
    }

    componentDidMount() {
        this.handleOnTableRefresh()
    }

    isDBResponseReceived = () => {
        return (this.props.DBResponse.message !== undefined)
    }

    setDropdownToggle = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    }

    handleOnSelectCustomers = (applicationCode) => {
        this.props.apiSASelectCustomerList(applicationCode)
    }

    handleOnTableRefresh = () => {
        console.log('Refreshing the list of customers...')
        this.handleOnSelectCustomers("PG")
    }

    handleOnRowClicked = (id) => {
        if (id == null) {
            this.setState({clickedCustomerId: null})
            this.props.apiSASelectCustomer(null, null, null, null, null, null)
        } else {
            const row = document.getElementById("row-id-" + id)
            if (row !== null) {
                if (row.childNodes) {
                    this.props.apiSASelectCustomer(
                        row.childNodes[1].innerText,
                        row.childNodes[2].innerText,
                        row.childNodes[3].innerText,
                        row.childNodes[4].innerText,
                        row.childNodes[5].innerText,
                        row.childNodes[6].innerText
                    )

                    this.setState({
                        clickedCustomerId: id,
                        clickedCustomerName: row.childNodes[2].innerText + " (" + row.childNodes[3].innerText + ")",
                        clickedCustomerStatus: row.childNodes[8].innerText,
                        clickedCustomerPrimaryAccount: row.childNodes[9].innerText,
                        clickedCustomerLicenseVariant: row.childNodes[10].innerText,
                        clickedCustomerLicenseCostMth: row.childNodes[11].innerText,
                        clickedCustomerLicenseDiscountPcnt: row.childNodes[12].innerText
                    })
                }
            }
        }
    }

    handleOnSuspendCustomer = (id) => {
        console.log("Suspending customer with Id = " + id)
        this.props.apiSASuspendCustomer("PG", id)
    }

    handleOnRejectCustomer = (id) => {
        console.log("Rejecting customer with Id = " + id)
        this.props.apiSARejectCustomer("PG", id)
    }

    handleOnVerifyCustomer = (id) => {
        console.log("Verifying customer with Id = " + id)
        this.props.apiSAVerifyCustomer("PG", id)
    }

    handleOnApproveCustomer = (id, data) => {
        console.log("Approving customer with Id = " + id)
        this.props.apiSAApproveCustomer("PG", id, data)
    }

    handleOnReactivateCustomer = (id) => {
        console.log("Reactivating customer with Id = " + id)
        this.props.apiSAReactivateCustomer("PG", id)
    }

    handleOnResetAccountCustomer = (id) => {
        console.log("Resetting customer main account with Id = " + id)
        this.props.apiSAResetAccountCustomer("PG", id)
    }

    render() {
        return(
            <>
                {(this.isDBResponseReceived()) ? (
                    <PopupAlert
                        key={this.props.DBResponse.message}
                        popupOpenToggle={true}
                        popupSize="md"
                        popupOpenWith="None"
                        popupOpenWithClass=""
                        popupHeaderTitle={ this.props.DBResponse.isError ? this.t('app.common.modals.popupAlert.title2') : this.t('app.common.modals.popupAlert.title') }
                        popupOpenBtnTitle=""
                        popupBodyText={this.props.DBResponse.message}
                        popupHeaderIconClassName={ this.props.DBResponse.isError ? "fas fa-exclamation-triangle font-red" : "fas fa-check-circle font-green" }
                        popupSubmitBtnText={this.t('app.common.modals.popupAlert.cancelText')}
                        popupEscapeListener={false}
                        popupOnSubmitHandler={() => {
                            this.handleOnTableRefresh()
                            this.props.apiClearResponse()
                        }}
                    />
                ): (
                    <></>
                )}

                <Form className="customer-view-form">
                    <Container fluid="md" className="customer-list-view">
                        <Row>
                            <Label className="form-view-title">{this.t('app.pages.sa.customersListView.title')}</Label>
                        </Row>
                        <Row style={{marginTop: '10px'}}>
                            <Label>{this.t('app.pages.sa.customersListView.headlines.selectCustomerTitle')}</Label>
                        </Row>
                        <Row>
                            <Col style={{paddingLeft: '0px'}}>
                                <FormGroup>
                                    <Dropdown className={this.state.dropdownOpen ? "transparency-dropdown-after" : "transparency-dropdown"} isOpen={this.state.dropdownOpen} toggle={this.setDropdownToggle}>
                                        <DropdownToggle caret>
                                            {this.t('app.pages.sa.customersListView.buttons.dropdown.allActions.menuitemdef')}
                                        </DropdownToggle>
                                        <DropdownMenu persist>
                                            <DropDownItems
                                                customerStatus={this.state.clickedCustomerStatus}
                                                customerPrimaryAccount={this.state.clickedCustomerPrimaryAccount}
                                                clickedCustomerId={this.state.clickedCustomerId}
                                                clickedCustomerName={this.state.clickedCustomerName}
                                                clickedCustomerLicenseVariant={this.state.clickedCustomerLicenseVariant}
                                                clickedCustomerLicenseCostMth={this.state.clickedCustomerLicenseCostMth}
                                                clickedCustomerLicenseDiscountPcnt={this.state.clickedCustomerLicenseDiscountPcnt}
                                                onSuspendCustomerHandler={(id) => {
                                                    this.handleOnSuspendCustomer(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onRejectCustomerHandler={(id) => {
                                                    this.handleOnRejectCustomer(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onVerifyCustomerHandler={(id) => {
                                                    this.handleOnVerifyCustomer(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onApproveCustomerHandler={(id, data) => {
                                                    this.handleOnApproveCustomer(id, data);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onReactivateCustomerHandler={(id) => {
                                                    this.handleOnReactivateCustomer(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onResetAccountCustomerHandler={(id) => {
                                                    this.handleOnResetAccountCustomer(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                            />
                                        </DropdownMenu>
                                    </Dropdown>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>

                    <div className="container-fluid customer-list-table">
                        <div className="row customer-list-table-header">
                            <div className="col-xs-1" style={{width: '2%', textAlign: 'right'}}>#</div>
                            <div className="col-xs-6 col-md-3" style={{marginLeft: '-2px', minWidth: '17vh', maxWidth: '33vh'}}>{this.t('app.pages.sa.customersListView.grids.customerList.cols.status')}</div>
                            <div className="col-xs-5 col-md-2" style={{minWidth: '34vh', maxWidth: '34vh'}}>{this.t('app.pages.sa.customersListView.grids.customerList.cols.customerName')}</div>
                            <div className="col-xs-4 col-md-2" style={{minWidth: '15vh', maxWidth: '15vh'}}>{this.t('app.pages.sa.customersListView.grids.customerList.cols.externalCode')}</div>
                            <div className="col-xs-4 col-md-1" style={{minWidth: '23vh', maxWidth: '23vh'}}>{this.t('app.pages.sa.customersListView.grids.customerList.cols.registered')}</div>
                            <div className="col-xs-5 col-md-2">{this.t('app.pages.sa.customersListView.grids.customerList.cols.email')}</div>
                            <div className="col-xs-3 col-md-1" style={{minWidth: '13vh', maxWidth: '13vh'}}>{this.t('app.pages.sa.customersListView.grids.customerList.cols.phone')}</div>
                            <div className="col-xs-1 col-md-1">{this.t('app.pages.sa.customersListView.grids.customerList.cols.license')}</div>
                            <div className="col-xs-1 col-md-1" style={{display: 'none'}}>Status Symbol</div>
                            <div className="col-xs-1 col-md-1" style={{display: 'none'}}>Primary account</div>
                            <div className="col-xs-1 col-md-1" style={{display: 'none'}}></div>
                            <div className="col-xs-1 col-md-1" style={{display: 'none'}}></div>
                            <div className="col-xs-1 col-md-1" style={{display: 'none'}}></div>
                            <div className="col text-right" style={{marginRight: (this.state.isScrollable ? '10px' : '0px')}}>
                                <i className='fas fa-sync-alt fa-2x fa-table-header-icon' title={this.t('app.pages.sa.customersListView.hints.iconRefresh')} onClick={() => this.handleOnTableRefresh()}></i>
                            </div>
                        </div>

                        <div className="customer-list-table-body-scrollable">
                            {this.props.customerList.map((item, index) => {
                                const colClickedClass = (this.state.clickedCustomerId === item.id) ? "customer-list-item-clicked" : ""

                                return (
                                    <div className={"row customer-list-table-body " + colClickedClass} key={index} id={"row-id-" + item.id} onClick={() => this.handleOnRowClicked(item.id)}>
                                        <div className="col-xs-1" style={{width: '2%', textAlign: 'right'}}>{index + 1}</div>
                                        <div className={"col-xs-6 col-md-3 status-" + item.statusSymbol} style={{minWidth: '17vh', maxWidth: '33vh'}}>{item.statusName}</div>
                                        <div className="col-xs-5 col-md-2" style={{minWidth: '34vh', maxWidth: '34vh'}}>{item.customerName}</div>
                                        <div className="col-xs-4 col-md-2" style={{minWidth: '15vh', maxWidth: '15vh'}}>{item.externalCode}</div>
                                        <div className="col-xs-4 col-md-1" style={{minWidth: '23vh', maxWidth: '23vh'}}>{item.registrationDate}</div>
                                        <div className="col-xs-5 col-md-2">{item.email}</div>
                                        <div className="col-xs-3 col-md-1" style={{minWidth: '13vh', maxWidth: '13vh'}}>{item.primaryPhone}</div>
                                        <div className="col-xs-1 col-md-1">{item.requestedLicenseVariant}</div>
                                        <div className="col-xs-1 col-md-1" style={{display: 'none'}}>{item.statusSymbol}</div>
                                        <div className="col-xs-1 col-md-1" style={{display: 'none'}}>{item.primaryAccount}</div>
                                        <div className="col-xs-1 col-md-1" style={{display: 'none'}}>{item.requestedLicenseVariant}</div>
                                        <div className="col-xs-1 col-md-1" style={{display: 'none'}}>{item.requestedLicenseCostMth}</div>
                                        <div className="col-xs-1 col-md-1" style={{display: 'none'}}>{item.requestedLicenseDiscountPcnt}</div>
                                        <div className="col text-right"></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerList: state.customer.customerList || [],
    DBResponse: { isError: state.customer.dbStatus.isError, message: state.customer.dbStatus.message }
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSASelectCustomer: (statusName, customerName, externalCode, registrationDate, email, phone) =>
        dispatch(apiSASelectCustomer(statusName, customerName, externalCode, registrationDate, email, phone)),
    apiSASelectCustomerList: (applicationCode) =>
        dispatch(apiSASelectCustomerList(applicationCode)),
    apiSARejectCustomer: (applicationCode, registrationId) =>
        dispatch(apiSARejectCustomer(applicationCode, registrationId)),
    apiSAVerifyCustomer: (applicationCode, registrationId) =>
        dispatch(apiSAVerifyCustomer(applicationCode, registrationId)),
    apiSAApproveCustomer: (applicationCode, registrationId, registrationData) =>
        dispatch(apiSAApproveCustomer(applicationCode, registrationId, registrationData)),
    apiSASuspendCustomer: (applicationCode, registrationId) =>
        dispatch(apiSASuspendCustomer(applicationCode, registrationId)),
    apiSAReactivateCustomer: (applicationCode, registrationId) =>
        dispatch(apiSAReactivateCustomer(applicationCode, registrationId)),
    apiSAResetAccountCustomer: (applicationCode, registrationId) =>
        dispatch(apiSAResetAccountCustomer(applicationCode, registrationId)),
    apiClearResponse: () =>
        dispatch(apiClearResponse())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SuperAdminCustomerListView));