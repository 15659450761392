import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function CardItem(props) {
	const { t } = useTranslation();
	
	const handleSelectLicenseOffer = () => {
		sessionStorage.setItem('registerSelectedLicense', props.licenseType);
	}

	return (
		<>
			<li className='cards__item'>
				<Link className='cards__item__link' onClick={handleSelectLicenseOffer} to={props.path}>
					<figure className='cards__item__pic-wrap' data-category={props.label}>
						<img
							className='cards__item__img'
							alt=''
							src={props.src}
						/>
					</figure>
					<div className='cards__item__details__div'>
						<ul className='cards__item__details__ul'>
							<li>{t('app.pages.licenseCards.labels.offerMaxDev')}&nbsp;&nbsp;{props.maxDev}</li>
							<li>{t('app.pages.licenseCards.labels.offerMaxStorage')}&nbsp;&nbsp;{props.maxStorage + " " + props.maxStorageUnit}</li>
						</ul>
					</div>
					<div className='cards__item__details__bottom__div'>
						{props.licensePrice}
					</div>
					<div className='cards__item__details__period__div'>
						<p>{props.licensePricePeriod}</p>
					</div>
					<div className='cards__item__info'>
						<button className="btn btn--outline-fig btn--medium-fig" onClick={handleSelectLicenseOffer}>{props.text.toUpperCase()}</button>
					</div>
					<div className='cards__item__details__bottom2__div'>
						<p>{props.licenseTip}</p>
					</div>
				</Link>
			</li>
		</>
	);
}

export default CardItem;
