// Author: Teldasoft(c) 2021
// Module: api.js
// Description: API calls to the underlying Service

import { default as apiAppActions } from './actions'
import { getApi } from '../../../utilities/api'

const sendTicketRequest = async (customerEmail, subject, subjectDetails) => {
    const response = await fetch(getApi('/api/v1/app/ticketRequest'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerEmail: customerEmail,
            subject: subject,
            subjectDetails: subjectDetails
          })
        })

    const data = await response.json()
    return data
}

// thunk
export const apiSetSideBarVisibility = (status) => async (dispatch) => {
    dispatch(apiAppActions.setSideBarVisibility(status))
}

// thunk
export const apiSetSideBarVisibilityHiddenClass = (cssName) => async (dispatch) => {
    dispatch(apiAppActions.setSideBarVisibilityHiddenClass(cssName))
}

export const apiSendTicketRequest = (customerEmail, subject, subjectDetails) => async (dispatch) => {
    const response = await sendTicketRequest(customerEmail, subject, subjectDetails)
    if (response) {
        dispatch(apiAppActions.sendTicketRequest(response))
    } else {
        dispatch(apiAppActions.error('Ticket request processing failed'))
    }
}

// thunk
export const apiClearTicketRequest = () => async (dispatch) => {
    dispatch(apiAppActions.clearTicketRequest(null))
}