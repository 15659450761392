import React from 'react'
import { Form, FormGroup, Label } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'

import '../../mainBoard.css'
import './settingsView.css'

const SettingsView = (props) => {
    const { t } = useTranslation();

    return(
        <Form className="settings-view-form">
            <FormGroup>
                <Label className="form-view-title">{t('app.pages.settingsView.title')}</Label>
            </FormGroup>
        </Form>
    );
}

const mapStateToProps = ({ session: { foo } }) => ({
    //TODO
    foo: foo
});

const mapDispatchToProps = dispatch => ({
    //TODO
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);