import types from './types'

const initialState = {
    userList: [],
    userGroupList: [],
    userRoleList: [],
    userSelected: {},
    dbStatus: "",
    error: null
}

function UserReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_CUSTOMER_SELECT_USERS:
            return { ...state, userList: action.payload.userList };

        case types.API_CUSTOMER_SELECT_USER_ROW:
            const genderName = (action.payload.gender === "M"
                                ? "Male"
                                : (action.payload.gender === "F"
                                    ? "Female"
                                    : (action.payload.gender === "O"
                                        ? "Other"
                                        : "")))

            return { ...state,
                userSelected: {
                    userGroupName: action.payload.userGroupName,
                    statusName: action.payload.statusName,
                    userRole: action.payload.userRole,
                    login: action.payload.login,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    email: action.payload.email,
                    phoneNo: action.payload.phoneNo,
                    genderCode: action.payload.gender,
                    gender: genderName
                }
            };

        case types.API_CUSTOMER_SELECT_USER_GROUPS:
            return { ...state, error: null, userGroupList: action.payload.userGroupList };

        case types.API_CUSTOMER_SELECT_USER_ROLES:
            return { ...state, error: null, userRoleList: action.payload.userRoleList };
                
        case types.API_USER_ERROR:
            return { ...state, error: action.payload };

        case types.API_CUSTOMER_INSERT_USER:
            return { ...state, dbStatus: action.payload.insertStatus };
                
        case types.API_CUSTOMER_EDIT_USER:
            return { ...state, dbStatus: action.payload.modificationStatus };
    
        case types.API_CUSTOMER_ACTIVATE_USER:
            return { ...state, dbStatus: action.payload.activationStatus };

        case types.API_CUSTOMER_LOCK_USER:
            return { ...state, dbStatus: action.payload.lockingStatus };

        case types.API_CUSTOMER_UNLOCK_USER:
            return { ...state, dbStatus: action.payload.unlockingStatus };

        case types.API_CUSTOMER_REMOVE_USER:
            return { ...state, dbStatus: action.payload.removalStatus };
                
        case types.API_CUSTOMER_CLEAR_DB_RESPONSE:
            return { ...state, dbStatus: action.payload.response || "" };
    
        default:
            return state;
    }
};

export default UserReducer;