import React from 'react'

import Bootstrap from '../components/bootstrap'
import MainBoard from '../components/dashboard/mainBoard'

import '../app.css'

class Dashboard extends React.Component {
    render() {
        return (
            <>
                <Bootstrap />
                <MainBoard path={window.location.pathname}/>
            </>
        );
    }
}

export default Dashboard;