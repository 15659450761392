// Author: Teldasoft(c) 2021
// Module: api.js
// Description: API calls to the underlying Service

import { default as apiFolderActions } from './actions'
import { getApi, objToQueryString } from '../../../utilities/api'

// insertFolder
const insertFolder = async (customerCode, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderCreator) => {
    const response = await fetch(getApi('/api/v1/folder'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            folderType: folderType,
            folderName: folderName,
            folderDescription: folderDescription,
            folderDisplayName: folderDisplayName,
            isActive: isActive ? "true" : "false",
            isLocked: isLocked ? "true" : "false",
            folderCreator: folderCreator
          })
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}

// updateFolder
const updateFolder = async (customerCode, folderId, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderOwner) => {
    const response = await fetch(getApi('/api/v1/folder'),
        { method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            folderId: folderId,
            folderType: folderType,
            folderName: folderName,
            folderDescription: folderDescription,
            folderDisplayName: folderDisplayName,
            folderOwner: folderOwner,
            isActive: isActive ? "true" : "false",
            isLocked: isLocked ? "true" : "false"
          })
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}

// deleteFolder
const deleteFolder = async (folderId) => {
    const response = await fetch(getApi(`/api/v1/folder/${folderId}`),
        { method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })

    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }
    
    const data = await response.json()
    return data
}

// selectFolders
const selectFolders = async (customerCode, folderType, folderOwner) => {
    const queryData = {
        folderType: folderType || '',
        folderOwner: folderOwner
    }

    const response = await fetch(getApi(`/api/v1/folders/${customerCode}?${objToQueryString(queryData)}`),
        { method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}

// selectFolderTypes
const selectFolderTypes = async () => {
    const response = await fetch(getApi('/api/v1/folderTypes'),
        { method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}

// tagFolderImage
const tagFolderImage = async (customerCode, srcFolder, srcFolderType, dstFolder, dstFolderType, itemResourceId, itemUser) => {
    const response = await fetch(getApi('/api/v1/folder/tagImage'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            srcFolder: srcFolder,
            srcFolderType: srcFolderType,
            dstFolder: dstFolder,
            dstFolderType: dstFolderType,
            itemResourceId: itemResourceId,
            itemUser: itemUser
          })
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}

// tagFolderImages
const tagFolderImages = async (customerCode, srcFolder, srcFolderType, dstFolder, dstFolderType, itemResourceList, itemUser) => {
    const response = await fetch(getApi('/api/v1/folder/tagImages'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            srcFolder: srcFolder,
            srcFolderType: srcFolderType,
            dstFolder: dstFolder,
            dstFolderType: dstFolderType,
            itemResourceList: itemResourceList,
            itemUser: itemUser
          })
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}

// removeFolderImages
const removeFolderImages = async (customerCode, itemFolder, itemFolderType, itemResourceList) => {
    const response = await fetch(getApi('/api/v1/folder/removeImage'),
        { method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            itemFolder: itemFolder,
            itemFolderType: itemFolderType,
            itemResourceList: itemResourceList
          })
        })
    
    if (!response.ok) {
        return Promise.reject({"code": response.status, "message": response.statusText})
    }

    const data = await response.json()
    return data
}


// thunk
export const apiInsertFolder = (customerCode, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderCreator) => async (dispatch) => {
    await insertFolder(customerCode, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderCreator)
        .then((response) => {
            if (response && (response.statusCode === 200)) {
                dispatch(apiFolderActions.insertFolder(response))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiFolderActions.error('Method failed'))
                }
            }
        });
}

// thunk
export const apiUpdateFolder = (customerCode, folderId, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderOwner) => async (dispatch) => {
    await updateFolder(customerCode, folderId, folderType, folderName, folderDescription, folderDisplayName, isActive, isLocked, folderOwner)
        .then((response) => {
            if (response && (response.statusCode === 200)) {
                dispatch(apiFolderActions.updateFolder(response))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiFolderActions.error('Method failed'))
                }
            }
        });
}

// thunk
export const apiDeleteFolder = (folderId) => async (dispatch) => {
    await deleteFolder(folderId)
        .then((response) => {
            if (response && (response.statusCode === 200)) {
                dispatch(apiFolderActions.deleteFolder(response))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiFolderActions.error('Method failed'))
                }
            }
        });
}

// thunk
export const apiRefreshFolders = () => async(dispatch) => {
    dispatch(apiFolderActions.refreshFolders())
}

// thunk
export const apiSelectFolders = (customerCode, folderType, folderOwner) => async (dispatch) => {
    await selectFolders(customerCode, folderType, folderOwner)
        .then((response) => {
            if (response) {
                dispatch(apiFolderActions.selectFolders(response))
            } else {
                dispatch(apiFolderActions.error('Fetch failed'))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiFolderActions.error('Method failed'))
                }
            }
        });
}

// thunk
export const apiSelectFolder = (folderType, folderName, folderDisplayName, folderDescription, folderId) => async (dispatch) => {
    dispatch(apiFolderActions.selectFolder(folderType, folderName, folderDisplayName, folderDescription, folderId))
}

// thunk
export const apiSelectFolderTypes = () => async (dispatch) => {
    await selectFolderTypes()
        .then((response) => {
            if (response) {
                dispatch(apiFolderActions.selectFolderTypes(response))
            } else {
                dispatch(apiFolderActions.error('Fetch failed'))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiFolderActions.error('Method failed'))
                }
            }
        });
}

//thunk
export const apiTagFolderImage = (customerCode, srcFolder, dstFolder, dstFolderType, itemResourceId, itemUser) => async (dispatch) => {
    await tagFolderImage(customerCode, srcFolder, dstFolder, dstFolderType, itemResourceId, itemUser)
        .then((response) => {
            if (response && (response.status === 200)) {
                dispatch(apiFolderActions.tagFolderImage(customerCode, srcFolder, dstFolder, dstFolderType, itemResourceId, itemUser))
            } else {
                dispatch(apiFolderActions.error('Image tagging failed'))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiFolderActions.error('Method failed'))
                }
            }
        });
}

//thunk
export const apiTagFolderImages = (customerCode, srcFolder, dstFolder, dstFolderType, itemResourceList, itemUser) => async (dispatch) => {
    await tagFolderImages(customerCode, srcFolder, dstFolder, dstFolderType, itemResourceList, itemUser)
        .then((response) => {
            if (response && (response.status === 200)) {
                dispatch(apiFolderActions.tagFolderImages(customerCode, srcFolder, dstFolder, dstFolderType, itemResourceList, itemUser))
            } else {
                dispatch(apiFolderActions.error('Image tagging failed'))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiFolderActions.error('Method failed'))
                }
            }
        });
}

//thunk
export const apiRemoveFolderImages = (customerCode, itemFolder, itemFolderType, itemResourceList) => async (dispatch) => {
    await removeFolderImages(customerCode, itemFolder, itemFolderType, itemResourceList)
        .then((response) => {
            if (response && (response.status === 200)) {
                dispatch(apiFolderActions.removeFolderImages(customerCode, itemFolder, itemFolderType, itemResourceList))
            } else {
                dispatch(apiFolderActions.error('Image removal failed'))
            }
        })
        .catch((error) => {
            if (error) {
                if (error.code === 405) {
                    dispatch(apiFolderActions.error('Method failed'))
                }
            }
        });
}