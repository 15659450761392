import React from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation, withTranslation } from 'react-i18next'

import { apiSelectUsers, apiSelectUser, apiSelectUserGroups, apiSelectUserRoles,
         apiInsertUser, apiEditUser, apiActivateUser, apiLockUser, apiUnlockUser, apiRemoveUser, apiClearResponse } from "../../../../middleware/user/duck/api"
import { isDivContentScrollable } from '../../../../utilities/dom'

import ModalUserEdit from './modals/modalUserEdit'

import PopupAlert from '../../../ui/popupAlert'
import PopupConfirm from '../../../ui/popupConfirm'

import '../../mainBoard.css'
import './userListView.css'

function DropDownItems(props) {
    const { t } = useTranslation();

    const isSelected = () => {
        return (props.clickedUserId != null)
    }

    const isNew = () => {
        return (props.userState === 'New')
    }

    const isLocked = () => {
        return (props.userState === 'Locked')
    }

    const isActivated = () => {
        return (props.userState === 'Active')
    }

    const isPrimaryAccount = () => {
        return (props.userIsPrimaryAccount === 1)
    }

    return (
        <>
            <DropdownItem header>{t('app.pages.userListView.buttons.dropdown.allActions.header')}</DropdownItem>
            {(isSelected() && isActivated() && !isPrimaryAccount()) ? (
                <DropdownItem className="font-orange">
                    <PopupConfirm
                        popupSize="md"
                        popupOpenWith="Link"
                        popupOpenWithClass=""
                        popupHeaderTitle={t('app.pages.userListView.modals.lockUser.title') + " [" + props.clickedUserName + "]"}
                        popupOpenBtnTitle={t('app.pages.userListView.buttons.dropdown.allActions.menuitem1')}
                        popupBodyText={t('app.pages.userListView.modals.lockUser.bodyText')}
                        popupHeaderIconClassName="fas fa-lock"
                        popupSubmitBtnText={t('app.pages.userListView.modals.lockUser.submitText')}
                        popupCancelBtnText={t('app.pages.userListView.modals.lockUser.cancelText')}
                        popupOnSubmitHandler={() => {
                            props.onLockUserHandler(props.clickedUserId)
                        }} />
                </DropdownItem>
            ) : (
                <DropdownItem disabled>{t('app.pages.userListView.buttons.dropdown.allActions.menuitem1')}</DropdownItem>
            )}
            {isSelected() && !isActivated() && isLocked() && (
                <DropdownItem className="font-green">
                    <PopupConfirm
                        popupSize="md"
                        popupOpenWith="Link"
                        popupOpenWithClass=""
                        popupHeaderTitle={t('app.pages.userListView.modals.unlockUser.title') + " [" + props.clickedUserName + "]"}
                        popupOpenBtnTitle={t('app.pages.userListView.buttons.dropdown.allActions.menuitem2')}
                        popupBodyText={t('app.pages.userListView.modals.unlockUser.bodyText')}
                        popupHeaderIconClassName="fas fa-lock-open"
                        popupSubmitBtnText={t('app.pages.userListView.modals.unlockUser.submitText')}
                        popupCancelBtnText={t('app.pages.userListView.modals.unlockUser.cancelText')}
                        popupOnSubmitHandler={() => {
                            props.onUnlockUserHandler(props.clickedUserId)
                        }} />
                </DropdownItem>
            )}
            {isSelected() && !isActivated() && isNew() && (
                <DropdownItem className="font-green">
                    <PopupConfirm
                        popupSize="md"
                        popupOpenWith="Link"
                        popupOpenWithClass=""
                        popupHeaderTitle={t('app.pages.userListView.modals.activateUser.title') + " [" + props.clickedUserName + "]"}
                        popupOpenBtnTitle={t('app.pages.userListView.buttons.dropdown.allActions.menuitem4')}
                        popupBodyText={t('app.pages.userListView.modals.activateUser.bodyText')}
                        popupHeaderIconClassName="fas fa-plug"
                        popupSubmitBtnText={t('app.pages.userListView.modals.activateUser.submitText')}
                        popupCancelBtnText={t('app.pages.userListView.modals.activateUser.cancelText')}
                        popupOnSubmitHandler={() => {
                            props.onActivateUserHandler(props.clickedUserId)
                        }} />
                </DropdownItem>
            )}
            {(isSelected() && !isPrimaryAccount()) ? (
                <DropdownItem className="font-red">
                    <PopupConfirm
                        popupSize="md"
                        popupOpenWith="Link"
                        popupOpenWithClass=""
                        popupHeaderTitle={t('app.pages.userListView.modals.removeUser.title') + " [" + props.clickedUserName + "]"}
                        popupOpenBtnTitle={t('app.pages.userListView.buttons.dropdown.allActions.menuitem3')}
                        popupBodyText={t('app.pages.userListView.modals.removeUser.bodyText')}
                        popupHeaderIconClassName="fas fa-trash-alt"
                        popupSubmitBtnText={t('app.pages.userListView.modals.removeUser.submitText')}
                        popupCancelBtnText={t('app.pages.userListView.modals.removeUser.cancelText')}
                        popupOnSubmitHandler={() => {
                            props.onRemoveUserHandler(props.clickedUserId)
                        }} />
                </DropdownItem>
            ) : (
                <DropdownItem disabled>{t('app.pages.userListView.buttons.dropdown.allActions.menuitem3')}</DropdownItem>
            )}
            <DropdownItem divider />
            <DropdownItem disabled>{t('app.pages.userListView.buttons.dropdown.allActions.menuitem5')}</DropdownItem>
            <DropdownItem disabled>{t('app.pages.userListView.buttons.dropdown.allActions.menuitem6')}</DropdownItem>
        </>
    )
}

class UserListView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            clickedUserId: null,
            clickedUserName: null,
            clickedUserIsPrimaryAccount: 0,
            isScrollable: false,
            userSelected: {}
        }
    }

    t = (key) => {
        return this.props.t(key)
    }

    setDropdownToggle = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    }

    componentDidMount() {
        this.props.apiSelectUserGroups()
        this.props.apiSelectUserRoles()

        this.handleOnTableRefresh()
        this.setState({isScrollable: isDivContentScrollable('user-list-table-body-scrollable')});
    }

    isDBResponseReceived = () => {
        return (this.props.DBResponse.message !== undefined)
    }

    handleOnSelectUsers = () => {
        this.props.apiSelectUsers(this.props.customerCode, "")
    }

    handleOnTableRefresh = () => {
        console.log('Refreshing the list of users...')
        this.handleOnSelectUsers(this.props.customerCode, "")
    }

    handleOnRowClicked = (id) => {
        if (id == null) {
            this.setState({clickedUserId: null})
            this.props.apiSelectUser(null, null, null, null, null, null, null, null)
        } else {
            const row = document.getElementById("row-id-" + id)

            if (row !== null) {
                if (row.childNodes) {
                    this.props.apiSelectUser(
                        row.childNodes[1].innerText,
                        row.childNodes[2].innerText,
                        row.childNodes[3].innerText,
                        row.childNodes[5].innerText, // login
                        row.childNodes[6].innerText,
                        row.childNodes[7].innerText,
                        row.childNodes[8].innerText,
                        row.childNodes[9].innerText,
                        row.childNodes[11].innerText
                    )
                    this.setState({
                        clickedUserId: id,
                        clickedUserName: row.childNodes[5].innerText,
                        clickedUserIsPrimaryAccount: (row.childNodes[12].innerText === "1" ? 1 : 0)
                    })
                }
            }
        }
    }

    handleOnAddUser = (model) => {
        if (model) {
            this.props.apiInsertUser(
                this.props.customerCode,
                "PG",
                model.userGroup,
                model.userRole,
                model.userLogin,
                Buffer.from(model.userPassword).toString('base64'),
                model.userFirstName,
                model.userLastName,
                model.userEmail,
                model.userPhone,
                "", /* userDisplayName */
                "", /* userWorkPhone1 */
                "", /* userWorkPhone2 */
                model.userGenderCode,
                ""  /* userDescription */
            )
        }
    }

    handleOnEditUser = (model) => {
        if (model) {
            console.log("Modifying user Id = " + this.state.clickedUserId + "...")
            this.props.apiEditUser(
                this.state.clickedUserId,
                this.props.customerCode,
                "PG",
                model.userLogin,
                model.userGroupCode,
                model.userRole,
                Buffer.from(model.userPassword).toString('base64'),
                model.userFirstName,
                model.userLastName,
                model.userEmail,
                model.userPhone,
                "", /* userDisplayName */
                "", /* userWorkPhone1 */
                "", /* userWorkPhone2 */
                model.userGenderCode,
                "", /* userDescription */
                "", /* userActiveFrom */
                ""  /* userActiveTo */
            )
        }
    }

    handleOnActivateUser = (id) => {
        console.log('Activating user with Id = ' + id)
        this.props.apiActivateUser(
            this.props.customerCode,
            "PG",
            id)
    }

    handleOnLockUser = (id) => {
        console.log('Locking user with Id = ' + id)
        this.props.apiLockUser(
            this.props.customerCode,
            "PG",
            id)
    }

    handleOnUnlockUser = (id) => {
        console.log('Unlocking user with Id = ' + id)
        this.props.apiUnlockUser(
            this.props.customerCode,
            "PG",
            id)
    }

    handleOnRemoveUser = (id) => {
        console.log('Removing user with Id = ' + id)
        this.props.apiRemoveUser(
            this.props.customerCode,
            "PG",
            id)
    }

    render() {
        return (
            <>
                {(this.isDBResponseReceived()) ? (
                    <PopupAlert
                        key={this.props.DBResponse.message}
                        popupOpenToggle={true}
                        popupSize="md"
                        popupOpenWith="None"
                        popupOpenWithClass=""
                        popupHeaderTitle={ this.props.DBResponse.isError ? this.t('app.common.modals.popupAlert.title2') : this.t('app.common.modals.popupAlert.title') }
                        popupOpenBtnTitle=""
                        popupBodyText={this.props.DBResponse.message}
                        popupHeaderIconClassName={ this.props.DBResponse.isError ? "fas fa-exclamation-triangle font-red" : "fas fa-check-circle font-green" }
                        popupSubmitBtnText={this.t('app.common.modals.popupAlert.cancelText')}
                        popupEscapeListener={false}
                        popupOnSubmitHandler={() => {
                            this.handleOnTableRefresh()
                            this.props.apiClearResponse()
                        }}
                    />
                ): (
                    <></>
                )}

                <Form className="user-view-form">
                    <Container fluid="md" className="user-list-view">
                        <Row>
                            <Label className="form-view-title">{this.t('app.pages.userListView.title')}</Label>
                        </Row>
                        <Row>
                            <Col xs={{size: 5}} sm={{size: 4}} md={{size: 4}} lg={{size: 3}}>
                                <FormGroup>
                                    <ModalUserEdit
                                        modalOpenMode="NEW"
                                        modalOpenWith="Button"
                                        modalOpenWithClass="transparency btn-large"
                                        modalOpenBtnTitle={this.t('app.pages.userListView.buttons.createAccount')}
                                        modalHeaderTitle={this.t('app.pages.userListView.modals.addUser.title')}
                                        modalClassName="user-edit-form"
                                        modalOnLoadHandler={() => {
                                            this.handleOnRowClicked(null);
                                        }}
                                        modalOnSubmitHandler={(state) => {
                                            this.handleOnAddUser(state);
                                            setTimeout(() => this.handleOnTableRefresh(), 500);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={{size: 4}} sm={{size: 3}} md={{size: 4}} lg={{size: 3}}>
                                <FormGroup>
                                    <Dropdown className={this.state.dropdownOpen ? "transparency-dropdown-after" : "transparency-dropdown"} isOpen={this.state.dropdownOpen} toggle={this.setDropdownToggle}>
                                        <DropdownToggle caret>
                                            {this.t('app.pages.userListView.buttons.dropdown.allActions.menuitemdef')}
                                        </DropdownToggle>
                                        <DropdownMenu persist>
                                            <DropDownItems
                                                userState={this.props.selectedUserState}
                                                userIsPrimaryAccount={this.state.clickedUserIsPrimaryAccount}
                                                clickedUserId={this.state.clickedUserId}
                                                clickedUserName={this.state.clickedUserName}
                                                onActivateUserHandler={(id) => {
                                                    this.handleOnActivateUser(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onUnlockUserHandler={(id) => {
                                                    this.handleOnUnlockUser(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onLockUserHandler={(id) => {
                                                    this.handleOnLockUser(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onRemoveUserHandler={(id) => {
                                                    this.handleOnRemoveUser(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                            />
                                        </DropdownMenu>
                                    </Dropdown>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>

                    <div className="container-fluid user-list-table">
                        <div className="row user-list-table-header">
                            <div className="col-xs-1" style={{width: '2%', textAlign: 'right'}}>#</div>
                            <div className="col-xs-4 col-md-1" style={{marginLeft: '-2px', minWidth: '17vh'}}>{this.t('app.pages.userListView.grids.userList.cols.group')}</div>
                            <div className="col-xs-4 col-md-1">{this.t('app.pages.userListView.grids.userList.cols.status')}</div>
                            <div className="col-xs-4 col-md-1">{this.t('app.pages.userListView.grids.userList.cols.role')}</div>
                            <div className="col-xs-1" style={{width: '20px'}}></div>
                            <div className="col-xs-4 col-md-1" style={{paddingLeft: '8px'}}>{this.t('app.pages.userListView.grids.userList.cols.login')}</div>
                            <div className="col-xs-4 col-md-1">{this.t('app.pages.userListView.grids.userList.cols.firstName')}</div>
                            <div className="col-xs-6 col-md-1">{this.t('app.pages.userListView.grids.userList.cols.lastName')}</div>
                            <div className="col-xs-6 col-md-3">{this.t('app.pages.userListView.grids.userList.cols.email')}</div>
                            <div className="col-xs-4 col-md-2">{this.t('app.pages.userListView.grids.userList.cols.phone')}</div>
                            <div className="col text-right" style={{marginRight: (this.state.isScrollable ? '10px' : '0px')}}>
                                <i className='fas fa-sync-alt fa-2x fa-table-header-icon' title={this.t('app.pages.userListView.hints.iconRefresh')} onClick={() => this.handleOnTableRefresh()}></i>
                            </div>
                            <div style={{display: 'none'}}></div>
                            <div style={{display: 'none'}}></div>
                        </div>

                        <div className="user-list-table-body-scrollable">
                            {this.props.userList.map((item, index) => {
                                const colClickedClass = (this.state.clickedUserId === item.Id) ? "user-list-item-clicked" : ""

                                return (
                                    <div className={"row user-list-table-body " + colClickedClass} key={index} id={"row-id-" + item.Id} onClick={() => this.handleOnRowClicked(item.Id)}>
                                        <div className="col-xs-1" style={{width: '2%', textAlign: 'right'}}>{index + 1}</div>
                                        <div className="col-xs-5 col-md-1" style={{minWidth: '17vh'}}>{item.UserGroup}</div>
                                        <div className={"col-xs-4 col-md-1 col-user-status-" + item.StatusName}>{item.StatusName}</div>
                                        <div className="col-xs-4 col-md-1">{item.UserPrimaryRole}</div>
                                        <div className="col-xs-1">
                                            {item.IsPrimaryAccount ? (
                                                <i className="fas fa-user-shield"></i>
                                            ) : (<i style={{padding: '9px'}}></i>)}
                                        </div>
                                        <div className="col-xs-4 col-md-1" style={{paddingLeft: '8px'}}>
                                            {item.Login}
                                        </div>
                                        <div className="col-xs-4 col-md-1">{item.FirstName}</div>
                                        <div className="col-xs-6 col-md-1">{item.LastName}</div>
                                        <div className="col-xs-6 col-md-3">{item.Email}</div>
                                        <div className="col-xs-4 col-md-2">{item.Phone}</div>
                                        <div className="col text-right">
                                            <ModalUserEdit
                                                modalIsPrimaryAccount={this.state.clickedUserIsPrimaryAccount}
                                                modalOpenMode="EDIT"
                                                modalOpenWith="Icon"
                                                modalOpenWithClass="far fa-edit fa-2x fa-table-body-icon"
                                                modalOpenBtnTitle={this.t('app.pages.userListView.hints.iconEdit')}
                                                modalHeaderTitle={this.t('app.pages.userListView.modals.addUser.title2')}
                                                modalClassName="user-edit-form"
                                                modalOnLoadHandler={() => {}}
                                                modalOnSubmitHandler={(state) => {
                                                    this.handleOnEditUser(state);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                            />
                                        </div>
                                        <div style={{display: 'none'}}>{item.Gender}</div>
                                        <div style={{display: 'none'}}>{item.IsPrimaryAccount}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerCode: state.session.customer,
    licenseType: state.customer.profile.licenseType,
    licenseSymbol: state.customer.profile.licenseSymbol,
    userList: state.user.userList,
    userGroupList: state.user.userGroupList,
    userRoleList: state.user.userRoleList,
    selectedUserState: state.user.userSelected.statusName,
    DBResponse: { isError: state.user.dbStatus.isError, message: state.user.dbStatus.message }
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSelectUsers: (customerCode, applicationCode) =>
        dispatch(apiSelectUsers(customerCode, applicationCode)),
    apiSelectUser: (userGroupName, statusName, userRole, login, firstName, lastName, email, phoneNo, gender) =>
        dispatch(apiSelectUser(userGroupName, statusName, userRole, login, firstName, lastName, email, phoneNo, gender)),
    apiInsertUser: (customerCode, applicationCode, userGroup, userRole, userLogin, userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName, userWorkPhone1, userWorkPhone2, userGender, userDescription) =>
        dispatch(apiInsertUser(customerCode, applicationCode, userGroup, userRole, userLogin, userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName, userWorkPhone1, userWorkPhone2, userGender, userDescription)),
    apiEditUser: (customerUserId, customerCode, applicationCode, userGroup, userRole, userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName, userWorkPhone1, userWorkPhone2, userGender, userDescription, userActiveFrom, userActiveTo) =>
        dispatch(apiEditUser(customerUserId, customerCode, applicationCode, userGroup, userRole, userPassword, userFirstName, userLastName, userEmail, userPhone, userDisplayName, userWorkPhone1, userWorkPhone2, userGender, userDescription, userActiveFrom, userActiveTo)),
    apiActivateUser: (customerCode, applicationCode, userId) =>
        dispatch(apiActivateUser(customerCode, applicationCode, userId)),
    apiLockUser: (customerCode, applicationCode, userId) =>
        dispatch(apiLockUser(customerCode, applicationCode, userId)),
    apiUnlockUser: (customerCode, applicationCode, userId) =>
        dispatch(apiUnlockUser(customerCode, applicationCode, userId)),
    apiRemoveUser: (customerCode, applicationCode, userId) =>
        dispatch(apiRemoveUser(customerCode, applicationCode, userId)),
    apiSelectUserGroups: () =>
        dispatch(apiSelectUserGroups()),
    apiSelectUserRoles: () =>
        dispatch(apiSelectUserRoles()),
    apiClearResponse: () =>
        dispatch(apiClearResponse())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserListView));