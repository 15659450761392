import React from 'react'
import { connect } from "react-redux"
import { apiSetSideBarVisibility, apiSetSideBarVisibilityHiddenClass } from "../middleware/app/duck/api"

import { setElementClassRemove } from '../utilities/dom'

import './bootstrap.css'

class Bootstrap extends React.Component {
    constructor(props) {
		super(props)
		
		this.state = {
			hiddenClass: ""
		}
	}
	componentDidMount() {
		this.handleOnRegisterListeners()
	}

	handleOnHideSideBarNavMenu = () => {
		if (document.getElementsByClassName("sidebar-nav-main") !== undefined) {
			if (document.getElementsByClassName("sidebar-nav-main").length > 0) {
				let navClass = document.getElementsByClassName("sidebar-nav-main")[0].classList[1]
				if (document.getElementsByClassName("content-search")[0].className.indexOf("content-search-offset") > -1) {
					if (document.getElementsByClassName(navClass)[0].classList !== undefined) {
						document.getElementsByClassName(navClass)[0].classList.add(this.state.hiddenClass)
						document.getElementsByClassName(navClass)[0].classList.remove(navClass)

						setElementClassRemove("content-search", "content-search-offset")
						this.props.apiSetSideBarVisibility(false)
					}
				}
			}
		}
	}

	handleOnRegisterListeners = () => {
		const elements = document.getElementsByClassName("content-search");
		if (document.getElementsByClassName("sidebar-nav-main") !== undefined) {
			let navClass = ""
			if (document.getElementsByClassName("sidebar-nav-main").length === 0) {
				navClass = this.props.sidebarHiddenClassName
			} else {
				navClass = document.getElementsByClassName("sidebar-nav-main")[0].classList[1]
			}

			if (navClass !== "") {
				this.setState({hiddenClass: navClass})
				this.props.apiSetSideBarVisibilityHiddenClass(navClass)

				for (let i = 0; i < elements.length; i++) {
					elements[i].addEventListener('click', this.handleOnHideSideBarNavMenu, false);
				}
			}
		}
	}

	render() {
		return (
            <></>
        )
    }
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
	sidebarHiddenClassName: state.application.ui.sidebar.visibilityHiddenClassName
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSetSideBarVisibility: (status) =>
        dispatch(apiSetSideBarVisibility(status)),
	apiSetSideBarVisibilityHiddenClass: (cssName) =>
        dispatch(apiSetSideBarVisibilityHiddenClass(cssName))
})

export default connect(mapStateToProps, mapDispatchToProps)(Bootstrap);
