import React from 'react'

import { Form, FormGroup, Label, Media } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next';

import '../../mainBoard.css'
import './customerDeviceView.css'

const CustomerDeviceView = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Form className="customer-device-view-form">
                <FormGroup>
                    <Label className="form-view-title" style={{paddingLeft: '15px'}}>{t('app.pages.customerDeviceView.title')}</Label>
                </FormGroup>
            </Form>

            <div className="device-image-list scroller">
                {props.deviceSummaryList && props.deviceSummaryList.map((item, index) => {
                    const path = '/dashboard/deviceinfo?item=' + item.deviceNo
                    const imageData = item.imageName.replace(/.jpg/g, '')
                    const itemUrl = require("../../../../resources/images/" + imageData + ".jpg")

                    return (
                        <a className="device-image-item-tile" key={index} href={path}>
                            <Media object src={itemUrl} className="device-image-item"></Media>
                            <Label className="device-image-item-label">{t('app.pages.customerDeviceView.labels.vendor')} {item.deviceVendor}</Label>
                            <Label className="device-image-item-label">{t('app.pages.customerDeviceView.labels.model')} {item.deviceModel}</Label>
                            <Label className="device-image-item-label" style={{paddingTop: '5px', fontWeight: 'bold', fontSize: '1em'}}>{item.deviceNo}</Label>
                        </a>
                    );
                })}
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    deviceSummaryList: state.customer.deviceSummaryList || []
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDeviceView);
