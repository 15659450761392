// Author: Teldasoft(c) 2021
// Module: actions.js
// Description: Action creators for 'auth' middleware

import types from './types'

const login = (customerCode, usernameOrEmail, password, userRole) => {
    return {
        type: types.API_LOGIN,
        payload: { user: usernameOrEmail, pass: password, customer: customerCode, role: userRole }
    };
};

const logout = () => {
    return {
        type: types.API_LOGOUT
    };
};

const logonCompleted = () => {
    return {
        type: types.API_LOGON_COMPLETED
    };
};

const profile = (data) => {
    return {
        type: types.API_PROFILE,
        payload: { roleId: data._roleId, roleName: data._roleName, roleFrom: data._activeFrom, roleTo: data._activeTo }
    };
};

const register = (customerCode, email, username, password) => {
    return {
        type: types.API_REGISTER,
        payload: { user: username, pass: password, email: email, customer: customerCode }
    };
};

const error = (msg) => {
    return {
        type: types.API_ERROR,
        payload: msg
    };
};

export default {
    login,
    logonCompleted,
    logout,
    register,
    profile,
    error
}
