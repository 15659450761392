import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Button, Input, Label, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { connect } from "react-redux";

import { useTranslation } from 'react-i18next';
import TextArea from '../components/ui/textArea';
import PopupAlert from '../components/ui/popupAlert'

import { apiSendTicketRequest, apiClearTicketRequest } from "../middleware/app/duck/api"

import { setFieldValidationError, setFieldValidationReset } from "../utilities/dom"
import { onValidateEmail } from '../utilities/validators'
import { setElementIdProperty } from '../utilities/dom'

import '../app.css';

import Bootstrap from '../components/bootstrap'

const deepEqual = require('deep-equal')

const Support = (props) => {
	const { t } = useTranslation();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggleSubjectName = () => {
		setDropdownOpen(prevState => !prevState);
		setFieldValidationReset('subjectName')
	}

	const usePrevious = value => {
        const ref = React.useRef();
     
        React.useEffect(() => {
            ref.current = value;
        }, [value]);
     
        return ref.current;
    }

	const usePropState = datas => {
        const [dataset, setDataset] = useState(datas);
        const prevDatas = usePrevious(datas);
     
        const handleChangeDataset = data => setDataset(data);
     
        React.useEffect(() => {
            if (!deepEqual(datas, prevDatas)) {
                setDataset(datas);
				if (isUserLoggedIn()) {
					setElementIdProperty("root", "overflow-y", "auto")
				}
            }
        }, [datas, prevDatas]);
     
        return [
            dataset,
            handleChangeDataset
        ]
    }

    const [componentState, setComponentState] = usePropState({
		subjectName: t('app.pages.support.buttons.dropdown.allThemes.menuitemdef'),
        requestMessage: "",
		customerRegEmail: "",
		validationError: ""
    })

	const handleChange = (e) => {
        const { name, value } = e.target;
        setComponentState({ ...componentState, [name]: value, validationError: "" });
		setFieldValidationReset(name)
	}

	const handleValidationErrorMessage = (msg) => {
		setComponentState({ ...componentState, validationError: msg})
	}

	const handleValidateForm = () => {
		const { subjectName, customerRegEmail } = componentState

		if (subjectName === t('app.pages.support.buttons.dropdown.allThemes.menuitemdef')) {
			setFieldValidationError("subjectName")
			return false
		}

		if (customerRegEmail === '') {
			setFieldValidationError("customerRegEmail")
			handleValidationErrorMessage(t('app.pages.support.messages.misRegEmail'))
			return false
		}
		else if (!onValidateEmail(customerRegEmail)) {
			setFieldValidationError("customerRegEmail")
			handleValidationErrorMessage(t('app.pages.support.messages.badEmail'))
			return false
		}

		return true
	}

	const handleOnSendInquiry = () => {
		if (!handleValidateForm()) {
			return
		}
		console.log('Sending ticket request...')

		props.apiSendTicketRequest(
			componentState.customerRegEmail,
			componentState.subjectName,
			componentState.requestMessage
		)
	}

	const isDBResponseReceived = () => {
        return (props.DBResponse.message !== undefined)
    }

	const isUserLoggedIn = () => {
		return (props.isAuthUser || false)
	}
	
	return (
		<>
			{(isDBResponseReceived()) ? (
				<PopupAlert
					key={props.DBResponse.message}
					popupOpenToggle={true}
					popupSize="md"
					popupOpenWith="None"
					popupOpenWithClass=""
					popupHeaderTitle={ props.DBResponse.isError ? t('app.common.modals.popupAlert.title2') : t('app.common.modals.popupAlert.title') }
					popupOpenBtnTitle=""
					popupBodyText={props.DBResponse.message}
					popupHeaderIconClassName={ props.DBResponse.isError ? "fas fa-exclamation-triangle font-red" : "fas fa-check-circle font-green" }
					popupSubmitBtnText={t('app.common.modals.popupAlert.cancelText')}
					popupEscapeListener={false}
					popupOnSubmitHandler={() => {
						setComponentState({ ...componentState,
							subjectName: t('app.pages.support.buttons.dropdown.allThemes.menuitemdef'),
							customerRegEmail: "",
							requestMessage: ""
						})
						props.apiClearTicketRequest()
					}}
				/>
			): (
				<></>
			)}

			<Bootstrap />
			<div className="wrapper">
				<div className="content">
					<div className="support-content-inside">
						<div className="support-container">
							<h5><span className="support-title">{t('app.pages.support.title')}</span></h5>
							<Form className="support-form">
								<div className="v-space-tiny"></div>

								<FormGroup>
									<Row>
										<Col>
											<FormGroup>
												<Label>{t('app.pages.support.labels.requestTheme')} *</Label>
												<Dropdown className={dropdownOpen ? "btn-large transparency-dropdown-after" : "btn-large  transparency-dropdown"} isOpen={dropdownOpen} toggle={toggleSubjectName}>
													<DropdownToggle name="subjectName" style={{textAlign: 'left', minWidth: '100%'}} caret>
														{componentState.subjectName}
													</DropdownToggle>
													<DropdownMenu>
														<DropdownItem key={1} onClick={() => {
                                                            setComponentState({ ...componentState, subjectName: t('app.pages.support.messages.theme1')});
														}}>{t('app.pages.support.messages.theme1')}
														</DropdownItem>
														<DropdownItem key={2} onClick={() => {
                                                            setComponentState({ ...componentState, subjectName: t('app.pages.support.messages.theme2')});
														}}>{t('app.pages.support.messages.theme2')}
														</DropdownItem>
														<DropdownItem key={2} onClick={() => {
                                                            setComponentState({ ...componentState, subjectName: t('app.pages.support.messages.theme3')});
														}}>{t('app.pages.support.messages.theme3')}
														</DropdownItem>
													</DropdownMenu>
												</Dropdown>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormGroup>
												{(componentState.subjectName === t('app.pages.support.messages.theme3')) ? (
													<TextArea name="requestMessage" className="requestMessage" rows="3" title={t('app.pages.support.labels.requestMessage')} placeholder={t('app.pages.support.placeholders.requestMessage')} onChange={handleChange} autoFocus={true} />
												) : (
													<TextArea name="requestMessage" className="requestMessage" rows="3" title={t('app.pages.support.labels.requestMessage')} placeholder={t('app.pages.support.placeholders.requestMessage')} readOnly={true} />
												)}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormGroup>
												<Label>{t('app.pages.support.labels.customerRegEmail')} *</Label>
												<Input type="text" name="customerRegEmail" placeholder={t('app.pages.support.placeholders.customerRegEmail')} value={componentState.customerRegEmail} onChange={handleChange}></Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<FormGroup>
												<Row>
													<Col style={{paddingLeft: '0'}}>
														<Label name="validation-errors" className="validation-error-message">{componentState.validationError}</Label>
													</Col>
													<Col style={{paddingRight: '0'}}>
														<Button color="warning" className="btn-large float-right" style={{width: '200px'}} onClick={handleOnSendInquiry}>{t('app.pages.support.buttons.sendInquiry')}</Button>
													</Col>
												</Row>
											</FormGroup>
										</Col>
									</Row>
									<div className="v-space-small"></div>
									<Row>
										<Col>
											<FormGroup>
												<Label className="content-centered">{t('app.pages.support.labels.contactOwnerHeader')}</Label>
												<br/>
												<div className="content-centered">
													<div className="v-space-tiny"></div>
													<h5 style={{color: 'lightyellow'}}>dzikaknieja.pl Wojciech Kurtyka</h5>
													<p style={{color: 'lightyellow'}}>
														Czarnochowice 386<br/>
														32-020 Wieliczka
													</p>
													<p style={{color: 'lightyellow'}}>
														Numer NIP: 8691777645<br/>
														Numer REGON: 120560789
													</p>
													<p style={{color: 'lightyellow'}}>
														Alior Bank<br/>
														75 2490 0005 0000 4530 8838 2513
													</p>
												</div>
											</FormGroup>
										</Col>
									</Row>
									<div className="v-space-tiny"></div>
									<Row>
										<Col>
											<FormGroup>
												<div className="content-centered">
													<Row>
														<Col>
															<p style={{color: 'lightyellow'}}>
																Telefon: 730 261 300
															</p>
														</Col>
														<Col>
															<p style={{color: 'lightyellow'}}>
																E-mail: <a href="mailto:serwis@dzikaknieja.pl" style={{color: 'lightyellow'}}>serwis@dzikaknieja.pl</a>
															</p>
														</Col>
														<Col>
															<p style={{color: 'lightyellow'}}>
																Strona: <a href="http://www.dzikaknieja.pl" target="_new" style={{color: 'lightyellow'}}>www.dzikaknieja.pl</a>
															</p>
														</Col>
													</Row>
												</div>
											</FormGroup>
										</Col>
									</Row>
								</FormGroup>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
	DBResponse: { isError: state.application.requestStatus.isError, message: state.application.requestStatus.message },
    isAuthUser: state.session.isAuthUser
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSendTicketRequest: (customerEmail, subject, subjectDetails) =>
		dispatch(apiSendTicketRequest(customerEmail, subject, subjectDetails)),
	apiClearTicketRequest: () => dispatch(apiClearTicketRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(Support)