import React from 'react';
import { useTranslation } from 'react-i18next';

import './footer.css';
import { ButtonEx } from './ui/button';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    const { t } = useTranslation();

	return (
		<div className='footer-container'>
			<section className='footer-subscription'>
				<p className='footer-subscription-heading'>
					{t('app.main.headlines.joinNewsletterTitle')}
				</p>
				<p className='footer-subscription-text'>
					{t('app.main.headlines.joinNewsletterSubTitle')}
				</p>
				<div className='input-areas'>
					<form>
						<input
							className='footer-input'
							name='email'
							type='email'
							placeholder={t('app.main.placeholders.subscribe')}
						/>
						<ButtonEx buttonStyle='btn--outline'>{t('app.main.buttons.subscribe')}</ButtonEx>
					</form>
				</div>
			</section>
			<div className='footer-links'>
				<div className='footer-link-wrapper'>
					<div className='footer-link-items'>
						<h2>{t('app.main.labels.aboutUs')}</h2>
						<Link to='/terms'>{t('app.main.labels.terms')}</Link>
					</div>
					<div className='footer-link-items'>
						<h2>{t('app.main.labels.contactUs')}</h2>
						<Link to='/support'>{t('app.main.labels.support')}</Link>
					</div>
				</div>
				<div className='footer-link-wrapper'>
					<div className='footer-link-items'>
						<h2>{t('app.main.labels.regulations')}</h2>
						<Link to='/rodo'>RODO</Link>
					</div>
					<div className='footer-link-items'>
						<h2>{t('app.main.labels.socialMedia')}</h2>
						<a href='https://pl-pl.facebook.com/dzikaknieja/' target='_new'>Facebook</a>
						<a href='https://www.instagram.com/dzikaknieja.pl/' target='_new'>Instagram</a>
						<a href='https://www.youtube.com/channel/UCTFzrJzgj8DZsedKQL4teww' target='_new'>Youtube</a>
					</div>
				</div>
			</div>
			<section className='social-media'>
				<div className='social-media-wrap'>
					<div className='footer-logo'>
						<Link to='/' className='social-logo'>
							DzikaApka v1.0.1
							<i className='fab fa-watchman-monitoring' />
						</Link>
					</div>
					<small className='website-rights'>DzikaApka - Copyright&copy; 2021 by <a className="company-link" href="http://www.teldasoft.com" target='_new'>Teldasoft</a></small>
					<div className='social-icons'>
						<a href='https://pl-pl.facebook.com/dzikaknieja/' target='_new' className="social-icon-link facebook" title={t('app.main.hints.seeUsFacebook')}><i className='fab fa-facebook-f' /></a>
						<a href='https://www.instagram.com/dzikaknieja.pl/' target='_new' className="social-icon-link instagram" title={t('app.main.hints.seeUsInstagram')}><i className='fab fa-instagram' /></a>
						<a href='https://www.youtube.com/channel/UCTFzrJzgj8DZsedKQL4teww' target='_new' className="social-icon-link youtube" title={t('app.main.hints.seeUsYoutube')}><i className='fab fa-youtube' /></a>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Footer;
