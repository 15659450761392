import { combineReducers } from 'redux'

import apiAuthReducer from './middleware/auth/duck'
import apiFolderReducer from './middleware/folder/duck'
import apiDeviceReducer from './middleware/device/duck'
import apiCustomerReducer from './middleware/customer/duck'
import apiUserReducer from './middleware/user/duck'
import apiAppReducer from './middleware/app/duck'


const applicationReducer = combineReducers({
    application: apiAppReducer,
    session: apiAuthReducer,
    customer: apiCustomerReducer,
    folder: apiFolderReducer,
    device: apiDeviceReducer,
    user: apiUserReducer
})

export default applicationReducer