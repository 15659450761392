import React from 'react'
import { connect } from "react-redux"
import { useTranslation, Trans } from 'react-i18next';

import './header.css'

import * as system from '../../../utilities/system'

const Header = props => {
    const { t } = useTranslation();

    return (
        <header className="header">
            <div className="header-title">
                {![system.roles.ROLE_SUPERADMIN].includes(props.userRole) ? (
                    <span>{props.customerWebsite !== "" ? props.customerName + " [" + props.customerWebsite + "]" : props.customerName}</span>
                ) : (
                    <span><i className="fas fa-user-shield font-orange"></i>&nbsp;&nbsp;
                        <Trans i18nKey="app.layout.header.superadmin.mode" t={t}>
                            <span className="font-orange">Super Admin</span>
                            mode activated
                        </Trans>
                    </span>
                )}
            </div>
        </header>
    )
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerName: state.customer.profile.displayName,
    customerWebsite: state.customer.profile.website || ""
})

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);