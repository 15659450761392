import types from './types'

const sessionProfileKey = "session-customer-profile";

const initialState = {
    profile: !!localStorage.getItem(sessionProfileKey)
                ? JSON.parse(localStorage.getItem(sessionProfileKey))
                : {},
    license: {},
    deviceList: [],
    deviceSummaryList: [],
    deviceSelected: {},
    dbStatus: "",
    customerSelected: {},
    customerList: [],
    error: null
}

function CustomerProfileReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_CUSTOMER_LOAD_PROFILE:
            const profile = {
                customerId: action.payload.customerId,
                customerSymbol: action.payload.customerSymbol,
                customerType: action.payload.customerType,
                customerTypeName: action.payload.customerTypeName,
                deviceCount: action.payload.deviceCount,
                deviceMax: action.payload.deviceMax,
                deviceMin: action.payload.deviceMin,
                displayName: action.payload.displayName,
                email: action.payload.email,
                licenseInfo: action.payload.licenseInfo,
                licenseSymbol: action.payload.licenseSymbol,
                licenseType: action.payload.licenseType,
                licenseTypeName: action.payload.licenseTypeName,
                licenseFrom: action.payload.licenseFrom,
                licenseTo: action.payload.licenseTo,
                licenseExpired: action.payload.licenseExpired,
                storageMax: action.payload.storageMax,
                storageMin: action.payload.storageMin,
                storageSize: action.payload.storageSize,
                storageUnit: action.payload.storageUnit,
                website: action.payload.website
            }

            const currentProfile = localStorage.getItem(sessionProfileKey);
            if ((currentProfile === null) || (currentProfile === {})) {
                localStorage.setItem(sessionProfileKey, JSON.stringify(profile));
                return { ...state, profile: profile };
            }
            else return state;

        case types.API_CUSTOMER_LOAD_LICENSE_USAGE:
            let storageUseUnit = action.payload.storageUse;

            if (state.profile.storageUnit === "KB") {
                storageUseUnit = storageUseUnit / 1024
            } else if (state.profile.storageUnit === "MB") {
                storageUseUnit = storageUseUnit / 1024 / 1024
            } else if (state.profile.storageUnit === "GB") {
                storageUseUnit = storageUseUnit / 1024 / 1024 / 1024
            } else if (state.profile.storageUnit === "TB") {
                storageUseUnit = storageUseUnit / 1024 / 1024 / 1024 / 1024
            }

            let storageProgressColor = "success"
            if ((action.payload.storageExceededWarnL1 === 1) && (action.payload.storageExceededWarnL2 === 0)) {
                storageProgressColor = "warning"
            } else if (action.payload.storageExceededWarnL2 === 1) {
                storageProgressColor = "danger"
            }

            const license = {
                licenseCode: action.payload.licenseCode,
                licenseId: action.payload.licenseId,
                storageItemsCount: action.payload.storageItemsCount,
                storageConnectedDeviceCount: action.payload.storageConnectedDeviceCount,
                storageAvail: action.payload.storageAvail,
                storageExceededWarnL1: action.payload.storageExceededWarnL1,
                storageExceededWarnL2: action.payload.storageExceededWarnL2,
                storageHard: action.payload.storageHard,
                storageSoft: action.payload.storageSoft,
                storageUse: storageUseUnit.toFixed(2),
                storageUseHardPcnt: action.payload.storageUseHardPcnt,
                storageUsePcnt: action.payload.storageUsePcnt,
                storageUseSoftPcnt: action.payload.storageUseSoftPcnt,
                storageProgressColor: storageProgressColor
            }

            return { ...state, license: license };

        case types.API_CUSTOMER_LOAD_ERROR:
            return {...state, profile: {}, error: action.payload };

        case types.API_CUSTOMER_CLEANUP_PROFILE:
            localStorage.removeItem(sessionProfileKey);
            return {...state, profile: {} };

        case types.API_CUSTOMER_REFRESH_DEVICES:
        case types.API_CUSTOMER_SELECT_DEVICES:
            return { ...state, deviceList: action.payload.deviceList };

        case types.API_CUSTOMER_SELECT_DEVICE_SUMMARY:
            return { ...state, deviceSummaryList: action.payload.deviceSummaryList };
    
        case types.API_CUSTOMER_INSERT_DEVICE:
            return { ...state, dbStatus: action.payload.insertStatus };

        case types.API_CUSTOMER_ACTIVATE_DEVICE:
        case types.API_CUSTOMER_ACTIVATE_ALL_DEVICE:
            return { ...state, dbStatus: action.payload.activationStatus };

        case types.API_CUSTOMER_DEACTIVATE_DEVICE:
        case types.API_CUSTOMER_DEACTIVATE_ALL_DEVICE:
            return { ...state, dbStatus: action.payload.deactivationStatus };

        case types.API_CUSTOMER_EDIT_DEVICE:
            return { ...state, dbStatus: action.payload.modificationStatus };
                
        case types.API_CUSTOMER_REMOVE_DEVICE:
            return { ...state, dbStatus: action.payload.removalStatus };
    
        case types.API_CUSTOMER_SELECT_DEVICE_ROW:
            return { ...state,
                deviceSelected: {
                    deviceTypeName: action.payload.deviceTypeName,
                    deviceTypeCode: action.payload.deviceTypeCode,
                    statusName: action.payload.statusName,
                    name: action.payload.name,
                    deviceMappingNo: action.payload.deviceMappingNo,
                    imei: action.payload.imei,
                    deviceEmail: action.payload.deviceEmail,
                    phoneNo: action.payload.phoneNo
                }
            };

        // Super-Admin part
        case types.API_SA_CUSTOMER_SELECT_ROW:
            return { ...state,
                customerSelected: {
                    statusName: action.payload.statusName,
                    customerName: action.payload.customerName,
                    externalCode: action.payload.externalCode,
                    registrationDate: action.payload.registrationDate,
                    email: action.payload.email,
                    phone: action.payload.phone
                }
            };

        case types.API_SA_CUSTOMER_SELECT_ALL:
            return { ...state, customerList: action.payload.customerList };

        case types.API_SA_CUSTOMER_REJECT:
        case types.API_SA_CUSTOMER_VERIFY:
        case types.API_SA_CUSTOMER_APPROVE:
        case types.API_SA_CUSTOMER_SUSPEND:
        case types.API_SA_CUSTOMER_REACTIVATE:
        case types.API_SA_CUSTOMER_RESET_ACCOUNT:
            return { ...state, dbStatus: action.payload.response };
    
        case types.API_SA_CUSTOMER_CLEAR_DB_RESPONSE:
            return { ...state, dbStatus: action.payload.response || "" };

        default:
            return state;
    }
};

export default CustomerProfileReducer;