import React from 'react';
import './buttonLink.css';

export const ButtonLink = ({
	urlName,
	content
}) => {
	return (
		<a href={urlName} className='btn btn--primary btn--large' target='_new'>{content}</a>
	);
};