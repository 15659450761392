// Author: Teldasoft(c) 2021
// Module: api.js
// Description: API calls to the underlying Service

import { default as apiAuthActions } from './actions'
import { default as apiFolderActions } from '../../folder/duck/actions'
import { default as apiCustomerActions } from '../../customer/duck/actions'
import { getApi } from '../../../utilities/api'

const loginWithCredentials = async (customerCode, login, password) => {
    const response = await fetch(getApi('/api/v1/user/authenticate'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            login: login,
            password: password
          })
        })

    const data = await response.json()
    return data
}

const loginProfile = async (customerCode, login) => {
    const response = await fetch(getApi('/api/v1/user/profile'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            login: login
          })
      })

    const data = await response.json()
    return data
}

const logout = async () => {
    const response = await fetch(getApi('/api/v1/user/signout'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
          })
        })

    const data = await response.json()
    return data
}

// thunk
export const apiLoginWithCredentials = (customerCode, login, password) => async (dispatch) => {
    const response = await loginWithCredentials(customerCode, login, password)
    if (response && (response.statusCode === 200)) {
        dispatch(apiAuthActions.login(customerCode, login, password))

        const response = await loginProfile(customerCode, login)
        if (response) {
            dispatch(apiAuthActions.profile(response))
        } else {
            dispatch(apiAuthActions.error('User profile fetch failed'))
        }
    } else {
        dispatch(apiAuthActions.error(response.message))
    }
}

// thunk
export const apiLogout = () => async (dispatch) => {
    const response = await logout()
    if (response && (response.statusCode === 200)) {
        dispatch(apiAuthActions.logout())
        dispatch(apiFolderActions.cleanupFolder())
        dispatch(apiCustomerActions.cleanupProfile())
    } else {
        dispatch(apiAuthActions.error('Sign off failed'))
    }
}

// thunk
export const apiLogonCompleted = () => async (dispatch) => {
    dispatch(apiAuthActions.logonCompleted())
}
