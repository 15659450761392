// Author: Teldasoft(c) 2021
// Module: actions.js
// Description: Action creators for 'device' middleware

import types from './types'

const selectDeviceTypes = (deviceTypeList) => {
    return {
        type: types.API_DEVICE_TYPE_SELECT,
        payload: {
            deviceTypeList: deviceTypeList
        }
    };
};

const selectDeviceItem = (name) => {
    return {
        type: types.API_DEVICE_SELECT_DEVICE_NO,
        payload: {
            selectedDeviceNo: name
        }
    };
};

const selectDeviceImage = (id) => {
    return {
        type: types.API_DEVICE_SELECT_IMAGE_ID,
        payload: {
            selectedDeviceImage: id
        }
    };
};

const unselectDeviceImage = (id) => {
    return {
        type: types.API_DEVICE_UNSELECT_IMAGE_ID,
        payload: {
            selectedDeviceImage: id
        }
    };
};

const unselectDeviceImageAll = () => {
    return {
        type: types.API_DEVICE_UNSELECT_IMAGE_ALL,
        payload: {
        }
    };
};

const error = (msg) => {
    return {
        type: types.API_DEVICE_ERROR,
        payload: msg
    };
};

export default {
    selectDeviceTypes,
    selectDeviceItem,
    selectDeviceImage,
    unselectDeviceImage,
    unselectDeviceImageAll,
    error
}
