import types from './types'

const initialState = {
    ui: {
        sidebar: {
            visibility: false,
            visibilityHiddenClassName: null
        }
    },
    requestStatus: ""
}

function ApplicationReducer(state = initialState, action) {
    switch (action.type) {  
        case types.APP_SET_SIDEBAR_NAV_VISIBILITY:
            return { ...state, ui: { sidebar: { visibility: action.payload, visibilityHiddenClassName: state.ui.sidebar.visibilityHiddenClassName } } };

        case types.APP_SET_SIDEBAR_NAV_VISIBILITY_HIDDEN_CLASS:
            return { ...state, ui: { sidebar: { visibility: state.ui.sidebar.visibility, visibilityHiddenClassName: action.payload } } };

        case types.API_APP_SEND_TICKET:
            return { ...state, requestStatus: action.payload.requestStatus };

        case types.API_APP_CLEAR_TICKET:
            return { ...state, requestStatus: action.payload.requestStatus || "" };
    
        default:
            return state;
    }
};

export default ApplicationReducer;