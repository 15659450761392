import React from 'react'
import * as system from '../../../utilities/system'

import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import * as RiIcons from 'react-icons/ri'

export const SideBarData = [
    {
        title: 'itemOverview',
        role: [system.roles.ROLE_ADMIN, system.roles.ROLE_MODERATOR, system.roles.ROLE_STANDARD],
        path: '#',
        icon: <AiIcons.AiFillHome />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        isCollapsed: false,
        showLicExpired: true,
        subNav: [
            {
                title: 'itemLicense',
                path: '/dashboard/license',
                icon: <IoIcons.IoIosKey />,
                showLicExpired: true
            },
            {
                title: 'itemConfiguredDevices',
                path: '/dashboard/customerdevices',
                icon: <IoIcons.IoIosConstruct />,
                showLicExpired: false
            },
        ]
    },
    {
        title: 'itemAdmin',
        role: [system.roles.ROLE_ADMIN],
        path: '#',
        icon: <IoIcons.IoMdLock />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        isCollapsed: true,
        showLicExpired: false,
        subNav: [
            {
                title: 'itemFolders',
                path: '/dashboard/folders',
                icon: <IoIcons.IoMdFolder />,
                showLicExpired: false
            },
            {
                title: 'itemDevices',
                path: '/dashboard/devices',
                icon: <IoIcons.IoIosCellular />,
                showLicExpired: false
            },
            {
                title: 'itemUsers',
                path: '/dashboard/users',
                icon: <IoIcons.IoMdPerson />,
                showLicExpired: false
            },
            // {
            //     title: 'itemLicenses',
            //     path: '/dashboard/licenses',
            //     icon: <IoIcons.IoMdCash />
            // },
            {
                title: 'itemSettings',
                path: '/dashboard/settings',
                icon: <IoIcons.IoMdSettings />,
                showLicExpired: false
            }
        ]
    },
    {
        title: 'itemSupport',
        role: [system.roles.ROLE_ADMIN, system.roles.ROLE_MODERATOR, system.roles.ROLE_STANDARD, system.roles.ROLE_SUPERADMIN],
        path: '/support',
        icon: <IoIcons.IoMdHelpCircle />,
        showLicExpired: true,
        isCollapsed: true
    },
    {
        title: 'itemSystem',
        role: [system.roles.ROLE_SUPERADMIN],
        path: '#',
        icon: <IoIcons.IoMdLock />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        isCollapsed: false,
        showLicExpired: true,
        subNav: [
            {
                title: 'itemCustomers',
                path: '/dashboard/customers',
                icon: <IoIcons.IoMdPerson />
            },
            {
                title: 'itemSettings',
                path: '/dashboard/installation',
                icon: <IoIcons.IoIosSettings />
            }
        ]
    }
]