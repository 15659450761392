// Author: Teldasoft(c) 2021
// Module: api.js
// Description: API calls to the underlying Service

import { default as apiCustomerActions } from './actions'
import { getApi } from '../../../utilities/api'

const loadProfile = async (customerCode, applicationCode) => {
    const response = await fetch(getApi('/api/v1/customer/profile'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            applicationCode: applicationCode
          })
        })

    const data = await response.json()
    return data
}

const loadLicenseUsage = async (customerCode, licenseTypeCode, licenseCode, applicationCode) => {
    const response = await fetch(getApi('/api/v1/customer/license'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            licenseTypeCode: licenseTypeCode,
            licenseCode: licenseCode,
            applicationCode: applicationCode
          })
        })

    const data = await response.json()
    return data
}

const selectDevices = async (customerCode, applicationCode) => {
    const response = await fetch(getApi('/api/v1/customer/devices'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            applicationCode: applicationCode
          })
        })

    const data = await response.json()
    return data
}

const selectDeviceList = async (customerCode, applicationCode) => {
    const response = await fetch(getApi('/api/v1/customer/deviceList'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            applicationCode: applicationCode
          })
        })

    const data = await response.json()
    return data
}

const insertDevice = async (customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode, deviceName,
                                deviceIMEI, deviceEmail, devicePhone, deviceSimCardType) => {
    const response = await fetch(getApi('/api/v1/customer/addDevice'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            licenseTypeCode: licenseType,
            licenseCode: licenseSymbol,
            applicationCode: applicationCode,
            deviceNo: deviceNo,
            deviceTypeCode: deviceTypeCode,
            deviceName: deviceName,
            deviceIMEI: deviceIMEI,
            deviceEmail: deviceEmail,
            devicePhone: devicePhone,
            simCardType: deviceSimCardType
          })
        })

    const data = await response.json()
    return data
}

const activateDevice = async (customerCode, licenseType, licenseSymbol, deviceId) => {
    const response = await fetch(getApi('/api/v1/customer/activateDevice'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            licenseTypeCode: licenseType,
            licenseCode: licenseSymbol,
            deviceId: deviceId
          })
        })

    const data = await response.json()
    return data
}

const deactivateDevice = async (customerCode, licenseType, licenseSymbol, deviceId) => {
    const response = await fetch(getApi('/api/v1/customer/deactivateDevice'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            licenseTypeCode: licenseType,
            licenseCode: licenseSymbol,
            deviceId: deviceId
          })
        })

    const data = await response.json()
    return data
}

const removeDevice = async (customerCode, licenseType, licenseSymbol, deviceId) => {
    const response = await fetch(getApi('/api/v1/customer/removeDevice'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            licenseTypeCode: licenseType,
            licenseCode: licenseSymbol,
            deviceId: deviceId
          })
        })

    const data = await response.json()
    return data
}

const activateAllDevices = async(customerCode, licenseType, licenseSymbol) => {
    const response = await fetch(getApi('/api/v1/customer/activateAllDevices'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            licenseTypeCode: licenseType,
            licenseCode: licenseSymbol
          })
        })

    const data = await response.json()
    return data
}

const deactivateAllDevices = async(customerCode, licenseType, licenseSymbol) => {
    const response = await fetch(getApi('/api/v1/customer/deactivateAllDevices'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerCode: customerCode,
            licenseTypeCode: licenseType,
            licenseCode: licenseSymbol
          })
        })

    const data = await response.json()
    return data
}

const editDevice = async (customerDeviceId, customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode,
                            deviceName, deviceIMEI, deviceEmail, devicePhone, deviceSimCardType) => {
    const response = await fetch(getApi('/api/v1/customer/editDevice'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            customerDeviceId: customerDeviceId,
            customerCode: customerCode,
            licenseTypeCode: licenseType,
            licenseCode: licenseSymbol,
            applicationCode: applicationCode,
            deviceNo: deviceNo,
            deviceTypeCode: deviceTypeCode,
            deviceName: deviceName,
            deviceIMEI: deviceIMEI,
            deviceEmail: deviceEmail,
            devicePhone: devicePhone,
            simCardType: deviceSimCardType
          })
        })

    const data = await response.json()
    return data
}

const saSelectCustomerList = async (applicationCode) => {
    const response = await fetch(getApi('/api/v1/sa/customerList'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            applicationCode: applicationCode
          })
        })

    const data = await response.json()
    return data
}

const saRejectCustomer = async (applicationCode, registrationId) => {
    const response = await fetch(getApi('/api/v1/sa/customer/reject'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            applicationCode: applicationCode,
            registrationId: registrationId
          })
        })

    const data = await response.json()
    return data
}

const saVerifyCustomer = async (applicationCode, registrationId) => {
    const response = await fetch(getApi('/api/v1/sa/customer/verify'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            applicationCode: applicationCode,
            registrationId: registrationId
          })
        })

    const data = await response.json()
    return data
}

const saApproveCustomer = async (applicationCode, registrationId, registrationData) => {
    const response = await fetch(getApi('/api/v1/sa/customer/approve'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            applicationCode: applicationCode,
            registrationId: registrationId,
            contractedLicenseFrom: registrationData.licenseFrom,
            contractedLicenseTo: registrationData.licenseTo,
            contractedAmount: registrationData.licensePaidAmount
          })
        })

    const data = await response.json()
    return data
}

const saSuspendCustomer = async (applicationCode, registrationId) => {
    const response = await fetch(getApi('/api/v1/sa/customer/suspend'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            applicationCode: applicationCode,
            registrationId: registrationId
          })
        })

    const data = await response.json()
    return data
}

const saReactivateCustomer = async (applicationCode, registrationId) => {
    const response = await fetch(getApi('/api/v1/sa/customer/reactivate'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            applicationCode: applicationCode,
            registrationId: registrationId
          })
        })

    const data = await response.json()
    return data
}

const saResetCustomer = async (applicationCode, registrationId) => {
    const response = await fetch(getApi('/api/v1/sa/customer/resetaccount'),
        { method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            applicationCode: applicationCode,
            registrationId: registrationId
          })
        })

    const data = await response.json()
    return data
}

// thunk
export const apiLoadProfile = (customerCode, applicationCode) => async (dispatch) => {
    const response = await loadProfile(customerCode, applicationCode)
    if (response) {
        dispatch(apiCustomerActions.loadProfile(
            response.customerId, response.customerSymbol, response.customerType, response.customerTypeName,
            response.deviceCount, response.deviceMax, response.deviceMin, response.displayName,
            response.email, response.licenseInfo, response.licenseSymbol,
            response.licenseType, response.licenseTypeName, response.licenseFrom, response.licenseTo,
            response.storageMax, response.storageMin, response.storageSize, response.storageUnit, response.website,
            response.licenseExpired
        ))
    } else {
        dispatch(apiCustomerActions.error('Profile loading failed'))
    }
}

// thunk
export const apiLoadLicenseUsage = (customerCode, licenseTypeCode, licenseCode, applicationCode) => async (dispatch) => {
    const response = await loadLicenseUsage(customerCode, licenseTypeCode, licenseCode, applicationCode)
    if (response) {
        dispatch(apiCustomerActions.loadLicenseUsage(
            response.storageItemsCount, response.storageConnectedDeviceCount, response.licenseCode, response.licenseId,
            response.storageAvail, response.storageExceededWarnL1, response.storageExceededWarnL2, response.storageHard,
            response.storageSoft, response.storageUse, response.storageUseHardPcnt, response.storageUsePcnt, response.storageUseSoftPcnt
        ))
    } else {
        dispatch(apiCustomerActions.error('License usage loading failed'))
    }
}

// thunk
export const apiSelectDevices = (customerCode, applicationCode) => async (dispatch) => {
    const response = await selectDevices(customerCode, applicationCode)
    if (response) {
        dispatch(apiCustomerActions.selectDevices(response))
    } else {
        dispatch(apiCustomerActions.error('Device usage loading failed'))
    }
}

// thunk
export const apiSelectDeviceList = (customerCode, applicationCode) => async (dispatch) => {
    const response = await selectDeviceList(customerCode, applicationCode)
    if (response) {
        dispatch(apiCustomerActions.selectDeviceList(response))
    } else {
        dispatch(apiCustomerActions.error('Device summary loading failed'))
    }
}

// thunk
export const apiSelectDevice = (deviceTypeName, statusName, name, deviceMappingNo, imei, deviceEmail, phoneNo, deviceTypeCode) => async (dispatch) => {
    dispatch(apiCustomerActions.selectDevice(deviceTypeName, statusName, name, deviceMappingNo, imei, deviceEmail, phoneNo, deviceTypeCode))
}

// thunk
export const apiInsertDevice = (customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode, deviceName,
                                    deviceIMEI, deviceEmail, devicePhone, deviceSimCardType) => async (dispatch) => {
    const response = await insertDevice(customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode, deviceName,
                                            deviceIMEI, deviceEmail, devicePhone, deviceSimCardType)
    if (response) {
        dispatch(apiCustomerActions.insertDevice(response))
    } else {
        dispatch(apiCustomerActions.error('Customer device creation failed'))
    }
}

// thunk
export const apiActivateDevice = (customerCode, licenseType, licenseSymbol, deviceId) => async (dispatch) => {
    const response = await activateDevice(customerCode, licenseType, licenseSymbol, deviceId)
    if (response) {
        dispatch(apiCustomerActions.activateDevice(response))
    } else {
        dispatch(apiCustomerActions.error('Customer device activation failed'))
    }
}

// thunk
export const apiDeactivateDevice = (customerCode, licenseType, licenseSymbol, deviceId) => async (dispatch) => {
    const response = await deactivateDevice(customerCode, licenseType, licenseSymbol, deviceId)
    if (response) {
        dispatch(apiCustomerActions.deactivateDevice(response))
    } else {
        dispatch(apiCustomerActions.error('Customer device deactivation failed'))
    }
}

// thunk
export const apiRemoveDevice = (customerCode, licenseType, licenseSymbol, deviceId) => async (dispatch) => {
    const response = await removeDevice(customerCode, licenseType, licenseSymbol, deviceId)
    if (response) {
        dispatch(apiCustomerActions.removeDevice(response))
    } else {
        dispatch(apiCustomerActions.error('Customer device removal failed'))
    }
}

// thunk 
export const apiActivateAllDevices = (customerCode, licenseType, licenseSymbol) => async (dispatch) => {
    const response = await activateAllDevices(customerCode, licenseType, licenseSymbol)
    if (response) {
        dispatch(apiCustomerActions.activateAllDevices(response))
    } else {
        dispatch(apiCustomerActions.error('Customer device(s) activation failed'))
    }
}

// thunk
export const apiDeactivateAllDevices = (customerCode, licenseType, licenseSymbol) => async (dispatch) => {
    const response = await deactivateAllDevices(customerCode, licenseType, licenseSymbol)
    if (response) {
        dispatch(apiCustomerActions.deactivateAllDevices(response))
    } else {
        dispatch(apiCustomerActions.error('Customer device(s) deactivation failed'))
    }
}

// thunk
export const apiEditDevice = (customerDeviceId, customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode,
                                deviceName, deviceIMEI, deviceEmail, devicePhone, deviceSimCardType) => async (dispatch) => {
    const response = await editDevice(customerDeviceId, customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode,
                                deviceName, deviceIMEI, deviceEmail, devicePhone, deviceSimCardType)
    if (response) {
        dispatch(apiCustomerActions.editDevice(response))
    } else {
        dispatch(apiCustomerActions.error('Customer device modification failed'))
    }
}

// thunk
export const apiRefreshDevices = () => async(dispatch) => {
    dispatch(apiCustomerActions.refreshDevices())
}

// thunk
export const apiSASelectCustomerList = (applicationCode) => async (dispatch) => {
    const response = await saSelectCustomerList(applicationCode)
    if (response) {
        dispatch(apiCustomerActions.saSelectCustomerList(response))
    } else {
        dispatch(apiCustomerActions.error('SA / Customer list fetch failed'))
    }
}

// thunk
export const apiSASelectCustomer = (statusName, customerName, externalCode, registrationDate, email, phone) => async (dispatch) => {
    dispatch(apiCustomerActions.saSelectCustomer(statusName, customerName, externalCode, registrationDate, email, phone))
}

// thunk
export const apiSARejectCustomer = (applicationCode, registrationId) => async (dispatch) => {
    const response = await saRejectCustomer(applicationCode, registrationId)
    if (response) {
        dispatch(apiCustomerActions.saRejectCustomer(response))
    } else {
        dispatch(apiCustomerActions.error('SA / Customer rejection failed'))
    }
}

// thunk
export const apiSAVerifyCustomer = (applicationCode, registrationId) => async (dispatch) => {
    const response = await saVerifyCustomer(applicationCode, registrationId)
    if (response) {
        dispatch(apiCustomerActions.saVerifyCustomer(response))
    } else {
        dispatch(apiCustomerActions.error('SA / Customer verification failed'))
    }
}

// thunk
export const apiSAApproveCustomer = (applicationCode, registrationId, registrationData) => async (dispatch) => {
    const response = await saApproveCustomer(applicationCode, registrationId, registrationData)
    if (response) {
        dispatch(apiCustomerActions.saApproveCustomer(response))
    } else {
        dispatch(apiCustomerActions.error('SA / Customer approval failed'))
    }
}

// thunk
export const apiSASuspendCustomer = (applicationCode, registrationId) => async (dispatch) => {
    const response = await saSuspendCustomer(applicationCode, registrationId)
    if (response) {
        dispatch(apiCustomerActions.saSuspendCustomer(response))
    } else {
        dispatch(apiCustomerActions.error('SA / Customer suspension failed'))
    }
}

// thunk
export const apiSAReactivateCustomer = (applicationCode, registrationId) => async (dispatch) => {
    const response = await saReactivateCustomer(applicationCode, registrationId)
    if (response) {
        dispatch(apiCustomerActions.saReactivateCustomer(response))
    } else {
        dispatch(apiCustomerActions.error('SA / Customer reactivation failed'))
    }
}

// thunk
export const apiSAResetAccountCustomer = (applicationCode, registrationId) => async (dispatch) => {
    const response = await saResetCustomer(applicationCode, registrationId)
    if (response) {
        dispatch(apiCustomerActions.saResetCustomer(response))
    } else {
        dispatch(apiCustomerActions.error('SA / Customer reset account failed'))
    }
}

// thunk
export const apiClearResponse = () => async (dispatch) => {
    dispatch(apiCustomerActions.clearResponse(null))
}