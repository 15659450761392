// System registration license types
const REG_LICENSE_DEMO         = 'DEMO';

const REG_LICENSE_BASIC        = 'BASIC';
const REG_LICENSE_ADVANCED     = 'ADVANCED';
const REG_LICENSE_PROFESSIONAL = 'PROFESSIONAL';

export default {
    REG_LICENSE_DEMO,
    REG_LICENSE_BASIC,
    REG_LICENSE_ADVANCED,
    REG_LICENSE_PROFESSIONAL
}