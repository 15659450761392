const sense  = require.context('../../public/sense', true);
const crypto = require('crypto');

// ----------- sensitive data -------------------------------------------------------
const algorithm = 'aes-256-cbc';
const key       = sense.keys()[0].replace("./", "")
const IV_LENGTH = 16;
// ----------- sensitive data -------------------------------------------------------

const encryptAES = (text) => {
    const iv = crypto.randomBytes(IV_LENGTH);
    const cipher = crypto.createCipheriv(algorithm, Buffer.from(key, 'hex'), iv);
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return `${iv.toString('hex')}:${encrypted.toString('hex')}`;
};

const decryptAES = (text) => {
    const [iv, encryptedText] = text.split(':').map(part => Buffer.from(part, 'hex'));
    // console.log(iv + ' # ' + encryptedText)
    // console.log(Buffer.from(key, 'hex'))

    const decipher = crypto.createDecipheriv(algorithm, Buffer.from(key, 'hex'), iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
};

const hexStringToByte = (text) => {
    if (!text) {
        return new Uint8Array();
    }
    
    let a = [];
    for (let i = 0, len = text.length; i < len; i+=2) {
        a.push(parseInt(text.substr(i, 2), 16));
    }
    
    return new Uint8Array(a);
}

const bytesToHex = (bytes) => {
    return bytes.map(function(byte) {
        return (byte & 0xFF).toString(16)
    }).join('')
}

exports.encryptAES = encryptAES;
exports.decryptAES = decryptAES;

exports.hexStringToByte = hexStringToByte;
exports.bytesToHex = bytesToHex;
