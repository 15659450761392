// Author: Teldasoft(c) 2021
// Module: types.js
// Description: Action types supported for the 'device' middleware

const API_DEVICE_TYPE_SELECT        = 'API_DEVICE_TYPE_SELECT';
const API_DEVICE_SELECT_DEVICE_NO   = 'API_DEVICE_SELECT_DEVICE_NO';
const API_DEVICE_SELECT_IMAGE_ID    = 'API_DEVICE_SELECT_IMAGE_ID';
const API_DEVICE_UNSELECT_IMAGE_ID  = 'API_DEVICE_UNSELECT_IMAGE_ID';
const API_DEVICE_UNSELECT_IMAGE_ALL = 'API_DEVICE_UNSELECT_IMAGE_ALL';
const API_DEVICE_ERROR              = 'API_DEVICE_ERROR';

export default {
    API_DEVICE_TYPE_SELECT,
    API_DEVICE_SELECT_DEVICE_NO,
    API_DEVICE_SELECT_IMAGE_ID,
    API_DEVICE_UNSELECT_IMAGE_ID,
    API_DEVICE_UNSELECT_IMAGE_ALL,
    API_DEVICE_ERROR
}
