import types from './types'

const initialState = {
    customerCode: '',
    folderId: null,
    folderType: '',
    folderName: '',
    folderDisplayName: '',
    folderDescription: '',
    folderCreator: '',
    folderOwner: '',
    folderList: [],
    folderTypeList: [],
    isActive: true,
    isLocked: false,
    dbStatus: "",
    error: null
}

function FolderReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_FOLDER_INSERT:
            return { ...state,
                dbStatus: action.payload.insertStatus
            };

        case types.API_FOLDER_UPDATE:
            return { ...state,
                dbStatus: action.payload.updateStatus
            };

        case types.API_FOLDER_DELETE:
            return { ...state,
                dbStatus: action.payload.deleteStatus
            };

        case types.API_FOLDER_REFRESH:
            return { ...state, error: null, dbStatus: "",
                folderId: null,
                folderType: 'Default',
                folderName: '',
                folderDisplayName: '',
                folderDescription: '',
            };

        case types.API_FOLDER_CLEANUP:
            return { ...state, error: null, dbStatus: "",
                folderId: null,
                folderType: null,
                folderName: null,
                folderDisplayName: null,
                folderDescription: null,
                folderCreator: null,
                folderOwner: null,
                folderList: [],
                folderTypeList: [],
                isActive: false,
                isLocked: false                
            };

        case types.API_FOLDER_SELECT:
            return { ...state, error: null, folderList: action.payload.folderList };
    
        case types.API_FOLDER_SELECT_ROW:
            return { ...state,
                folderId: action.payload.folderId,
                folderType: action.payload.folderType,
                folderName: action.payload.folderName,
                folderDisplayName: action.payload.folderDisplayName,
                folderDescription: action.payload.folderDescription
            };

        case types.API_FOLDER_TYPE_SELECT:
            return { ...state, error: null, folderTypeList: action.payload.folderTypeList };

        case types.API_FOLDER_TAG_IMAGE:
        case types.API_FOLDER_TAG_IMAGES:
        case types.API_FOLDER_REMOVE_IMAGES:
            return { ...state, error: null };
        
        case types.API_FOLDER_ERROR:
            return { ...state, error: action.payload, dbStatus: action.payload };
    
        default:
            return state;
    }
};

export default FolderReducer;