import React from 'react';
import { Link } from 'react-router-dom';
import { default as licenseTypes } from '../../utilities/system/licenseTypes';

import './licenseCardItem.css';

const defaultSelectionLicenseType = licenseTypes.REG_LICENSE_ADVANCED

const handleSelectLicense = (type) => {
	if (sessionStorage.getItem('registerSelectedLicense')) {
		sessionStorage.removeItem('registerSelectedLicense')
	}
	localStorage.setItem('registerSelectedLicense', type)
}

function LicenseCardItem(props) {
	const selectedLicenseType = sessionStorage.getItem('registerSelectedLicense') || localStorage.getItem('registerSelectedLicense') || defaultSelectionLicenseType

	return (
		<>
			<li className={(selectedLicenseType === props.licenseDetails.licenseType) ? 'license-item-selected' : 'license-item'}>
				<Link className='license-item-link' to="#" data-id={props.licenseDetails.licenseId} data-license-type={props.licenseDetails.licenseType}
					onClick={() => {
						handleSelectLicense(props.licenseDetails.licenseType)
					}}>
					<figure className='license-item-pic-wrap' data-category={props.label}>
						<img
							className='license-item-img'
							alt=''
							src={props.src}
						/>
					</figure>
					<div className='license-item-details-info'>
						<ul className='license-item-details-list-info'>
							<li>{props.licenseMaxDevices}&nbsp;&nbsp;{props.licenseDetails.licenseMaxDevices}</li>
							<li>{props.licenseMaxStorage}&nbsp;&nbsp;{props.licenseDetails.licenseStorage + " " + props.licenseDetails.licenseStorageUnit}</li>
						</ul>
					</div>
					<div className={(selectedLicenseType === props.licenseDetails.licenseType) ? 'license-item-price-info-selected' : 'license-item-price-info'}>
						{props.licenseDetails.licensePrice}&nbsp;<span style={{fontSize: '1.0em'}}>{props.licenseDetails.licenseCurrency}</span>
					</div>
					<div className='license-item-info'>
						<p style={{color: 'lightyellow'}}>
							{props.textPricePerPeriod}
						</p>
						<button className={(selectedLicenseType === props.licenseDetails.licenseType) ? "btn btn--outline-fig-selected btn--medium-fig" : "btn btn--outline-fig btn--medium-fig"}>
							{(selectedLicenseType === props.licenseDetails.licenseType) ? props.textSelected : props.text}
						</button>
						{(selectedLicenseType === props.licenseDetails.licenseType) ? (
							<>&nbsp;<i className="fas fa-check fa-green-color"></i></>
						) : (
							<></>
						)}
					</div>
				</Link>
			</li>
		</>
	);
}

export default LicenseCardItem;
