import React from 'react'
import { Form, FormGroup, Label } from "reactstrap"
import { connect } from "react-redux"

import '../../mainBoard.css'
import './licenseListView.css'

const LicenseListView = (props) => {

    return(
        <Form className="license-view-form">
            <FormGroup>
                <Label className="form-view-title">Manage system licenses</Label>
            </FormGroup>
        </Form>
    );
}

const mapStateToProps = ({ session: { foo } }) => ({
    //TODO
    foo: foo
});

const mapDispatchToProps = dispatch => ({
    //TODO
})

export default connect(mapStateToProps, mapDispatchToProps)(LicenseListView);