import React from 'react'
import { connect } from "react-redux"

import Footer from './footer/footer'
import Header from './header/header'
import SideBar from './sidebar/sideBar'

import FolderListView from './admin/folders/folderListView'
import DeviceListView from './admin/devices/deviceListView'
import DeviceInfo from './admin/devices/deviceInfo'
import LicenseListView from './admin/licenses/licenseListView'
import UserListView from './admin/users/userListView'
import SettingsView from './admin/settings/settingsView'

import CustomerLicenseView from './customer/info/customerLicenseView'
import CustomerDeviceView from './customer/devices/customerDeviceView'

import SuperAdminCustomerListView from './superadmin/customers/customersListView'
import SuperAdminSettingsView from './superadmin/installation/settingsView'

import WelcomeDashboardView from './home/welcomeDashboard'

import * as system from '../../utilities/system'

import '../../app.css'
import './mainBoard.css'

const MainBoard = (props) => {
    const section = props.path

    return (
        <div className="wrapper">
            <Header userRole={props.userRole} />
            <div className="content">
                <div className="content-inside">
                    <SideBar path={section} />
                    {(() => {
                        if ((props.isLicenseExpired === 1) && (section !== '/dashboard/license')) {
                            return (
                                <div className="content-search">
                                    <div className="content-search-margin">
                                        <WelcomeDashboardView />
                                    </div>
                                </div>
                            )
                        }

                        if ([system.roles.ROLE_ADMIN, system.roles.ROLE_MODERATOR, system.roles.ROLE_STANDARD].includes(props.userRole)) {
                            switch (section) {
                                case '/dashboard/license':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <CustomerLicenseView />
                                            </div>
                                        </div>
                                    )
                                case '/dashboard/customerdevices':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <CustomerDeviceView />
                                            </div>
                                        </div>
                                    )    
                                case '/dashboard/folders':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <FolderListView />
                                            </div>
                                        </div>
                                    )
                                case '/dashboard/devices':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <DeviceListView />
                                            </div>
                                        </div>
                                    )
                                case '/dashboard/deviceinfo':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <DeviceInfo />
                                            </div>
                                        </div>
                                    )
                                case '/dashboard/users':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <UserListView />
                                            </div>
                                        </div>
                                    )
                                case '/dashboard/licenses':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <LicenseListView />
                                            </div>
                                        </div>
                                    )
                                case '/dashboard/settings':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <SettingsView />
                                            </div>
                                        </div>
                                    )
                                default:
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <WelcomeDashboardView />
                                            </div>
                                        </div>
                                    )
                            }
                        }
                        else if ([system.roles.ROLE_SUPERADMIN].includes(props.userRole)) {
                            switch (section) {
                                case '/dashboard/customers':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <SuperAdminCustomerListView />
                                            </div>
                                        </div>
                                    )
                                case '/dashboard/installation':
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <SuperAdminSettingsView />
                                            </div>
                                        </div>
                                    )
                                default:
                                    return (
                                        <div className="content-search">
                                            <div className="content-search-margin">
                                                <WelcomeDashboardView />
                                            </div>
                                        </div>
                                    )
                            }
                        }
                        else {
                            return (
                                <div className="content-search">
                                    <div className="content-search-margin">
                                        <WelcomeDashboardView />
                                    </div>
                                </div>
                            )
                        }
                    })()}
                </div>
            </div>
            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    userRole: state.session.profile.roleName || 'Standard',
    isLicenseExpired: state.customer.profile.licenseExpired || 0
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(MainBoard);