import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import thunk from 'redux-thunk'
import applicationReducer from './reducers'

const store = createStore(applicationReducer, composeWithDevTools(applyMiddleware(thunk)));
window.store = store

export default store

// https://www.youtube.com/watch?v=qrePbm2dil8
// import { applyMiddleware, bindActionCreators, createStore } from "redux"
