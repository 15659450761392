import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./popupVerifyEmail.css";

const PopupVerifyEmail = (props) => {
    const {
        popupOpenBtnTitle,
        popupHeaderTitle,
        popupBodyText,
        popupBodyText2,
        popupSize,
        popupClassName,
        popupHeaderIconClassName,
        popupSubmitBtnText,
        popupNextBtnText,
        popupOnSubmitHandler,
        popupOnNextHandler
    } = props;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal((localStorage.getItem('wizardStepOneValidated') || false) && !modal);
    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    return (
        <>
            <Button className="register-button" onClick={() => {
                popupOnNextHandler();
                toggle();
            }}>{popupOpenBtnTitle} &raquo;</Button>
            <Modal isOpen={modal} toggle={toggle} className={popupClassName} size={popupSize || "sm"} draggable={true} centered={true}>
                <ModalHeader toggle={toggle} close={closeBtn}><i className={popupHeaderIconClassName}></i>&nbsp;{popupHeaderTitle}</ModalHeader>
                <ModalBody>
                    {popupBodyText}<br/>
                    {popupBodyText2}
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" className="btn-large" onClick={() => {popupOnSubmitHandler('VERIFY'); toggle()}}>{popupSubmitBtnText}</Button>{' '}
                    <Button color="secondary" className="transparency btn-large" onClick={() => {toggle(); popupOnSubmitHandler('CONTINUE');}}>{popupNextBtnText} &raquo;</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default PopupVerifyEmail;