import React, { useEffect } from 'react'
import { Form, FormGroup, Label } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next';

import { parse } from '../../../utilities/common'
import PopupAlert from '../../../components/ui/popupAlert'
import './welcomeDashboard.css'

const WelcomeDashboardView = (props) => {
    const { t } = useTranslation();

    return(
        <Form className="settings-view-form">
            <FormGroup>
                <Label className="form-view-title">{t('app.component.mainboard.welcomeDashboard.title')}</Label>
            </FormGroup>

            {(props.isLicenseExpired === 1) && (
                <PopupAlert
                    popupOpenToggle={true}
                    popupSize="lg"
                    popupOpenWith="None"
                    popupOpenWithClass=""
                    popupHeaderTitle={t('app.component.mainboard.modals.licenseExpired.title')}
                    popupOpenBtnTitle=""
                    popupBodyText={parse(t('app.component.mainboard.modals.licenseExpired.bodyText'), props.licenseExpiredAt)}
                    popupHeaderIconClassName="fas fa-exclamation-triangle font-red"
                    popupSubmitBtnText={t('app.component.mainboard.modals.licenseExpired.cancelText')}
                    popupEscapeListener={true}
                    popupOnSubmitHandler={() => {
                    }}
                />
            )}
        </Form>
    );
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    isLicenseExpired: state.customer.profile.licenseExpired || 0,
    licenseExpiredAt: state.customer.profile.licenseTo
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeDashboardView);