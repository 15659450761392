import types from './types'

const sessionUserKey        = "session-user";
const sessionCustomerKey    = "session-customer";
const sessionProfileKey     = "session-profile";
const sessionLogonStatusKey = "session-logon-status";

const initialState = {
    isAuthUser: !!localStorage.getItem(sessionUserKey),
    user: JSON.parse(localStorage.getItem(sessionUserKey)) || {},
    customer: JSON.parse(localStorage.getItem(sessionCustomerKey)) || {},
    profile: JSON.parse(localStorage.getItem(sessionProfileKey)) || {},
    isLoading: false,
    isLogonCompleted: JSON.parse(localStorage.getItem(sessionLogonStatusKey)) || false,
    error: null
}

function AuthenticationReducer(state = initialState, action) {
    switch (action.type) {
        case types.API_LOGIN:
            let userItem = action && action.payload ? action.payload.user : {}
            localStorage.setItem(sessionUserKey, JSON.stringify(userItem));

            let customerItem = action && action.payload ? action.payload.customer : {}
            localStorage.setItem(sessionCustomerKey, JSON.stringify(customerItem));

            return { ...state, isAuthUser: true, user: userItem, customer: customerItem, error: null };

        case types.API_PROFILE:
            let profileItem = action && action.payload ? {
                roleId: action.payload.roleId,
                roleName: action.payload.roleName,
                roleFrom: action.payload.roleFrom,
                roleTo: action.payload.roleTo
            } : {}
            localStorage.setItem(sessionProfileKey, JSON.stringify(profileItem));

            return { ...state, isAuthUser: true, profile: profileItem, error: null };

        case types.API_LOGON_COMPLETED:
            localStorage.setItem(sessionLogonStatusKey, true);
            return { ...state, isLogonCompleted: localStorage.getItem(sessionLogonStatusKey) };

        case types.API_LOGOUT:
            localStorage.removeItem(sessionUserKey);
            localStorage.removeItem(sessionCustomerKey);
            localStorage.removeItem(sessionProfileKey);
            localStorage.removeItem(sessionLogonStatusKey);
            return { ...state, isAuthUser: false, isLogonCompleted: false, user: {}, customer: {}, profile: {} };

        case types.API_ERROR:
            localStorage.removeItem(sessionUserKey);
            localStorage.removeItem(sessionCustomerKey);
            localStorage.removeItem(sessionProfileKey);
            localStorage.removeItem(sessionLogonStatusKey);
            return { ...state, isAuthUser: false, isLogonCompleted: false, error: action.payload };
    
        case types.API_REGISTER:
        default:
            return state;
    }
};

export default AuthenticationReducer;