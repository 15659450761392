import React, { useState } from 'react'
import { connect } from "react-redux"
import { useTranslation, Trans } from 'react-i18next';

import { apiSelectDeviceItem } from "../../../middleware/device/duck/api"

import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as IoIcons from 'react-icons/io'

import './sideBar.css'

const SideBarLink = styled(Link)`
    display: flex;
    color: #e1e9fc;
    align-items: center;
    padding: 20px;
    margin-left: 4px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 18px;
    font-family: 'PT Sans', sans-serif;

    &:hover {
        background: #252831;
        color: #fff;
        border-left: 7px solid gray;
        cursor: pointer;
        text-decoration: none;
        margin-left: -3px;
    }
`;

const SideBarDeviceLink = styled(Link)`
    display: flex;
    color: #e1e9fc;
    align-items: center;
    padding: 20px;
    padding-left: 60px;
    margin-left: 4px;
    list-style: none;
    height: 40px;
    text-decoration: none;
    font-size: 18px;
    font-family: 'PT Sans', sans-serif;

    &:hover {
        background: lightsalmon;
        color: #fff;
        border-left: 7px solid gray;
        cursor: pointer;
        text-decoration: none;
        margin-left: -3px;
    }
`;

const SideBarLabel = styled.span`
    margin-left: 16px;
`;

const DropDownLink = styled(Link)`
    background: #414757;
    height: 50px;
    display: flex;
    padding-left: 40px;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    font-family: 'PT Sans', sans-serif;

    &:hover {
        background: gray;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
    }

    &:active {
        background: gray;
        color: #fff;
    }
`;

const SubMenu = ({ deviceSummaryList, apiSelectDeviceItem, selectedDeviceNo, isLicenseExpired, path, item, userRole }) => {
    const [subnav, setSubNav] = useState(!item.isCollapsed || false)
    const showSubNav = () => setSubNav(!subnav)

    const handleOnItemAllClicked = (name) => {
        apiSelectDeviceItem(name);
        window.location = `/dashboard/deviceinfo?item=${name}`
    }

    const { t } = useTranslation();

    return (
        <>
            {(item.role.includes(userRole)) ? (
                <>
                    {((isLicenseExpired && item.showLicExpired) || (!isLicenseExpired)) && (
                        <SideBarLink to={item.path} key={path} onClick={item.subNav && showSubNav}>
                            <div className="sideBar-linkitem">
                                {item.icon}
                                <SideBarLabel>{t('app.layout.sidebar.'+ item.title)}</SideBarLabel>
                            </div>
                            <div>
                                {item.subNav && subnav
                                    ? item.iconOpened
                                    : item.subNav
                                    ? item.iconClosed
                                    : null
                                }
                            </div>
                        </SideBarLink>
                    )}
                    {subnav && item.subNav.map((item, index) => {
                        if ((isLicenseExpired && item.showLicExpired) || (!isLicenseExpired)) {
                            return (
                                <div key={index}>
                                    <DropDownLink to={item.path} key={index}>
                                        {item.icon}
                                        <SideBarLabel>{t('app.layout.sidebar.'+ item.title)}</SideBarLabel>
                                    </DropDownLink>
                                    <div className="scroller">
                                        {(item.path === '/dashboard/customerdevices') ? (
                                            <SideBarDeviceLink
                                                className={(selectedDeviceNo === t('app.layout.sidebar.itemALL') ? "device-no-selected" : "")}
                                                to={"/dashboard/deviceinfo?item=" + t('app.layout.sidebar.itemALL')}
                                                key={t('app.layout.sidebar.itemALL')}
                                                onClick={() => handleOnItemAllClicked(t('app.layout.sidebar.itemALL'))}>
                                                <div className="sideBar-linkitem" id={t('app.layout.sidebar.itemALL')}>
                                                    <IoIcons.IoIosSearch />
                                                    <SideBarLabel>{t('app.layout.sidebar.itemALL')}</SideBarLabel>
                                                </div>
                                            </SideBarDeviceLink>
                                        ) : (
                                            <></>
                                        )}

                                        {deviceSummaryList && deviceSummaryList.map((deviceItem, deviceIndex) => {
                                            return (
                                            <CustomerDevice
                                                    path={item.path}
                                                    name={deviceItem.deviceNo}
                                                    key={deviceItem.deviceNo}
                                                    device-row-id={deviceItem.id}
                                                    selectedDeviceNo={selectedDeviceNo}
                                                    apiSelectDeviceItem={apiSelectDeviceItem}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }
                    })}
                </>
            ) : (
                <></>
            )}
        </>
    );
};

const CustomerDevice = ({ path, name, apiSelectDeviceItem, selectedDeviceNo }) => {
    const deviceInfoPath = '/dashboard/deviceinfo'
    const handleOnItemClicked = (name) => {
        apiSelectDeviceItem(name);
        window.location = `${deviceInfoPath}?item=${name}`
    }

    if (path === '/dashboard/customerdevices' ) {
        return (
            <>
                <SideBarDeviceLink className={(selectedDeviceNo === name ? "device-no-selected" : "")} to={deviceInfoPath + '?item=' + name} key={name} onClick={() => handleOnItemClicked(name)}>
                    <div className="sideBar-linkitem sideBar-linkitem-offset-small" id={name}>
                        <IoIcons.IoIosCamera />
                        <SideBarLabel>{name}</SideBarLabel>
                    </div>
                </SideBarDeviceLink>
            </>
        )
    } else {
        return <></>
    }
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    deviceSummaryList: state.customer.deviceSummaryList || [],
    selectedDeviceNo: state.device.selectedDeviceNo,
    userRole: state.session.profile.roleName || 'Standard',
    isLicenseExpired: state.customer.profile.licenseExpired || 0
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSelectDeviceItem: (deviceNo) =>
        dispatch(apiSelectDeviceItem(deviceNo))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);