// Author: Teldasoft(c) 2021
// Module: types.js
// Description: Action types supported for the 'app' middleware

const APP_SET_SIDEBAR_NAV_VISIBILITY              = 'APP_SET_SIDEBAR_NAV_VISIBILITY';
const APP_SET_SIDEBAR_NAV_VISIBILITY_HIDDEN_CLASS = 'APP_SET_SIDEBAR_NAV_VISIBILITY_HIDDEN_CLASS';
const API_APP_SEND_TICKET                         = 'API_APP_SEND_TICKET';
const API_APP_CLEAR_TICKET                        = 'API_APP_CLEAR_TICKET';

export default {
    APP_SET_SIDEBAR_NAV_VISIBILITY,
    APP_SET_SIDEBAR_NAV_VISIBILITY_HIDDEN_CLASS,
    API_APP_SEND_TICKET,
    API_APP_CLEAR_TICKET
}