import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup,
         Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Input, Label, Row, Col } from 'reactstrap';

import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'

import { setFieldValidationError, setFieldValidationReset } from "../../../../../utilities/dom"
import { getCurrentDate, getShiftedDate, getFormattedDateYYYYMMDD } from "../../../../../utilities/common"
import { onValidateDateYYYYMMDD } from '../../../../../utilities/validators'
import { default as licenseTypes } from '../../../../../utilities/system/licenseTypes';

import "./approveCustomer.css";

const deepEqual = require('deep-equal')

const ApproveCustomer = (props) => {
    const { t } = useTranslation();

    const usePrevious = value => {
        const ref = React.useRef();
     
        React.useEffect(() => {
            ref.current = value;
        }, [value]);
     
        return ref.current;
    }
     
    const usePropState = datas => {
        const [dataset, setDataset] = useState(datas);
        const prevDatas = usePrevious(datas);
     
        const handleChangeDataset = data => setDataset(data);
     
        React.useEffect(() => {
            if (!deepEqual(datas, prevDatas)) {
                setDataset(datas);
                setPageInitForm()
            }
        }, [datas, prevDatas]);
     
        return [
            dataset,
            handleChangeDataset
        ]
    }

    const {
        popupOpenToggle,
        popupOpenWith,
        popupOpenWithClass,
        popupOpenBtnTitle,
        popupHeaderTitle,
        popupBodyText,
        popupSize,
        popupClassName,
        popupHeaderIconClassName,
        popupSubmitBtnText,
        popupCancelBtnText,
        popupLicenseData,
        popupOnSubmitHandler
    } = props;

    const [modal, setModal] = useState(popupOpenToggle || false);
    const toggle = () => setModal(!modal);
    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    const [dropdownPaid, setDropdownPaid] = useState(false);
    const togglePaid = () => {
        setDropdownPaid(prevState => !prevState)
        setFieldValidationReset("paidAmount")
        setFieldValidationReset("licenseFrom")
        setFieldValidationReset("licenseTo")
    }

    const [componentState, setComponentState] = usePropState({
        paidAmount: t('app.pages.sa.customersListView.modals.approveCustomer.buttons.dropdown.customerPaid.menuitemdef'),
        paidAmountWithDiscount: false,
        licenseValidationMessage: "",
        licenseFrom: "",
        licenseTo: ""
    })

    const calculateMthAmount = (cycle, cost, discount = 0) => {
        let licenseCost = 0.0
        if (discount === 0) {
            licenseCost = (cycle * cost)
        } else {
            let disc = (cycle * cost * discount)
            licenseCost = ((cycle * cost) - disc)
        }
        if (licenseCost.toString().includes('.')) {
            return licenseCost + "0"
        } else {
            return licenseCost + ".00"
        }
    }

    const calculateMthAmountDesc = (cycle, cost, discount = 0) => {
        return calculateMthAmount(cycle, cost, discount) + " PLN"
    }

    const calculateLicenseFromDate = () => {
        return getCurrentDate()
    }

    const calculateLicenseToDate = (paidAmount, withDiscount = false) => {
        let licenseDate = null

        if (popupLicenseData.requestedLicenseVariant === licenseTypes.REG_LICENSE_DEMO) {
            licenseDate = getShiftedDate(new Date(), 5 - 1, 'D')
        } else {
            let periodMth = 1

            if (withDiscount) {
                periodMth = 12
            } else {
                periodMth = ((paidAmount / (popupLicenseData.requestedLicenseCostMth || paidAmount)) || 1)
            }
            
            licenseDate = getShiftedDate(new Date(), periodMth, 'M')
            licenseDate = getShiftedDate(licenseDate, -1, 'D')
        }
        return getFormattedDateYYYYMMDD(licenseDate, '-')
    }

    const handleValidateForm = () => {
        if (popupLicenseData.requestedLicenseVariant !== licenseTypes.REG_LICENSE_DEMO) {
            if (componentState.paidAmount === t('app.pages.sa.customersListView.modals.approveCustomer.buttons.dropdown.customerPaid.menuitemdef')) {
                setFieldValidationError("paidAmount")
                return false
            }

            if (componentState.licenseFrom === "") {
                setFieldValidationError("licenseFrom")
                return false
            } else {
                if (!onValidateDateYYYYMMDD(componentState.licenseFrom)) {
                    setFieldValidationError("licenseFrom")
                    setFormValidationMessage(t('app.pages.sa.customersListView.modals.approveCustomer.messages.expectedValidDate'))
                    return false
                }
            }

            if (componentState.licenseTo === "") {
                setFieldValidationError("licenseTo")
                return false
            } else {
                if (!onValidateDateYYYYMMDD(componentState.licenseTo)) {
                    setFieldValidationError("licenseTo")
                    setFormValidationMessage(t('app.pages.sa.customersListView.modals.approveCustomer.messages.expectedValidDate'))
                    return false
                }
            }

            if (componentState.licenseFrom > componentState.licenseTo) {
                setFieldValidationError("licenseFrom")
                setFormValidationMessage(t('app.pages.sa.customersListView.modals.approveCustomer.messages.expectedValidDateOrder'))
                return false
            }
        }
        
        return true
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setComponentState({ ...componentState, [name]: value, licenseValidationMessage: "" });
        setFieldValidationReset(e.target.name)
    }

    const setFormValidationMessage = (message) => {
        setComponentState({ ...componentState, licenseValidationMessage: message });
    }

    const setPageInitForm = () => {
    }

    return (
        <>
            {(popupOpenWith === "Icon") ? (
                <i className={popupOpenWithClass} title={popupOpenBtnTitle} onClick={toggle}></i>
            ) : (
                <div className={popupOpenWithClass} onClick={toggle}>{popupOpenBtnTitle}</div>
            )}        
            <Modal isOpen={modal} toggle={toggle} className={popupClassName} size={popupSize || "sm"} draggable={true} centered={true}>
                <ModalHeader toggle={toggle} close={closeBtn}><i className={popupHeaderIconClassName}></i>&nbsp;{popupHeaderTitle}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Label className="approve-title">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.customerDecided')} <span className="approve-orange">{popupLicenseData.requestedLicenseVariant}</span></Label>
                        </Col>
                    </Row>
                    {(popupLicenseData.requestedLicenseVariant === licenseTypes.REG_LICENSE_DEMO) ? (
                        <Row>
                            <Col>
                                <Label className="approve-summary">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.monthlyCost')} <span className="approve-orange">{popupLicenseData.requestedLicenseCostMth}</span></Label>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col>
                                {(popupLicenseData.requestedLicenseDiscountPcnt > 0) ? (
                                    <Label className="approve-summary">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.monthlyCost')} <span className="approve-orange">{popupLicenseData.requestedLicenseCostMth}</span>
                                        {t('app.pages.sa.customersListView.modals.approveCustomer.labels.monthlyCost2')} <span className="approve-orange">{popupLicenseData.requestedLicenseDiscountPcnt * 100}%</span>
                                    </Label>
                                ) : (
                                    <Label className="approve-summary">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.monthlyCost')} <span className="approve-orange">{popupLicenseData.requestedLicenseCostMth}</span>
                                    </Label>
                                )}
                            </Col>
                        </Row>
                    )}
                    {(popupLicenseData.requestedLicenseVariant !== licenseTypes.REG_LICENSE_DEMO) && (
                        <>
                        <div className="v-space-tiny"></div>
                        <Row>
                            <Col>
                                <Label className="approve-headline">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.confirmPayment')}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label className="approve-title">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.customerPaid')}</Label>
                                <Dropdown className={dropdownPaid ? "btn-large transparency-dropdown-after" : "btn-large  transparency-dropdown"} isOpen={dropdownPaid} toggle={togglePaid}>
                                    <DropdownToggle name="paidAmount" style={{textAlign: 'left', minWidth: '164px'}} caret>
                                        {componentState.paidAmount}
                                    </DropdownToggle>
                                    <DropdownMenu persist>
                                        {[1,2,3,4,5,6,7,8,9,10,11,12].map((item, index) => {
                                            return (
                                                <DropdownItem className="approve-paid-item" key={index} onClick={() => {
                                                    const paidAmount = calculateMthAmount(item, popupLicenseData.requestedLicenseCostMth)

                                                    setComponentState({ ...componentState,
                                                        paidAmount: paidAmount,
                                                        paidAmountWithDiscount: false,
                                                        licenseFrom: calculateLicenseFromDate(),
                                                        licenseTo: calculateLicenseToDate(paidAmount, false),
                                                        licenseValidationMessage: ""
                                                    })
                                                    }}>
                                                    {calculateMthAmountDesc(item, popupLicenseData.requestedLicenseCostMth)}
                                                </DropdownItem>
                                            )
                                        })}
                                        {(popupLicenseData.requestedLicenseDiscountPcnt !== 0) && (
                                            <DropdownItem className="approve-paid-item" key={13} onClick={() => {
                                                const paidAmount = calculateMthAmount(12, popupLicenseData.requestedLicenseCostMth, popupLicenseData.requestedLicenseDiscountPcnt)

                                                setComponentState({ ...componentState,
                                                    paidAmount: paidAmount,
                                                    paidAmountWithDiscount: true,
                                                    licenseFrom: calculateLicenseFromDate(),
                                                    licenseTo: calculateLicenseToDate(paidAmount, true),
                                                    licenseValidationMessage: ""
                                                })
                                                }}>
                                                {calculateMthAmountDesc(12, popupLicenseData.requestedLicenseCostMth, popupLicenseData.requestedLicenseDiscountPcnt)}
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        </>
                    )}
                    <div className="v-space-tiny"></div>
                    {(popupLicenseData.requestedLicenseVariant !== licenseTypes.REG_LICENSE_DEMO) ? (
                        <Row>
                            <Col>
                                <Label className="approve-headline">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.confirmLicensePeriod')}</Label>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col>
                                <Label className="approve-headline">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.confirmLicensePeriodDemo')}</Label>
                            </Col>
                        </Row>
                    )}
                    {(popupLicenseData.requestedLicenseVariant !== licenseTypes.REG_LICENSE_DEMO) ? (
                        <Row>
                            <Col>
                                <Label className="approve-title">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.licenseFrom')}</Label>
                                <Input type="text" name="licenseFrom" value={componentState.licenseFrom} onChange={handleChange} maxLength={10}></Input>
                            </Col>
                            <Col>
                                <Label className="approve-title">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.licenseTo')}</Label>
                                <Input type="text" name="licenseTo" value={componentState.licenseTo} onChange={handleChange} maxLength={10}></Input>
                            </Col>
                            <Col></Col>
                            <Col></Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col>
                                <Label className="approve-title">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.licenseFrom')}</Label>
                                <Input type="text" name="licenseFrom" value={calculateLicenseFromDate()} maxLength={10} readOnly></Input>
                            </Col>
                            <Col>
                                <Label className="approve-title">{t('app.pages.sa.customersListView.modals.approveCustomer.labels.licenseTo')}</Label>
                                <Input type="text" name="licenseTo" value={calculateLicenseToDate()} maxLength={10} readOnly></Input>
                            </Col>
                            <Col></Col>
                            <Col></Col>
                        </Row>
                    )}
                    {(popupLicenseData.requestedLicenseVariant !== licenseTypes.REG_LICENSE_DEMO) ? (
                        <Row>
                            <Col>
                                <Label>&nbsp;</Label>
                                <div style={{height: '20px'}}>
                                    <span style={{color: 'red', fontSize: '0.9em'}}>{componentState.licenseValidationMessage}</span>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <div className="v-space-tiny"></div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" className="btn-large" onClick={() => {
                            const data = {
                                licensePaidAmount: componentState.paidAmount,
                                licensePaidAmountWithDiscount: componentState.paidAmountWithDiscount,
                                licenseFrom: componentState.licenseFrom,
                                licenseTo: componentState.licenseTo
                            }

                            if (handleValidateForm()) {
                                popupOnSubmitHandler(data);
                                toggle();
                            }
                        }}>{popupSubmitBtnText || "Close"}</Button>{' '}
                    <Button color="secondary" className="transparency btn-large" onClick={toggle}>{popupCancelBtnText || "Cancel"}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveCustomer);