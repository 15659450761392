import React from 'react';
import './button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];
const SIZES = ['btn--medium', 'btn--large', 'btn--xlarge'];

export const ButtonEx = ({
	children,
	type,
	onClick,
	buttonStyle,
	buttonSize,
	actionName
}) => {
	const checkButtonStyle = STYLES.includes(buttonStyle)
		? buttonStyle
		: STYLES[0];

	const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

	return (
		<Link to={actionName || '/sign-up'} className='btn-mobile'>
			<button
				className={`btn ${checkButtonStyle} ${checkButtonSize}`}
				onClick={onClick}
				type={type}
			>
			{children}
			</button>
		</Link>
	);
};
