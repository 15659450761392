import React from 'react'
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next';

import * as system from '../../../utilities/system'

import './footer.css'

const Footer = props => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <p>Copyright&copy; by <a href="http://www.teldasoft.com" target="_new" style={{color: '#fff'}}>Teldasoft</a> - 2021
                <span className="font-orange" style={{float: 'right', marginRight: '30px'}}>{t('app.layout.footer.loggedin')}:&nbsp;&nbsp;<span style={{color: 'lightyellow'}}>
                    {props.userLoggedIn + " [" + props.userRole + "]"}</span></span>
                
                {![system.roles.ROLE_SUPERADMIN].includes(props.userRole) && (
                    <span className="font-orange" style={{float: 'right', marginRight: '30px'}}>{t('app.layout.footer.licValidTo')}&nbsp;&nbsp;<span style={{color: 'lightyellow'}}>
                        {props.isLicenseExpired === 1 ? t('app.layout.footer.licExpired') : props.licenseTo}</span></span>
                )}
            </p>
        </footer>
    )
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    userLoggedIn: state.session.user,
    userRole: state.session.profile.roleName,
    licenseTo: state.customer.profile.licenseTo,
    isLicenseExpired: state.customer.profile.licenseExpired || 0
})

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);