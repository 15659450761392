// Author: Teldasoft(c) 2021
// Module: actions.js
// Description: Action creators for 'user' middleware

import types from './types'

const selectUsers = (userList) => {
    return {
        type: types.API_CUSTOMER_SELECT_USERS,
        payload: {
            userList: userList
        }
    };
};

const selectUser = (userGroupName, statusName, userRole, login, firstName, lastName, email, phoneNo, gender) => {
    return {
        type: types.API_CUSTOMER_SELECT_USER_ROW,
        payload: {
            userGroupName: userGroupName,
            statusName: statusName,
            userRole: userRole,
            login: login,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNo: phoneNo,
            gender: gender
        }
    };
}

const selectUserGroups = (userGroupList) => {
    return {
        type: types.API_CUSTOMER_SELECT_USER_GROUPS,
        payload: {
            userGroupList: userGroupList
        }
    };
};

const selectUserRoles = (userRoleList) => {
    return {
        type: types.API_CUSTOMER_SELECT_USER_ROLES,
        payload: {
            userRoleList: userRoleList
        }
    };
};

const insertUser = (status) => {
    return {
        type: types.API_CUSTOMER_INSERT_USER,
        payload: {
            insertStatus: status
        }
    };
}

const editUser = (status) => {
    return {
        type: types.API_CUSTOMER_EDIT_USER,
        payload: {
            modificationStatus: status
        }
    };
}

const activateUser = (status) => {
    return {
        type: types.API_CUSTOMER_ACTIVATE_USER,
        payload: {
            activationStatus: status
        }
    };
}

const lockUser = (status) => {
    return {
        type: types.API_CUSTOMER_LOCK_USER,
        payload: {
            lockingStatus: status
        }
    };
}

const unlockUser = (status) => {
    return {
        type: types.API_CUSTOMER_UNLOCK_USER,
        payload: {
            unlockingStatus: status
        }
    };
}

const removeUser = (status) => {
    return {
        type: types.API_CUSTOMER_REMOVE_USER,
        payload: {
            removalStatus: status
        }
    };
}

const clearResponse = (status) => {
    return {
        type: types.API_CUSTOMER_CLEAR_DB_RESPONSE,
        payload: {
            response: status || ""
        }
    };
}

const error = (msg) => {
    return {
        type: types.API_USER_ERROR,
        payload: msg
    };
};

export default {
    selectUsers,
    selectUser,
    selectUserGroups,
    selectUserRoles,
    insertUser,
    editUser,
    activateUser,
    lockUser,
    unlockUser,
    removeUser,
    clearResponse,
    error
}
