import React, { Suspense } from 'react'

import Bootstrap from './components/bootstrap'
import Navbar from './components/navbar'
import Home from './pages/home'

import './app.css'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'

import AuthRoute from './components/auth/route'

import Register from './pages/register'
import Support from './pages/support'
import Rodo from './pages/rodo'
import Terms from './pages/terms'
import SignUp from './pages/signUp'
import Dashboard from './pages/dashboard'

import store from './store'

class App extends React.Component {

	render() {
		return (
			<Suspense fallback="Loading application DzikaApka. Please wait...">
				<Provider store={store}>
					<Bootstrap />
					<Router>
						<Navbar />
						<Switch>
							<Route path='/' exact component={Home} />
							<Route path="/register" component={Register} />
							<Route path='/support' component={Support} />
							<Route path='/rodo' component={Rodo} />
							<Route path='/terms' component={Terms} />
							<Route path='/sign-up' component={SignUp} />
							
							<AuthRoute path="/dashboard" component={Dashboard} type="private" />

							<Route path='*' exact={true} >
								<Redirect to='/'></Redirect>
							</Route>
						</Switch>
					</Router>
				</Provider>
			</Suspense>
		);
	}
}

export default App;