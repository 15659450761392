// Author: Teldasoft(c) 2021
// Module: types.js
// Description: Action types supported for the 'folder' middleware

const API_FOLDER_INSERT        = 'API_FOLDER_INSERT';
const API_FOLDER_UPDATE        = 'API_FOLDER_UPDATE';
const API_FOLDER_DELETE        = 'API_FOLDER_DELETE';
const API_FOLDER_CLEANUP       = 'API_FOLDER_CLEANUP';
const API_FOLDER_REFRESH       = 'API_FOLDER_REFRESH';
const API_FOLDER_SELECT        = 'API_FOLDER_SELECT';
const API_FOLDER_SELECT_ROW    = 'API_FOLDER_SELECT_ROW';
const API_FOLDER_TYPE_SELECT   = 'API_FOLDER_TYPE_SELECT';
const API_FOLDER_TAG_IMAGE     = 'API_FOLDER_TAG_IMAGE';
const API_FOLDER_TAG_IMAGES    = 'API_FOLDER_TAG_IMAGES';
const API_FOLDER_REMOVE_IMAGES = 'API_FOLDER_REMOVE_IMAGES';

const API_FOLDER_ERROR         = 'API_FOLDER_ERROR';

export default {
    API_FOLDER_INSERT,
    API_FOLDER_UPDATE,
    API_FOLDER_DELETE,
    API_FOLDER_CLEANUP,
    API_FOLDER_REFRESH,
    API_FOLDER_SELECT,
    API_FOLDER_SELECT_ROW,
    API_FOLDER_TYPE_SELECT,
    API_FOLDER_TAG_IMAGE,
    API_FOLDER_TAG_IMAGES,
    API_FOLDER_REMOVE_IMAGES,
    API_FOLDER_ERROR
}
