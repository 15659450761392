// Author: Teldasoft(c) 2021
// Module: dom.js
// Description: DOM utility helpers

export const setFieldValidationError = (fieldName, focus = true) => {
    if (fieldName !== '') {
        let className = "validation-error"
        let id = document.getElementsByName(fieldName)

        if ((id === undefined) || (id.length === 0)) {
            id = document.getElementsByClassName(fieldName)
        }
        if (id) {
            if (id[0].classList.contains('dropdown-toggle')) {
                className = "validation-error-dropdown"
            }
            id[0].classList.add(className)
            if (focus) {
                id[0].focus();
            }
        }
    }
}

export const setFieldValidationReset = (fieldName) => {
    if (fieldName !== '') {
        let className = "validation-error"
        let id = document.getElementsByName(fieldName)

        if ((id === undefined) || (id.length === 0)) {
            id = document.getElementsByClassName(fieldName)
        }
        if (id) {
            if (id[0].classList.contains('dropdown-toggle')) {
                className = "validation-error-dropdown"
            }
            id[0].classList.remove(className)
        }
    }
}

export const setFieldValidationOk = (fieldName) => {
    if (fieldName !== '') {
        document.getElementsByName(fieldName)[0].classList
            .remove("validation-error")
            .add("validation-ok")
    }
}

export const setElementIdProperty = (itemId, name, value) => {
    if (itemId !== '') {
        document.getElementById(itemId).style.setProperty(name, value);
    }
}

export const setElementIdClass = (itemId, className) => {
    if (itemId !== '') {
        document.getElementById(itemId).classList.add(className);
    }
}

export const setElementClassAdd = (itemClass, className) => {
    if (itemClass !== '') {
        document.getElementsByClassName(itemClass)[0].classList.add(className)
    }
}

export const setElementClassRemove = (itemClass, className) => {
    if (itemClass !== '') {
        document.getElementsByClassName(itemClass)[0].classList.remove(className)
    }
}

export const getCheckboxChecked = (itemId) => {
    return document.getElementsByName(itemId)[0].checked
}

export const setCheckboxChecked = (itemId, value = false) => {
    document.getElementsByName(itemId)[0].checked = value
}

export const isDivContentScrollable = function(element) {
    const elementId = document.getElementsByClassName(element)[0]
    if (elementId !== undefined) {
        const hasScrollableContent = elementId.scrollHeight > elementId.clientHeight
        const overflowYStyle = window.getComputedStyle(elementId).overflowY
        const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1
        
        return hasScrollableContent && !isOverflowHidden
    }
    return false
}

export const isTableContentScrollable = function(element) {
    const elementId = document.getElementsByClassName(element)[0]
    if (elementId !== undefined) {
        const id = elementId.childNodes[1]
        const hasScrollableContent = id.scrollHeight > id.clientHeight
        const overflowYStyle = window.getComputedStyle(id).overflowY
        const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1
        
        return hasScrollableContent && !isOverflowHidden
    }
    return false
}

export const setFocusOnInput = function(name) {
    setTimeout(() => { document.getElementsByName(name)[0].focus() }, 200)
}

export const copyToClipboard = function(name, defaultValue = null) {
    var id = document.getElementsByName(name)[0]
    if (id.getAttribute('placeholder')) {
        var value = id.getAttribute('placeholder')
        id.value = value       
    }

    if ((id.value === "") || (id.value === (defaultValue || ""))) {
        return
    }

    id.select();
    id.setSelectionRange(0, 99999);
    document.execCommand("copy");
}
