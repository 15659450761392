import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'

import { Container, Row, Col, Form, FormGroup, Input, Label, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"
import { connect } from "react-redux"

import { apiSelectDevices, apiRefreshDevices, apiSelectDevice,
         apiInsertDevice, apiActivateDevice, apiDeactivateDevice,
         apiRemoveDevice, apiActivateAllDevices, apiDeactivateAllDevices,
         apiEditDevice } from "../../../../middleware/customer/duck/api"
import { apiSelectDeviceTypes } from "../../../../middleware/device/duck/api"
   
import { isDivContentScrollable } from '../../../../utilities/dom'

import ModalDeviceEdit from './modals/modalDeviceEdit'

import PopupAlert from '../../../ui/popupAlert'
import PopupConfirm from '../../../ui/popupConfirm'

import '../../mainBoard.css'
import './deviceListView.css'


function DropDownItems(props) {
    const { t } = useTranslation();

    const isSelected = () => {
        return (props.clickedDeviceId != null)
    }

    const isActivated = () => {
        return (props.deviceState === 'Active')
    }

    const isEmptyDeviceList = () => {
        return (props.deviceCount === 0)
    }

    return (
        <>
            <DropdownItem header>{t('app.pages.deviceListView.buttons.dropdown.allActions.header')}</DropdownItem>
            {isSelected() && isActivated() && (
                <>
                    <DropdownItem className="font-orange">
                        <PopupConfirm
                            popupSize="md"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.deviceListView.modals.deactivateDevice.title') + " [" + props.clickedDeviceName + "]"}
                            popupOpenBtnTitle={t('app.pages.deviceListView.buttons.dropdown.allActions.menuitem2')}
                            popupBodyText={t('app.pages.deviceListView.modals.deactivateDevice.bodyText')}
                            popupHeaderIconClassName="fas fa-power-off"
                            popupSubmitBtnText={t('app.pages.deviceListView.modals.deactivateDevice.submitText')}
                            popupCancelBtnText={t('app.pages.deviceListView.modals.deactivateDevice.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onDeactivateDeviceHandler(props.clickedDeviceId)
                            }} />
                    </DropdownItem>
                    <DropdownItem className="font-red">
                        <PopupConfirm
                            popupSize="md"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.deviceListView.modals.removeDevice.title') + " [" + props.clickedDeviceName + "]"}
                            popupOpenBtnTitle={t('app.pages.deviceListView.buttons.dropdown.allActions.menuitem3')}
                            popupBodyText={t('app.pages.deviceListView.modals.removeDevice.bodyText')}
                            popupHeaderIconClassName="fas fa-trash-alt"
                            popupSubmitBtnText={t('app.pages.deviceListView.modals.removeDevice.submitText')}
                            popupCancelBtnText={t('app.pages.deviceListView.modals.removeDevice.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onRemoveDeviceHandler(props.clickedDeviceId)
                            }} />
                    </DropdownItem>
                    <DropdownItem divider />
                </>
            )}
            {isSelected() && !isActivated() && (
                <>
                    <DropdownItem className="font-green">
                        <PopupConfirm
                            popupSize="md"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.deviceListView.modals.activateDevice.title') + " [" + props.clickedDeviceName + "]"}
                            popupOpenBtnTitle={t('app.pages.deviceListView.buttons.dropdown.allActions.menuitem1')}
                            popupBodyText={t('app.pages.deviceListView.modals.activateDevice.bodyText')}
                            popupHeaderIconClassName="fas fa-signal"
                            popupSubmitBtnText={t('app.pages.deviceListView.modals.activateDevice.submitText')}
                            popupCancelBtnText={t('app.pages.deviceListView.modals.activateDevice.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onActivateDeviceHandler(props.clickedDeviceId)
                            }} />
                    </DropdownItem>
                    <DropdownItem className="font-red">
                        <PopupConfirm
                            popupSize="md"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.deviceListView.modals.removeDevice.title') + " [" + props.clickedDeviceName + "]"}
                            popupOpenBtnTitle={t('app.pages.deviceListView.buttons.dropdown.allActions.menuitem3')}
                            popupBodyText={t('app.pages.deviceListView.modals.removeDevice.bodyText')}
                            popupHeaderIconClassName="fas fa-trash-alt"
                            popupSubmitBtnText={t('app.pages.deviceListView.modals.removeDevice.submitText')}
                            popupCancelBtnText={t('app.pages.deviceListView.modals.removeDevice.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onRemoveDeviceHandler(props.clickedDeviceId)
                            }} />
                    </DropdownItem>
                    <DropdownItem divider />
                </>
            )}
            {!isEmptyDeviceList() && (
                <>
                    <DropdownItem>
                        <PopupConfirm
                            popupSize="md"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.deviceListView.modals.activateAllDevices.title')}
                            popupOpenBtnTitle={t('app.pages.deviceListView.buttons.dropdown.allActions.menuitem4')}
                            popupBodyText={t('app.pages.deviceListView.modals.activateAllDevices.bodyText')}
                            popupHeaderIconClassName="fas fa-signal"
                            popupSubmitBtnText={t('app.pages.deviceListView.modals.activateAllDevices.submitText')}
                            popupCancelBtnText={t('app.pages.deviceListView.modals.activateAllDevices.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onActivateAllDevicesHandler()
                            }} />
                    </DropdownItem>
                    <DropdownItem>
                        <PopupConfirm
                            popupSize="md"
                            popupOpenWith="Link"
                            popupOpenWithClass=""
                            popupHeaderTitle={t('app.pages.deviceListView.modals.deactivateAllDevices.title')}
                            popupOpenBtnTitle={t('app.pages.deviceListView.buttons.dropdown.allActions.menuitem5')}
                            popupBodyText={t('app.pages.deviceListView.modals.deactivateAllDevices.bodyText')}
                            popupHeaderIconClassName="fas fa-power-off"
                            popupSubmitBtnText={t('app.pages.deviceListView.modals.deactivateAllDevices.submitText')}
                            popupCancelBtnText={t('app.pages.deviceListView.modals.deactivateAllDevices.cancelText')}
                            popupOnSubmitHandler={() => {
                                props.onDeactivateAllDevicesHandler()
                            }} />
                    </DropdownItem>
                    <DropdownItem divider />
                </>
            )}
            <DropdownItem disabled>{t('app.pages.deviceListView.buttons.dropdown.allActions.menuitem6')}</DropdownItem>
            <DropdownItem disabled>{t('app.pages.deviceListView.buttons.dropdown.allActions.menuitem7')}</DropdownItem>
        </>
    )
}

class DeviceListView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            availDevices: this.props.maxDevices,
            clickedDeviceId: null,
            clickedDeviceName: null,
            dropdownOpen: false,
            isScrollable: false
        }
    }

    t = (key) => {
        return this.props.t(key)
    }

    setDropdownToggle = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    }

    componentDidMount() {
        this.props.apiSelectDeviceTypes()
        this.handleOnTableRefresh()
        this.setState({isScrollable: isDivContentScrollable('device-list-table-body-scrollable')});
    }

    isDBResponseReceived = () => {
        return (this.props.DBResponse.message !== undefined)
    }

    handleOnSelectDevices = () => {
        this.props.apiSelectDevices(this.props.customerCode, "")
    }

    handleOnTableRefresh = () => {
        console.log('Refreshing the list of devices...')
        this.handleOnSelectDevices(this.props.customerCode, "")
    }

    handleOnRowClicked = (id) => {
        if (id == null) {
            this.setState({clickedDeviceId: null})
            this.props.apiSelectDevice(null, null, null, null, null, null, null, null)
        } else {
            const row = document.getElementById("row-id-" + id)
            if (row !== null) {
                if (row.childNodes) {
                    this.props.apiSelectDevice(
                        row.childNodes[1].innerText,
                        row.childNodes[2].innerText,
                        row.childNodes[3].innerText,
                        row.childNodes[4].innerText,
                        row.childNodes[5].innerText,
                        row.childNodes[6].innerText,
                        row.childNodes[7].innerText,
                        row.childNodes[9].innerText
                    )
                    this.setState({clickedDeviceId: id, clickedDeviceName: row.childNodes[1].innerText + " / " + row.childNodes[3].innerText})
                }
            }
        }
    }

    handleOnAddDevice = (model) => {
        if (model) {
            this.props.apiInsertDevice(
                this.props.customerCode,
                this.props.licenseType,
                this.props.licenseSymbol,
                "",
                model.deviceNo,
                model.deviceTypeCode,
                model.deviceName,
                model.deviceIMEI,
                model.deviceEmail || model.deviceEmailPlaceholder,
                model.devicePhone,
                model.deviceSimCardType
            )
        }
    }

    handleOnEditDevice = (model) => {
        if (model) {
            console.log("Modifying device Id = " + this.state.clickedDeviceId + "...")
            this.props.apiEditDevice(
                this.state.clickedDeviceId,
                this.props.customerCode,
                this.props.licenseType,
                this.props.licenseSymbol,
                "",
                model.deviceNo,
                model.deviceTypeCode,
                model.deviceName,
                model.deviceIMEI,
                model.deviceEmail || model.deviceEmailPlaceholder,
                model.devicePhone,
                model.deviceSimCardType
            )
        }
    }

    handleOnActivateDevice = (id) => {
        console.log("Activating device with Id = " + id)
        this.props.apiActivateDevice(
            this.props.customerCode,
            this.props.licenseType,
            this.props.licenseSymbol,
            id)
    }

    handleOnDeactivateDevice = (id) => {
        console.log("Deactivating device with Id = " + id)
        this.props.apiDeactivateDevice(
            this.props.customerCode,
            this.props.licenseType,
            this.props.licenseSymbol,
            id)
    }

    handleOnRemoveDevice = (id) => {
        console.log("Removing device with Id = " + id)
        this.props.apiRemoveDevice(
            this.props.customerCode,
            this.props.licenseType,
            this.props.licenseSymbol,
            id)
    }

    handleOnActivateAllDevices = () => {
        console.log("Activating all devices...")
        this.props.apiActivateAllDevices(
            this.props.customerCode,
            this.props.licenseType,
            this.props.licenseSymbol)
    }

    handleOnDeactivateAllDevices = () => {
        console.log("Deactivating all devices...")
        this.props.apiDeactivateAllDevices(
            this.props.customerCode,
            this.props.licenseType,
            this.props.licenseSymbol)
    }

    render() {
        console.log(this.props.deviceList)
        return (
            <>
                {(this.isDBResponseReceived()) ? (
                    <PopupAlert
                        key={this.props.DBResponse.message}
                        popupOpenToggle={true}
                        popupSize="md"
                        popupOpenWith="None"
                        popupOpenWithClass=""
                        popupHeaderTitle={ this.props.DBResponse.isError ? this.t('app.common.modals.popupAlert.title2') : this.t('app.common.modals.popupAlert.title') }
                        popupOpenBtnTitle=""
                        popupBodyText={this.props.DBResponse.message}
                        popupHeaderIconClassName={ this.props.DBResponse.isError ? "fas fa-exclamation-triangle font-red" : "fas fa-check-circle font-green" }
                        popupSubmitBtnText={this.t('app.common.modals.popupAlert.cancelText')}
                        popupEscapeListener={false}
                        popupOnSubmitHandler={() => {
                            this.handleOnTableRefresh()
                        }}
                    />
                ): (
                    <></>
                )}
                
                <Form className="device-view-form">
                    <Container fluid="md" className="device-list-view">
                        <Row>
                            <Label className="form-view-title">{this.t('app.pages.deviceListView.title')}</Label>
                        </Row>
                        <Row>
                            <Label>{this.t('app.pages.deviceListView.labels.availableConfigured')} </Label>
                        </Row>
                        <Row>
                            <Col xs={{size: 2}} sm={{size: 2}} md={{size: 2}} lg={{size: 1}}>
                                <FormGroup>
                                    <Input className="transparency emphased text-right font-weight-bold" type="text" name="availDevices" value={this.state.availDevices} readOnly></Input>
                                </FormGroup>
                            </Col>
                            <Col xs={{size: 2}} sm={{size: 2}} md={{size: 2}} lg={{size: 1}}>
                                <FormGroup>
                                    <Input className="transparency emphased text-right font-weight-bold" type="text" name="confDevices" value={this.props.deviceList.length} readOnly></Input>
                                </FormGroup>
                            </Col>
                            <Col xs={{size: 5}} sm={{size: 4}} md={{size: 4}} lg={{size: 3}}>
                                <FormGroup>
                                    {(this.state.availDevices === this.props.deviceList.length) ? (
                                        <PopupAlert
                                            popupSize="md"
                                            popupOpenWith="Button"
                                            popupOpenWithClass="transparency btn-large"
                                            popupHeaderTitle={this.t('app.pages.deviceListView.modals.addDevice.title')}
                                            popupOpenBtnTitle={this.t('app.pages.deviceListView.buttons.configureDevice')}
                                            popupBodyText={this.t('app.pages.deviceListView.modals.deviceLimitPopup.bodyText')}
                                            popupHeaderIconClassName="fas fa-exclamation-triangle fa-alert-icon"
                                            popupSubmitBtnText={this.t('app.pages.deviceListView.modals.deviceLimitPopup.submitText')}
                                        />
                                    ) : (
                                        <ModalDeviceEdit
                                            licenseExceeded={this.state.availDevices === this.props.deviceList.length ? "1" : "0"}
                                            modalOpenMode="NEW"
                                            modalOpenWith="Button"
                                            modalOpenWithClass="transparency btn-large"
                                            modalOpenBtnTitle={this.t('app.pages.deviceListView.buttons.configureDevice')}
                                            modalHeaderTitle={this.t('app.pages.deviceListView.modals.addDevice.title')}
                                            modalClassName="device-edit-form"
                                            modalOnLoadHandler={() => {
                                                this.handleOnRowClicked(null);
                                            }}
                                            modalOnSubmitHandler={(state) => {
                                                this.handleOnAddDevice(state);
                                                setTimeout(() => this.handleOnTableRefresh(), 500);
                                            }} />
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={{size: 4}} sm={{size: 3}} md={{size: 4}} lg={{size: 3}}>
                                <FormGroup>
                                    <Dropdown className={this.state.dropdownOpen ? "transparency-dropdown-after" : "transparency-dropdown"} isOpen={this.state.dropdownOpen} toggle={this.setDropdownToggle}>
                                        <DropdownToggle caret>
                                            {this.t('app.pages.deviceListView.buttons.dropdown.allActions.menuitemdef')}
                                        </DropdownToggle>
                                        <DropdownMenu persist>
                                            <DropDownItems
                                                deviceCount={this.props.deviceList.length}
                                                deviceState={this.props.selectedDeviceState}
                                                clickedDeviceId={this.state.clickedDeviceId}
                                                clickedDeviceName={this.state.clickedDeviceName}
                                                onActivateDeviceHandler={(id) => {
                                                    this.handleOnActivateDevice(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onDeactivateDeviceHandler={(id) => {
                                                    this.handleOnDeactivateDevice(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onRemoveDeviceHandler={(id) => {
                                                    this.handleOnRemoveDevice(id);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onActivateAllDevicesHandler={() => {
                                                    this.handleOnActivateAllDevices();
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                                onDeactivateAllDevicesHandler={() => {
                                                    this.handleOnDeactivateAllDevices();
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }}
                                            />
                                        </DropdownMenu>
                                    </Dropdown>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>

                    <div className="container-fluid device-list-table">
                        <div className="row device-list-table-header">
                            <div className="col-xs-1" style={{width: '2%', textAlign: 'right'}}>#</div>
                            <div className="col-xs-5 col-md-1" style={{marginLeft: '-2px', minWidth: '17vh'}}>{this.t('app.pages.deviceListView.grids.deviceList.cols.deviceType')}</div>
                            <div className="col-xs-4 col-md-1">{this.t('app.pages.deviceListView.grids.deviceList.cols.status')}</div>
                            <div className="col-xs-4 col-md-2">{this.t('app.pages.deviceListView.grids.deviceList.cols.name')}</div>
                            <div className="col-xs-4 col-md-1">{this.t('app.pages.deviceListView.grids.deviceList.cols.deviceNo')}</div>
                            <div className="col-xs-4 col-md-2">{this.t('app.pages.deviceListView.grids.deviceList.cols.imei')}</div>
                            <div className="col-xs-6 col-md-3">{this.t('app.pages.deviceListView.grids.deviceList.cols.email')}</div>
                            <div className="col-xs-4 col-md-1">{this.t('app.pages.deviceListView.grids.deviceList.cols.phone')}</div>
                            <div className="col text-right" style={{marginRight: (this.state.isScrollable ? '10px' : '0px')}}>
                                <i className='fas fa-sync-alt fa-2x fa-table-header-icon' title={this.t('app.pages.deviceListView.hints.iconRefresh')} onClick={() => this.handleOnTableRefresh()}></i>
                            </div>
                            <div style={{display: 'none'}}></div>
                        </div>

                        <div className="device-list-table-body-scrollable">
                            {this.props.deviceList.map((item, index) => {
                                const colClickedClass = (this.state.clickedDeviceId === item.Id) ? "device-list-item-clicked" : ""

                                return (
                                    <div className={"row device-list-table-body " + colClickedClass} key={index} id={"row-id-" + item.Id} onClick={() => this.handleOnRowClicked(item.Id)}>
                                        <div className="col-xs-1" style={{width: '2%', textAlign: 'right'}}>{index + 1}</div>
                                        <div className="col-xs-5 col-md-1" style={{minWidth: '17vh'}}>{item.DeviceTypeName}</div>
                                        <div className={"col-xs-4 col-md-1 col-device-status-" + item.StatusName}>{item.StatusName}</div>
                                        <div className="col-xs-4 col-md-2">{item.Name}</div>
                                        <div className="col-xs-4 col-md-1">
                                            <a className={(this.state.clickedDeviceId === item.Id) ? "col-lnk-clicked" : "col-lnk"}
                                                href={"/dashboard/deviceinfo?item=" + item.DeviceMappingNo} title={this.t('app.pages.deviceListView.hints.linkDevice')}>
                                                {item.DeviceMappingNo}
                                            </a>
                                        </div>
                                        <div className="col-xs-4 col-md-2">{item.IMEI}</div>
                                        <div className="col-xs-6 col-md-3">{item.CustomerDeviceContractEmail}</div>
                                        <div className="col-xs-4 col-md-1">{item.PhoneNo}</div>
                                        <div className="col text-right">
                                            <ModalDeviceEdit
                                                modalOpenMode="EDIT"
                                                modalOpenWith="Icon"
                                                modalOpenWithClass="far fa-edit fa-2x fa-table-body-icon"
                                                modalOpenBtnTitle={this.t('app.pages.deviceListView.hints.iconEdit')}
                                                modalHeaderTitle={this.t('app.pages.deviceListView.modals.addDevice.title2')}
                                                modalClassName="device-edit-form"
                                                modalOnLoadHandler={() => {}}
                                                modalOnSubmitHandler={(state) => {
                                                    this.handleOnEditDevice(state);
                                                    setTimeout(() => this.handleOnTableRefresh(), 500);
                                                }} />
                                        </div>
                                        <div style={{display: 'none'}}>{item.DeviceTypeCode}</div>
                                    </div>
                                )
                            })}
                            {[...Array(this.props.maxDevices - this.props.deviceList.length)].map((item, index) => {
                                const rowId = this.props.deviceList.length
                                return (
                                    <div className="row device-list-table-body" key={rowId + index} onClick={() => this.handleOnRowClicked(null)}>
                                        <div className="col-xs-1" style={{width: '2%', textAlign: 'right'}}>{rowId + index + 1}</div>
                                        <div className="col-xs-5 col-md-1" style={{minWidth: '17vh'}}></div>
                                        <div className="col-xs-4 col-md-1"></div>
                                        <div className="col-xs-4 col-md-2"></div>
                                        <div className="col-xs-4 col-md-1"></div>
                                        <div className="col-xs-4 col-md-2"></div>
                                        <div className="col-xs-6 col-md-3"></div>
                                        <div className="col-xs-4 col-md-1"></div>
                                        <div className="col text-right" style={{marginRight: '4px'}}>
                                            {(index === 0) ? (
                                                <ModalDeviceEdit
                                                    licenseExceeded={this.state.availDevices === this.props.deviceList.length ? "1" : "0"}
                                                    modalOpenMode="NEW"
                                                    modalOpenWith="Icon"
                                                    modalOpenWithClass="far fa-plus-square fa-2x fa-table-body-icon"
                                                    modalOpenBtnTitle={this.t('app.pages.deviceListView.hints.iconAdd')}
                                                    modalHeaderTitle={this.t('app.pages.deviceListView.modals.addDevice.title')}
                                                    modalClassName="device-edit-form"
                                                    modalOnLoadHandler={() => {}}
                                                    modalOnSubmitHandler={(state) => {
                                                        this.handleOnAddDevice(state);
                                                        setTimeout(() => this.handleOnTableRefresh(), 500);
                                                    }} />
                                                ) : (<></>)
                                            }
                                        </div>
                                        <div style={{display: 'none'}}></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerCode: state.session.customer,
    licenseType: state.customer.profile.licenseType,
    licenseSymbol: state.customer.profile.licenseSymbol,
    deviceList: state.customer.deviceList || [],
    maxDevices: state.customer.profile.deviceMax,
    selectedDeviceState: state.customer.deviceSelected.statusName,
    DBResponse: { isError: state.customer.dbStatus.isError, message: state.customer.dbStatus.message }
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSelectDevices: (customerCode, applicationCode) =>
        dispatch(apiSelectDevices(customerCode, applicationCode)),
    apiSelectDeviceTypes: () =>
        dispatch(apiSelectDeviceTypes()),
    apiSelectDevice: (deviceTypeName, statusName, name, deviceMappingNo, imei, deviceEmail, phoneNo, deviceTypeCode) =>
        dispatch(apiSelectDevice(deviceTypeName, statusName, name, deviceMappingNo, imei, deviceEmail, phoneNo, deviceTypeCode)),
    apiInsertDevice: (customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode, deviceName, deviceIMEI, deviceEmail, devicePhone, deviceSimCardType) =>
        dispatch(apiInsertDevice(customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode, deviceName, deviceIMEI, deviceEmail, devicePhone, deviceSimCardType)),
    apiActivateDevice: (customerCode, licenseType, licenseSymbol, deviceId) =>
        dispatch(apiActivateDevice(customerCode, licenseType, licenseSymbol, deviceId)),
    apiDeactivateDevice: (customerCode, licenseType, licenseSymbol, deviceId) =>
        dispatch(apiDeactivateDevice(customerCode, licenseType, licenseSymbol, deviceId)),
    apiRemoveDevice: (customerCode, licenseType, licenseSymbol, deviceId) =>
        dispatch(apiRemoveDevice(customerCode, licenseType, licenseSymbol, deviceId)),
    apiActivateAllDevices: (customerCode, licenseType, licenseSymbol) =>
        dispatch(apiActivateAllDevices(customerCode, licenseType, licenseSymbol)),
    apiDeactivateAllDevices: (customerCode, licenseType, licenseSymbol) =>
        dispatch(apiDeactivateAllDevices(customerCode, licenseType, licenseSymbol)),
    apiEditDevice: (customerDeviceId, customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode, deviceName, deviceIMEI, deviceEmail, devicePhone, deviceSimCardType) =>
        dispatch(apiEditDevice(customerDeviceId, customerCode, licenseType, licenseSymbol, applicationCode, deviceNo, deviceTypeCode, deviceName, deviceIMEI, deviceEmail, devicePhone, deviceSimCardType)),
    apiRefreshDevices: () =>
        dispatch(apiRefreshDevices())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeviceListView));