// System roles
const ROLE_ADMIN       = 'Admin';
const ROLE_MODERATOR   = 'Moderator';
const ROLE_STANDARD    = 'Standard';
const ROLE_SUPERADMIN  = 'SuperAdmin';

export default {
    ROLE_ADMIN,
    ROLE_MODERATOR,
    ROLE_STANDARD,
    ROLE_SUPERADMIN
}