import React, { useState } from 'react'

import { Container, Row, Col, Form, FormGroup, Input, Button, Label, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next';

import { apiSelectFolders, apiTagFolderImage, apiTagFolderImages, apiRemoveFolderImages } from "../../../../middleware/folder/duck/api"
import { apiSelectDeviceItem, apiSelectDeviceImage, apiUnselectDeviceImage, apiUnselectDeviceImageAll } from "../../../../middleware/device/duck/api"

import { ContextMenuTrigger } from "react-contextmenu"

import ModalResourceTag from "./modals/modalResourceTag"
import ModalImageSelected from "./modals/modalImageSelected"

import queryString from 'query-string'
import { getApi } from "../../../../utilities/api"
import * as system from "../../../../utilities/system"

import '../../mainBoard.css'
import './deviceInfo.css'

const deepEqual = require('deep-equal')

const DeviceInfo = (props) => {
    const { t } = useTranslation();

    const [filterByDeviceDropdownOpen, setFilterByDeviceDropdownOpen] = useState(false);
    const toggleFilterByDevice = () => setFilterByDeviceDropdownOpen(prevState => !prevState);

    const [filterByFolderDropdownOpen, setFilterByFolderDropdownOpen] = useState(false);
    const toggleFilterByFolder = () => setFilterByFolderDropdownOpen(prevState => !prevState);

    const [filterByTimelineDropdownOpen, setFilterByTimelineDropdownOpen] = useState(false);
    const toggleFilterByTimeline = () => setFilterByTimelineDropdownOpen(prevState => !prevState);

    const [filterBySelectionDropdownOpen, setFilterBySelectionDropdownOpen] = useState(false);
    const toggleFilterBySelection = () => setFilterBySelectionDropdownOpen(prevState => !prevState);

    const [filterByDevice, setFilterByDevice] = useState(t('app.pages.deviceInfo.buttons.dropdown.allDevices.menuitem1'));
    const [filterByFolder, setFilterByFolder] = useState(t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem2'));
    const [filterByFolderType, setFilterByFolderType] = useState('Default');
    const [filterByTimeline, setFilterByTimeline] = useState(t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem1'));

    const query = queryString.parse(window.location.search);
    const selectedItem = query.item

    const usePrevious = value => {
        const ref = React.useRef();
     
        React.useEffect(() => {
            ref.current = value;
        }, [value]);
     
        return ref.current;
    }
     
    const usePropState = datas => {
        const [dataset, setDataset] = useState(datas);
        const prevDatas = usePrevious(datas);
     
        const handleChangeDataset = data => setDataset(data);
     
        React.useEffect(() => {
            if (!deepEqual(datas, prevDatas)) {
                setDataset(datas);
                setPageInitForm()
            }
        }, [datas, prevDatas]);
     
        return [
            dataset,
            handleChangeDataset
        ]
    }

    const [componentState, setComponentState] = usePropState({
        searchQuery: "",
        searchData: []
    })

    const isSelectedAllDevicesMode = () => {
        return ((props.selectedDeviceNo === t('app.layout.sidebar.itemALL')) || (props.selectedDeviceNo === "") || (props.selectedDeviceNo === undefined) ||
                (selectedItem === t('app.layout.sidebar.itemALL')) || (selectedItem === ""))
    }

    const handleOnSelectFolders = () => {
        props.apiSelectFolders(
            props.customerCode,
            null,
            props.login
        )
    }

    const handleOnTagResource = (id, dstFolderName, dstFolderType) => {
        props.apiTagFolderImage(
            props.customerCode,
            (filterByFolder === t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem2') ? "In-Queue" : filterByFolder),
            filterByFolderType || "Default",
            dstFolderName,
            dstFolderType,
            id,
            props.login
        )
        setTimeout(() => handleOnDeviceDataSearchQuery(componentState.searchQuery), 1000)
    }

    const handleOnRemoveResource = (id) => {
        props.apiRemoveFolderImages(
            props.customerCode,
            (filterByFolder === t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem2') ? "In-Queue" : filterByFolder),
            filterByFolderType || "Default",
            [id]
        )
        setTimeout(() => handleOnDeviceDataSearchQuery(componentState.searchQuery), 1000)
    }

    const handleOnDeviceDataSearch = (e) => {
        const { name, value } = e.target;
        setComponentState({ ...componentState, [name]: value });
        handleOnDeviceDataSearchQuery(value)
    }

    const handleOnDeviceDataSearchQuery = (searchText = "") => {
        let searchFolderName = filterByFolder
        if (searchFolderName === t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem2')) {
            searchFolderName = "In-Queue"
        }
        if (searchFolderName === t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem1')) {
            searchFolderName = ""
        }

        let searchTimeline = filterByTimeline
        if (searchTimeline === t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem1')) {
            searchTimeline = "td"
        }
        else if (searchTimeline === t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem2')) {
            searchTimeline = "tm"
        }
        else if (searchTimeline === t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem3')) {
            searchTimeline = "lm"
        }
        else if (searchTimeline === t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem4')) {
            searchTimeline = "l3m"
        }
        else if (searchTimeline === t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem5')) {
            searchTimeline = "all"
        }
        else searchTimeline = "td"

        let searchDevice = props.selectedDeviceNo || ""
        if (searchDevice === t('app.layout.sidebar.itemALL')) {
            searchDevice = ""
        }

        let searchDeviceFilter = filterByDevice || ""
        if (searchDeviceFilter === t('app.pages.deviceInfo.buttons.dropdown.allDevices.menuitem1')) {
            searchDeviceFilter = ""
        }
        if (isSelectedAllDevicesMode()) {
            searchDevice = searchDeviceFilter
        }

        const fullQuery="query=" + searchText + "&folder=" + searchFolderName + "&device=" + searchDevice + "&user=" + props.login + "&time=" + searchTimeline

        fetch(getApi(`/api/v1/device/${props.customerCode}/search?${fullQuery}`))
            .then((response) => response.json())
            .then((data) => {
                setComponentState({ ...componentState, searchData: data, searchQuery: searchText })
            })
    }

    const handleOnClickRefresh = () => {
        handleOnDeviceDataSearchQuery(componentState.searchQuery)
    }

    const handleOnSelectAllImages = () => {
        let imageList = document.querySelectorAll("input[data-name^=cb-image-]")
        if (imageList && (imageList.length > 0)) {
            for (let i=0 ; i < imageList.length ; i++) {
                let imageId = imageList[i].getAttribute("data-id");
                if (imageId !== "") {
                    props.apiSelectDeviceImage(parseInt(imageId))
                }
            }
        }
    }

    const handleOnUnselectAllImages = () => {
        let imageList = document.querySelectorAll("input[data-name^=cb-image-]")
        if (imageList && (imageList.length > 0)) {
            for (let i=0 ; i < imageList.length ; i++) {
                let imageId = imageList[i].getAttribute("data-id");
                if (imageId !== "") {
                    props.apiUnselectDeviceImage(parseInt(imageId))
                }
            }
        }
    }

    const handleRemoveAllSelectedPhotos = (tag, tagType) => {
        props.apiRemoveFolderImages(
            props.customerCode,
            (filterByFolder === t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem2') ? "In-Queue" : filterByFolder),
            filterByFolderType || "Default",
            props.selectedImageList
        )
        props.apiUnselectDeviceImageAll()
        setTimeout(() => handleOnDeviceDataSearchQuery(componentState.searchQuery), 1000)
    }

    const handleTagAllSelectedPhotos = (tag, tagType) => {
        props.apiTagFolderImages(
            props.customerCode,
            (filterByFolder === t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem2') ? "In-Queue" : filterByFolder),
            filterByFolderType || "Default",
            tag,
            tagType,
            props.selectedImageList,
            props.login
        )
        props.apiUnselectDeviceImageAll()
        setTimeout(() => handleOnDeviceDataSearchQuery(componentState.searchQuery), 1000)
    }

    const handleDownloadAllSelectedPhotos = (tag, tagType) => {
        let imageList = document.querySelectorAll("a[data-name^=a-image-download-]")
        if (imageList && (imageList.length > 0)) {
            for (let i=0 ; i < imageList.length ; i++) {
                let imageId = imageList[i].getAttribute("data-id");
                if (props.selectedImageList.indexOf(parseInt(imageId)) > -1) {
                    imageList[i].click()
                }
            }
        }
    }

    const setPageInitForm = () => {
        handleOnSelectFolders()
        props.apiSelectDeviceItem(selectedItem)
    }

    return(
        <>
            <Form className="device-image-list-view-form">                
                <Container fluid="xs" style={{paddingLeft: '0px'}}>
                    <Row>
                        {isSelectedAllDevicesMode() ? (
                            <Label className="device-item-view-title">{t('app.pages.deviceInfo.title')}</Label>
                        ) : (
                            <Label className="device-item-view-title">{t('app.pages.deviceInfo.title2')} {props.selectedDeviceNo || selectedItem}</Label>
                        )}
                    </Row>
                    <Row>
                        <Col xs="3">
                            <FormGroup>
                                <Label>{t('app.pages.deviceInfo.labels.searchCriteria')}</Label>
                                <Input className="transparency" type="text" name="searchQuery" placeholder={isSelectedAllDevicesMode() ? t('app.pages.deviceInfo.placeholders.searchQueryAll') : t('app.pages.deviceInfo.placeholders.searchQuery')}
                                       onChange={handleOnDeviceDataSearch} title={t('app.pages.deviceInfo.hints.searchQuery')} autoFocus>
                                </Input>
                            </FormGroup>
                        </Col>
                        {isSelectedAllDevicesMode() ? (
                            <Col xs="auto">
                                <FormGroup>
                                    <Label>{t('app.pages.deviceInfo.labels.filterByDevice')}</Label>
                                    <Dropdown className={filterByDeviceDropdownOpen ? "transparency-dropdown-after" : "transparency-dropdown"} isOpen={filterByDeviceDropdownOpen} toggle={toggleFilterByDevice}>
                                        <DropdownToggle caret className="btn-width-small">
                                            {filterByDevice}
                                        </DropdownToggle>
                                        <DropdownMenu
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                        ...data,
                                                        styles: {
                                                            ...data.styles,
                                                            overflowY: 'auto',
                                                            maxHeight: '260px',
                                                        },
                                                        };
                                                    },
                                                },
                                            }}
                                            persist>
                                            <DropdownItem header>{t('app.pages.deviceInfo.buttons.dropdown.allDevices.header')}</DropdownItem>
                                            <DropdownItem onClick={() => {
                                                setFilterByDevice(t('app.pages.deviceInfo.buttons.dropdown.allDevices.menuitem1'))
                                            }} className="font-green">{t('app.pages.deviceInfo.buttons.dropdown.allDevices.menuitem1')}
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            {props.deviceList.map((item, index) => {
                                                return (
                                                    <DropdownItem onClick={() => {
                                                        setFilterByDevice(item.deviceNo)
                                                    }} key={index}>{item.deviceNo}</DropdownItem>
                                                )
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </FormGroup>
                            </Col>
                        ) : (
                            <></>
                        )}
                        <Col xs="auto">
                            <FormGroup>
                                <Label>{t('app.pages.deviceInfo.labels.filterByFolder')}</Label>
                                <Dropdown className={filterByFolderDropdownOpen ? "transparency-dropdown-after" : "transparency-dropdown"} isOpen={filterByFolderDropdownOpen} toggle={toggleFilterByFolder}>
                                    <DropdownToggle caret>
                                        {filterByFolder}
                                    </DropdownToggle>
                                    <DropdownMenu persist>
                                        <DropdownItem header>{t('app.pages.deviceInfo.buttons.dropdown.allFolders.header')}</DropdownItem>
                                        {isSelectedAllDevicesMode() ? (
                                            <>
                                                <DropdownItem onClick={() => {
                                                    setFilterByFolder(t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem1'))
                                                    setFilterByFolderType('')
                                                }} className="font-green">{t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem1')}</DropdownItem>
                                                <DropdownItem divider />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <DropdownItem onClick={() => {
                                            setFilterByFolder(t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem2'))
                                            setFilterByFolderType('Default')
                                        }}>{t('app.pages.deviceInfo.buttons.dropdown.allFolders.menuitem2')}</DropdownItem>
                                        {(props.folderList.length !== 0) ? (
                                            <DropdownItem divider />
                                        ) : (<></>)}
                                        {props.folderList.map((item, index) => {
                                            return (
                                                <DropdownItem onClick={() => {
                                                        setFilterByFolder(item.name)
                                                        setFilterByFolderType(item.folderType)
                                                    }
                                                } key={index}>{item.name}</DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </FormGroup>
                        </Col>
                        <Col xs="auto">
                            <FormGroup>
                                <Label>{t('app.pages.deviceInfo.labels.filterByTimeline')}</Label>
                                <Dropdown className={filterByTimelineDropdownOpen ? "transparency-dropdown-after" : "transparency-dropdown"} isOpen={filterByTimelineDropdownOpen} toggle={toggleFilterByTimeline}>
                                    <DropdownToggle caret>
                                        {filterByTimeline}
                                    </DropdownToggle>
                                    <DropdownMenu persist>
                                        <DropdownItem header>{t('app.pages.deviceInfo.buttons.dropdown.allTimelines.header')}</DropdownItem>
                                        <DropdownItem onClick={() => setFilterByTimeline(t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem1'))}>{t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem1')}</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => setFilterByTimeline(t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem2'))}>{t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem2')}</DropdownItem>
                                        <DropdownItem onClick={() => setFilterByTimeline(t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem3'))}>{t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem3')}</DropdownItem>
                                        <DropdownItem onClick={() => setFilterByTimeline(t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem4'))}>{t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem4')}</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => setFilterByTimeline(t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem5'))}>{t('app.pages.deviceInfo.buttons.dropdown.allTimelines.menuitem5')}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </FormGroup>
                        </Col>
                        <Col xs="auto">
                            <FormGroup>
                                <Label>{t('app.pages.deviceInfo.labels.selection')}</Label>
                                <Dropdown className={filterBySelectionDropdownOpen ? "transparency-dropdown-after" : "transparency-dropdown"} isOpen={filterBySelectionDropdownOpen} toggle={toggleFilterBySelection}>
                                    <DropdownToggle caret>
                                        {t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitemDef')}
                                    </DropdownToggle>
                                    <DropdownMenu persist>
                                        <DropdownItem header>{t('app.pages.deviceInfo.buttons.dropdown.allActions.header')}</DropdownItem>
                                        {(componentState.searchData.length === 0) ? (
                                            <DropdownItem disabled>{t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem1')}</DropdownItem>
                                        ) : (
                                            <DropdownItem onClick={() => handleOnSelectAllImages()}>{t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem1')}</DropdownItem>
                                        )}
                                        {(props.selectedImageList.length === 0) ? (
                                            <DropdownItem disabled>{t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem2')}</DropdownItem>
                                        ) : (
                                            <DropdownItem onClick={() => handleOnUnselectAllImages()}>{t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem2')}</DropdownItem>
                                        )}
                                        <DropdownItem divider />
                                        {(props.selectedImageList.length === 0) ? (
                                            <>
                                                <DropdownItem disabled>{t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem3')}</DropdownItem>
                                                {([system.roles.ROLE_ADMIN, system.roles.ROLE_MODERATOR].includes(props.userRole)) ? (
                                                    <>
                                                        <DropdownItem disabled>{t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem4')}</DropdownItem>
                                                        <DropdownItem divider />
                                                        <DropdownItem disabled>{t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem5')}</DropdownItem>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ): (
                                            <>
                                                <DropdownItem>
                                                    <ModalImageSelected
                                                        popupSize="md"
                                                        popupOpenWith="Link"
                                                        popupOpenWithClass=""
                                                        popupHeaderTitle={t('app.pages.deviceInfo.modals.tagSelected.title')}
                                                        popupOpenBtnTitle={t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem3')}
                                                        popupBodyText={t('app.pages.deviceInfo.modals.tagSelected.bodyText')}
                                                        popupHeaderIconClassName="fas fa-tags"
                                                        popupSubmitBtnText={t('app.pages.deviceInfo.modals.tagSelected.submitText')}
                                                        popupCancelBtnText={t('app.pages.deviceInfo.modals.tagSelected.cancelText')}
                                                        popupMediaTags={props.folderList || []}
                                                        popupMediaCurrentTag={filterByFolder}                            
                                                        popupOnSubmitHandler={(tag, tagType) => {
                                                            handleTagAllSelectedPhotos(tag, tagType)
                                                            setTimeout(() => handleOnDeviceDataSearchQuery(componentState.searchQuery), 1000)
                                                        }} />
                                                </DropdownItem>
                                                {([system.roles.ROLE_ADMIN, system.roles.ROLE_MODERATOR].includes(props.userRole)) ? (
                                                    <>
                                                        <DropdownItem className="font-red">
                                                            <ModalImageSelected
                                                                popupSize="md"
                                                                popupOpenWith="Link"
                                                                popupOpenWithClass=""
                                                                popupHeaderTitle={t('app.pages.deviceInfo.modals.removeSelected.title')}
                                                                popupOpenBtnTitle={t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem4')}
                                                                popupBodyText={t('app.pages.deviceInfo.modals.removeSelected.bodyText')}
                                                                popupHeaderIconClassName="fas fa-trash-alt"
                                                                popupSubmitBtnText={t('app.pages.deviceInfo.modals.removeSelected.submitText')}
                                                                popupCancelBtnText={t('app.pages.deviceInfo.modals.removeSelected.cancelText')}
                                                                popupMediaTags={[]}
                                                                popupMediaCurrentTag={""}
                                                                popupOnSubmitHandler={(tag, tagType) => {
                                                                    handleRemoveAllSelectedPhotos(tag, tagType)
                                                                    setTimeout(() => handleOnDeviceDataSearchQuery(componentState.searchQuery), 1000)
                                                                }} />
                                                        </DropdownItem>
                                                        <DropdownItem divider />
                                                        <DropdownItem className="font-green">
                                                            <ModalImageSelected
                                                                popupSize="md"
                                                                popupOpenWith="Link"
                                                                popupOpenWithClass=""
                                                                popupHeaderTitle={t('app.pages.deviceInfo.modals.dnldSelected.title')}
                                                                popupOpenBtnTitle={t('app.pages.deviceInfo.buttons.dropdown.allActions.menuitem5')}
                                                                popupBodyText={t('app.pages.deviceInfo.modals.dnldSelected.bodyText')}
                                                                popupHeaderIconClassName="fas fa-download"
                                                                popupSubmitBtnText={t('app.pages.deviceInfo.modals.dnldSelected.submitText')}
                                                                popupCancelBtnText={t('app.pages.deviceInfo.modals.dnldSelected.cancelText')}
                                                                popupMediaTags={[]}
                                                                popupMediaCurrentTag={""}
                                                                popupOnSubmitHandler={(tag, tagType) => {
                                                                    handleDownloadAllSelectedPhotos(tag, tagType)
                                                                }} />
                                                        </DropdownItem>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </FormGroup>
                        </Col>
                        <Col xs="auto">
                            <FormGroup>
                                <Label style={{opacity: '0'}}>.</Label>
                                <div>
                                    <Button className="transparency" type="button" onClick={handleOnClickRefresh}>{componentState.searchData.length > 0 ? `${t('app.pages.deviceInfo.buttons.filter')} (${componentState.searchData.length})` : t('app.pages.deviceInfo.buttons.filter')}</Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </Form>

            {([system.roles.ROLE_ADMIN, system.roles.ROLE_MODERATOR].includes(props.userRole)) ? (
                <div className="device-item-view-scrollable">
                    {componentState.searchData.map((item, index) => {
                        return (
                            <ModalResourceTag
                                key={index}
                                modalOpenWith="Media"
                                modalHeaderTitle={t('app.pages.deviceInfo.modals.modalResourceTag.title')}
                                modalHeaderTitle2={t('app.pages.deviceInfo.modals.modalResourceTag.title2')}
                                modalClassName="resource-tag-form"
                                modalMediaPath={item.fileRelativePath}
                                modalMediaFilename={item.fileName}
                                modalMediaFilesize={item.fileSizeInBytes}
                                modalMediaKey={item.resourceId}
                                modalMediaDevice={item.deviceNo}
                                modalMediaTags={props.folderList || []}
                                modalMediaCurrentTag={filterByFolder}
                                modalOnLoadHandler={() => {}}
                                modalOnSubmitHandler={(action, tag, tagType) => {
                                    if (action === "TAG") {
                                        handleOnTagResource(item.resourceId, tag, tagType);
                                    } else if (action === "REMOVE") {
                                        handleOnRemoveResource(item.resourceId);
                                    }
                                }}
                            />
                        )
                    })}
                </div>
            ) : (
                <ContextMenuTrigger id={"CM_" + props.modalMediaKey}>
                    <div className="device-item-view-scrollable">
                        {componentState.searchData.map((item, index) => {
                            return (
                                <ModalResourceTag
                                    key={index}
                                    modalOpenWith="Media"
                                    modalHeaderTitle={t('app.pages.deviceInfo.modals.modalResourceTag.title')}
                                    modalHeaderTitle2={t('app.pages.deviceInfo.modals.modalResourceTag.title2')}
                                    modalClassName="resource-tag-form"
                                    modalMediaPath={item.fileRelativePath}
                                    modalMediaFilename={item.fileName}
                                    modalMediaFilesize={item.fileSizeInBytes}
                                    modalMediaKey={item.resourceId}
                                    modalMediaDevice={item.deviceNo}
                                    modalMediaTags={props.folderList || []}
                                    modalMediaCurrentTag={filterByFolder}
                                    modalOnLoadHandler={() => {}}
                                    modalOnSubmitHandler={(action, tag, tagType) => {
                                        if (action === "TAG") {
                                            handleOnTagResource(item.resourceId, tag, tagType);
                                        } else if (action === "REMOVE") {
                                            handleOnRemoveResource(item.resourceId);
                                        }
                                    }}
                                />
                            )
                        })}
                    </div>
                </ContextMenuTrigger>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerCode: state.session.customer,
    login: state.session.user,
    folderList: state.folder.folderList || [],
    selectedDeviceNo: state.device.selectedDeviceNo,
    selectedImageList: state.device.deviceSelectedImageList || [],
    deviceList: state.customer.deviceSummaryList || [],
    userRole: state.session.profile.roleName || 'Standard'
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSelectFolders: (customerCode, folderType, folderOwner) =>
        dispatch(apiSelectFolders(customerCode, folderType, folderOwner)),
    apiSelectDeviceItem: (name) =>
        dispatch(apiSelectDeviceItem(name)),
    apiTagFolderImage: (customerCode, srcFolder, srcFolderType, dstFolder, dstFolderType, itemResourceId, itemUser) =>
        dispatch(apiTagFolderImage(customerCode, srcFolder, srcFolderType, dstFolder, dstFolderType, itemResourceId, itemUser)),
    apiTagFolderImages: (customerCode, srcFolder, srcFolderType, dstFolder, dstFolderType, itemResourceList, itemUser) =>
        dispatch(apiTagFolderImages(customerCode, srcFolder, srcFolderType, dstFolder, dstFolderType, itemResourceList, itemUser)),
    apiRemoveFolderImages: (customerCode, itemFolder, itemFolderType, itemResourceList) =>
        dispatch(apiRemoveFolderImages(customerCode, itemFolder, itemFolderType, itemResourceList)),
    apiSelectDeviceImage: (id) =>
        dispatch(apiSelectDeviceImage(id)),
    apiUnselectDeviceImage: (id) =>
        dispatch(apiUnselectDeviceImage(id)),
    apiUnselectDeviceImageAll: () =>
        dispatch(apiUnselectDeviceImageAll())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfo);