import React from 'react'
import { Table } from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from 'react-i18next'

import { apiSelectFolders, apiSelectFolder, apiDeleteFolder, apiRefreshFolders } from "../../../../middleware/folder/duck/api"

import FolderFormView from './folderFormView'
import PopupConfirm from '../../../ui/popupConfirm'
import PopupAlert from '../../../../components/ui/popupAlert'

import { isTableContentScrollable } from '../../../../utilities/dom'

import '../../mainBoard.css'
import './folderListView.css'


class FolderListView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            clickedFolderId: null
        }
    }

    t = (key) => {
        return this.props.t(key)
    }

    componentDidMount() {
        this.handleOnTableRefresh()
    }

    isDBResponseReceived = () => {
        return (this.props.DBResponse.message !== undefined)
    }

    handleOnSelectFolders = () => {
        this.props.apiSelectFolders(this.props.customerCode, null, this.props.login)
    }

    handleOnRowClicked = (id) => {
        const row = document.getElementById("row-id-" + id)
        if (row !== null) {
            if (row.childNodes) {
                this.props.apiSelectFolder(
                    row.childNodes[1].innerText,
                    row.childNodes[2].innerText,
                    row.childNodes[3].innerText,
                    row.childNodes[4].innerText,
                    id)
                this.setState({clickedFolderId: id})
            }
        }
    }

    handleOnRowDelete = (id) => {
        console.log('Removing folder with id = ' + id)
        this.props.apiDeleteFolder(id)
    }

    handleOnTableRefresh = () => {
        console.log('Refreshing the list of folders...')
        this.handleOnSelectFolders()
        this.setState({clickedFolderId: null})
        this.props.apiRefreshFolders()
    }

    decodeFolderType = (folderType) => {
        if (folderType === "Default") {
            return this.t('app.pages.folderListView.buttons.dropdown.selectFolderType.menuitemdef')
        }
        else {
            return folderType
        }
    }

    render() {
        const isScrollable = isTableContentScrollable('table');

        return (
            <>
                <FolderFormView onRefreshTableHandler={() => {
                    if (!this.isDBResponseReceived()) {
                        this.handleOnTableRefresh()
                    }
                }} />

                {(this.isDBResponseReceived()) ? (
                    <PopupAlert
                        key={this.props.DBResponse.message}
                        popupOpenToggle={true}
                        popupSize="md"
                        popupOpenWith="None"
                        popupOpenWithClass=""
                        popupHeaderTitle={ this.props.DBResponse.isError ? this.t('app.common.modals.popupAlert.title2') : this.t('app.common.modals.popupAlert.title') }
                        popupOpenBtnTitle=""
                        popupBodyText={this.props.DBResponse.message}
                        popupHeaderIconClassName={ this.props.DBResponse.isError ? "fas fa-exclamation-triangle font-red" : "fas fa-check-circle font-green" }
                        popupSubmitBtnText={this.t('app.common.modals.popupAlert.cancelText')}
                        popupEscapeListener={false}
                        popupOnSubmitHandler={() => {
                            this.handleOnTableRefresh()
                        }}
                    />
                ): (
                    <></>
                )}

                <Table className="fixed-header">
                    <thead>
                        <tr>
                            <th scope="row" style={{width: '40px'}}>#</th>
                            <th style={{width: '20%'}}>{this.t('app.pages.folderListView.grids.folderList.cols.folderType')}</th>
                            <th style={{width: '20%'}}>{this.t('app.pages.folderListView.grids.folderList.cols.name')}</th>
                            <th style={{width: '22%'}}>{this.t('app.pages.folderListView.grids.folderList.cols.displayName')}</th>
                            <th>{this.t('app.pages.folderListView.grids.folderList.cols.description')}</th>
                            <th style={{textAlign: 'right', fontSize: '1em !important', paddingRight: '2%'}} onClick={() => this.handleOnTableRefresh()}>
                                <i className='fas fa-sync-alt fa-2x fa-table-header-icon' title={this.t('app.pages.folderListView.hints.iconRefresh')}></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.folderList.map((item, index) => {
                            const colClickedClass = (this.state.clickedFolderId === item.id) ? "table-col-clicked" : ""
                            return (
                                <tr key={index} id={"row-id-" + item.id} onClick={() => this.handleOnRowClicked(item.id)}>
                                    <th scope="row" className={colClickedClass}>{index + 1}</th>
                                    <td style={{width: '20%'}} className={colClickedClass}>{this.decodeFolderType(item.folderType)}</td>
                                    <td style={{width: '20%'}} className={colClickedClass}>{item.name}</td>
                                    <td style={{width: '22%'}} className={colClickedClass}>{item.displayName}</td>
                                    <td className={colClickedClass}>{item.description}</td>
                                    <td className={(isScrollable ? "table-icon-last-col-scrollable" : "table-icon-last-col-non-scrollable") + " " + colClickedClass}>
                                        <PopupConfirm
                                            popupSize="md"
                                            popupOpenWith="Icon"
                                            popupOpenWithClass="fas fa-trash-alt fa-2x fa-table-icon"
                                            popupHeaderTitle={this.t('app.pages.folderListView.modals.removeFolder.title') + " [" + item.name + "]"}
                                            popupOpenBtnTitle={this.t('app.pages.folderListView.hints.iconTrash')}
                                            popupBodyText={this.t('app.pages.folderListView.modals.removeFolder.bodyText')}
                                            popupHeaderIconClassName="fas fa-trash-alt"
                                            popupSubmitBtnText={this.t('app.pages.folderListView.modals.removeFolder.submitText')}
                                            popupCancelBtnText={this.t('app.pages.folderListView.modals.removeFolder.cancelText')}
                                            popupOnSubmitHandler={() => {
                                                this.handleOnRowDelete(item.id);
                                            }} />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerCode: state.session.customer,
    login: state.session.user,
    folderId: state.folder.folderId,
    folderType: state.folder.folderType,
    folderList: state.folder.folderList,
    DBResponse: { isError: state.folder.dbStatus.isError, message: state.folder.dbStatus.message }
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSelectFolders: (customerCode, folderType, folderOwner) =>
        dispatch(apiSelectFolders(customerCode, folderType, folderOwner)),
    apiSelectFolder: (folderType, folderName, folderDisplayName, folderDescription, folderId) =>
        dispatch(apiSelectFolder(folderType, folderName, folderDisplayName, folderDescription, folderId)),
    apiDeleteFolder: (folderId) =>
        dispatch(apiDeleteFolder(folderId)),
    apiRefreshFolders: () =>
        dispatch(apiRefreshFolders())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FolderListView));