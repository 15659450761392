import React, { useState } from 'react'
import { Container, Row, Col, Form, FormGroup, Button, Input, Label, Media, Progress } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next';

import { apiLoadLicenseUsage } from "../../../../middleware/customer/duck/api"

import '../../mainBoard.css'
import './customerLicenseView.css'

const deepEqual = require('deep-equal')

const CustomerLicenseView = (props) => {
    const { t } = useTranslation();

    const usePrevious = value => {
        const ref = React.useRef();
     
        React.useEffect(() => {
            ref.current = value;
        }, [value]);
     
        return ref.current;
    }
     
    const usePropState = datas => {
        const [dataset, setDataset] = useState(datas);
        const prevDatas = usePrevious(datas);
     
        const handleChangeDataset = data => setDataset(data);
     
        React.useEffect(() => {
            if (!deepEqual(datas, prevDatas)) {
                setDataset(datas);
                setPageInitForm()
            }
        }, [datas, prevDatas]);
     
        return [
            dataset,
            handleChangeDataset
        ]
    }

    usePropState({})

    const handleLoadLicenseUsage = () => {
        props.apiLoadLicenseUsage(props.customerCode, props.licenseType, props.licenseSymbol, 'PG')
    }

    const handleOnTableRefresh = () => {
        console.log('Reloading customer license usage...')
        handleLoadLicenseUsage()
    }

    const setPageInitForm = () => {
        handleLoadLicenseUsage()
    }

    return(
        <Form className="customer-license-view-form">
            <Container fluid="md">
                <Row>
                    <Col style={{paddingLeft: '0px'}}>
                        <Label className="form-view-title">{t('app.pages.customerLicenseView.headlines.customerProfile')}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{size: 4}} sm={{size: 3}} md={{size: 2}} style={{paddingLeft: '0px'}}>
                        <Media object src={require('../../../../resources/images/avatars/user-profile2.png')} className="transparency customer-profile-avatar"></Media>
                    </Col>
                    <Col sm={{size: 6, offset: 1}} md={{size: 9, offset: 0}} style={{paddingLeft: '0px'}}>
                        <Row className="v-space-16px">
                            <Col xs={{size: 11}} md={{size: 4, offset: 1}} lg={{size: 3, offset: 0}}>
                                <Input className="transparency" type="text" name="customerType" value={props.customerTypeName + " (" + props.customerType + ") - " + props.customerSymbol} readOnly></Input>
                            </Col>
                        </Row>
                        <Row className="v-space-16px">
                            <Col xs={{size: 11}} md={{size: 4, offset: 1}} lg={{size: 6, offset: 0}}>
                                <Input className="transparency" type="text" name="customerGroup" value={props.customerName} readOnly></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{size: 11}} md={{size: 4, offset: 1}} lg={{size: 6, offset: 0}}>
                                <Input className="transparency" type="text" name="customerName" value={props.customerWebSite} readOnly></Input>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="customer-license-view-scrollable">
                    <Row>
                        <Col style={{paddingLeft: '0px'}}>
                            <Label className="form-view-title">{t('app.pages.customerLicenseView.headlines.licenseInformation')}</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: 11}} sm={{size: 3}} md={{size: 4}}>
                            <FormGroup>
                                <Label>{t('app.pages.customerLicenseView.labels.activeLicense')}</Label>
                                <Input className="transparency emphased v-space-responsive font-weight-bold" type="text" name="licenseTypeName" value={props.licenseSymbol + " - " + props.licenseTypeName} readOnly></Input>
                            </FormGroup>
                        </Col>
                        <Col xs={{size: 5}} sm={{size: 3}} md={{size: 2}}>
                            <FormGroup>
                                <Label>{t('app.pages.customerLicenseView.labels.licenseFrom')}</Label>
                                <Input className="transparency v-space-responsive font-weight-bold text-right" type="text" name="licenseFrom" value={props.licenseFrom} readOnly></Input>
                            </FormGroup>
                        </Col>
                        <Col xs={{size: 5, offset: 1}} sm={{size: 3}} md={{size: 2, offset: 0}}>
                            <FormGroup>
                                <Label>{t('app.pages.customerLicenseView.labels.licenseTo')}</Label>
                                <Input className="transparency v-space-responsive font-weight-bold text-right" type="text" name="licenseTo" value={props.licenseTo !== null ? props.licenseTo : ""} readOnly></Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: 5}} sm={{size: 3}} md={{size: 2}}>
                            <FormGroup>
                                <Label>{t('app.pages.customerLicenseView.labels.currentDevices')}</Label>
                                <Input className="transparency emphased v-space-responsive font-weight-bold text-right" type="text" name="storageConnectedDeviceCount" value={props.storageConnectedDeviceCount} readOnly></Input>
                            </FormGroup>
                        </Col>
                        <Col xs={{size: 5, offset: 1}} sm={{size: 3, offset: 0}} md={{size: 2}}>
                            <FormGroup>
                                <Label>{t('app.pages.customerLicenseView.labels.maxAvailDevices')}</Label>
                                <Input className="transparency emphased v-space-responsive font-weight-bold text-right" type="text" name="maxDevice" value={props.maxDevice} readOnly></Input>
                            </FormGroup>
                        </Col>
                        <Col xs={{size: 5}} sm={{size: 3}} md={{size: 2}}>
                            <FormGroup>
                                <Label>{t('app.pages.customerLicenseView.labels.maxAvailStorage')}</Label>
                                <Input className="transparency v-space-responsive font-weight-bold text-right" type="text" name="storageSize" value={props.storageSize} readOnly></Input>
                            </FormGroup>
                        </Col>
                        <Col xs={{size: 5, offset: 1}} sm={{size: 2, offset: 0}} md={{size: 2}}>
                            <FormGroup>
                                <Label>{t('app.pages.customerLicenseView.labels.storageUnit')}</Label>
                                <Input className="transparency v-space-responsive font-weight-bold text-right" type="text" name="storageUnit" value={props.storageUnit} readOnly></Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{size: 11}} style={{paddingLeft: '0px'}}>
                            <Label className="form-view-title">{t('app.pages.customerLicenseView.headlines.licenseStorageUse')}</Label>
                            <div className="text-center">{props.storageUsePcnt + "% [" + props.storageUse + " " + props.storageUnit + "]"}</div>
                            <Progress value={props.storageUsePcnt} max={100} color={props.storageProgressColor} />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '10px'}}>
                        <Col xs={{size: 11}} lg={{size: 4}} style={{paddingLeft: '0px'}}>
                            <Button className="transparency btn-large" type="button" title={t('app.pages.customerLicenseView.hints.refreshLicense')} onClick={handleOnTableRefresh}>{t('app.pages.customerLicenseView.buttons.refresh')}</Button>&nbsp;&nbsp;
                            <Button className="transparency btn-large cur-default" type="button" title={t('app.pages.customerLicenseView.hints.changeLicense')} disabled style={{display: 'none'}}>{t('app.pages.customerLicenseView.buttons.change')}</Button>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Form>
    );
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    customerCode: state.session.customer,

    minDevice: state.customer.profile.deviceMin,
    maxDevice: state.customer.profile.deviceMax,
    storageSize: state.customer.profile.storageSize,
    storageUnit: state.customer.profile.storageUnit,
    customerTypeName: state.customer.profile.customerTypeName,
    customerType: state.customer.profile.customerType,
    customerSymbol: state.customer.profile.customerSymbol,
    customerName: state.customer.profile.displayName,
    customerWebSite: state.customer.profile.website,
    customerEmail: state.customer.profile.email,
    licenseSymbol: state.customer.profile.licenseSymbol,
    licenseType: state.customer.profile.licenseType,
    licenseTypeName: state.customer.profile.licenseTypeName,
    licenseFrom: state.customer.profile.licenseFrom,
    licenseTo: state.customer.profile.licenseTo,
    storageItemsCount: state.customer.license.storageItemsCount,
    storageConnectedDeviceCount: state.customer.license.storageConnectedDeviceCount || 0,
    storageUse: state.customer.license.storageUse,
    storageAvail: state.customer.license.storageAvail,
    storageSoft: state.customer.license.storageSoft,
    storageHard: state.customer.license.storageHard,
    storageUsePcnt: state.customer.license.storageUsePcnt,
    storageUseSoftPcnt: state.customer.license.storageUseSoftPcnt,
    storageUseHardPcnt: state.customer.license.storageUseHardPcnt,
    storageExceededWarnL1: state.customer.license.storageExceededWarnL1,
    storageExceededWarnL2: state.customer.license.storageExceededWarnL2,
    storageProgressColor: state.customer.license.storageProgressColor || "success"
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiLoadLicenseUsage: (customerCode, licenseTypeCode, licenseCode, applicationCode) =>
        dispatch(apiLoadLicenseUsage(customerCode, licenseTypeCode, licenseCode, applicationCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLicenseView);