import React from 'react';
import { Row, Col, FormGroup } from "reactstrap";
import { useTranslation } from 'react-i18next';

import './licenseCards.css';
import LicenseCardItem from './licenseCardItem';
import { default as licenseTypes } from '../../utilities/system/licenseTypes';

const LicenseCards = (props) => {
    const { t } = useTranslation();

    const licenseOfferConfig = {
        licenseOfferSelectionText: t('app.pages.licenseCards.labels.buy'),
        licenseOfferSelectedText: t('app.pages.licenseCards.labels.selected'),
        licenseOfferPeriodText: t('app.pages.licenseCards.labels.period'),
        licenseOfferBkgImage: {}
    }

    const initCards = () => {
        licenseOfferConfig["licenseOfferBkgImage"][licenseTypes.REG_LICENSE_BASIC] = 'images/img-bg-card-09-2.jpg'
        licenseOfferConfig["licenseOfferBkgImage"][licenseTypes.REG_LICENSE_ADVANCED] = 'images/img-bg-card-08-2.jpg'
        licenseOfferConfig["licenseOfferBkgImage"][licenseTypes.REG_LICENSE_PROFESSIONAL] = 'images/img-bg-card-07-2.jpg'
    }

    initCards()
    
    return (
        <FormGroup>
            <Row className={'license-container license-wrapper' + ((sessionStorage.getItem('registerSelectedLicense') || localStorage.getItem('registerSelectedLicense')) === licenseTypes.REG_LICENSE_DEMO ? ' license-select-disabled' : '')}>
                {props.offer && !!props.offer.length && props.offer.map((item, index) => {
                    if (item.licenseType !== licenseTypes.REG_LICENSE_DEMO) {
                        return (
                            <Col className='license-card-style' key={'CO_' + index}>
                                <LicenseCardItem
                                    src={licenseOfferConfig.licenseOfferBkgImage[item.licenseType]}
                                    text={licenseOfferConfig.licenseOfferSelectionText}
                                    textSelected={licenseOfferConfig.licenseOfferSelectedText}
                                    textPricePerPeriod={licenseOfferConfig.licenseOfferPeriodText}
                                    label={t('app.pages.licenseCards.labels.license') + " " +
                                        (item.licenseType === licenseTypes.REG_LICENSE_BASIC ? t('app.pages.licenseCards.labels.basic') :
                                            (item.licenseType === licenseTypes.REG_LICENSE_ADVANCED ? t('app.pages.licenseCards.labels.advanced') :
                                                (item.licenseType === licenseTypes.REG_LICENSE_PROFESSIONAL ? t('app.pages.licenseCards.labels.professional') : "UNKNOWN")))
                                    }
                                    licenseMaxDevices={t('app.pages.licenseCards.labels.maxAvailDevices')}
                                    licenseMaxStorage={t('app.pages.licenseCards.labels.maxAvailStorage')}
                                    licenseDetails={item}
                                    key={'LO_' + index}
                                />
                            </Col>
                        )
                    }
                })}
            </Row>
            <Row>
                <div className="offer-sticky-parent">
                    <img className="img-fluid offer-sticky" src="images/rabat_12mc.png" title={t('app.pages.licenseCards.labels.discountText')}></img>
                </div>
            </Row>
        </FormGroup>
    );
}

export default LicenseCards;
