import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Media, Row, Col, Form, FormGroup,
         Label, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next';

import { apiSelectDeviceImage, apiUnselectDeviceImage } from "../../../../../middleware/device/duck/api"
import { setFieldValidationError, setFieldValidationReset, getCheckboxChecked, setCheckboxChecked } from "../../../../../utilities/dom"
import { timestampToDateTimeStr } from "../../../../../utilities/common"
import { getWebUrl } from "../../../../../utilities/api"
import * as system from "../../../../../utilities/system"

import "./modalResourceTag.css";


const ModalResourceTag = (props) => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [tagFolderDropdownOpen, setTagFolderDropdownOpen] = useState(false);
    const toggleTagFolder = () => setTagFolderDropdownOpen(prevState => !prevState);
    const [tagFolder, setTagFolder] = useState(t('app.pages.deviceInfo.modals.modalResourceTag.buttons.dropdown.selectTag.menuitemdef'));
    const [tagFolderType, setTagFolderType] = useState('Default');

    const [removalConfirmed, setConfirmRemoval] = useState(false);
    const toggleRemovalConfirmed = () => setConfirmRemoval(prevState => !prevState);

    const toggleCheckImage = (id) => {
        if (selectedImageList.indexOf(id) > -1) {
            props.apiUnselectDeviceImage(id)
        } else {
            props.apiSelectDeviceImage(id)
        }
    }

    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;
    
    const {
        //modalOpenMode,
        //modalOpenWith,
        //modalOpenWithClass,
        //modalOpenBtnTitle,
        modalHeaderTitle,
        modalHeaderTitle2,
        modalClassName,
        //modalMediaPath,
        //modalMediaFilename,
        //modalMediaFilesize,
        //modalMediaKey,
        //modalMediaTags,
        modalMediaDevice,
        modalMediaCurrentTag,
        modalOnLoadHandler,
        modalOnSubmitHandler,
        selectedImageList,
        userRole
    } = props;

    const handleValidateForm = () => {
        if (!removalConfirmed) {
            if (tagFolder === "" || tagFolder === t('app.pages.deviceInfo.modals.modalResourceTag.buttons.dropdown.selectTag.menuitemdef')) {
                setFieldValidationError("tagFolder")
                return false
            }
        }

        return true
    }

    const handleOnLoadForm = (e) => {
        if (e.shiftKey) {
            toggleCheckImage(props.modalMediaKey)

            let value = getCheckboxChecked("cb-image-" + props.modalMediaKey)
            setCheckboxChecked("cb-image-" + props.modalMediaKey, !value)
        } else {
            modalOnLoadHandler()
            toggle()
        }
    }

    const handleOnSelectImage = (e) => {
        if (e.shiftKey) {
            toggleCheckImage(props.modalMediaKey)

            let value = getCheckboxChecked("cb-image-" + props.modalMediaKey)
            setCheckboxChecked("cb-image-" + props.modalMediaKey, !value)
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()
        if (!handleValidateForm()) {
            return
        }

        modalOnSubmitHandler(removalConfirmed ? "REMOVE" : "TAG", tagFolder, tagFolderType)
        toggle()
    }

    const Checkbox = (props) => (
        <input type="checkbox" {...props} />
    )

    try {
        const imageFile = getWebUrl() + "/" + props.modalMediaPath + "/" + props.modalMediaFilename

        return (
            <>
                <Media object src={imageFile} className="device-item-image" key={props.modalMediaKey} onClick={handleOnLoadForm} title={t('app.pages.deviceInfo.modals.modalResourceTag.hints.editImage')}></Media>
                <Checkbox className="checkbox-media" name={"cb-image-" + props.modalMediaKey} data-name={"cb-image-" + props.modalMediaKey} data-id={props.modalMediaKey} title={t('app.pages.deviceInfo.modals.modalResourceTag.hints.selectImage')} defaultChecked={(selectedImageList.indexOf(props.modalMediaKey) > -1)} onChange={() => toggleCheckImage(props.modalMediaKey)}></Checkbox>
                <a style={{display: 'none'}} data-name={"a-image-download-" + props.modalMediaKey} data-id={props.modalMediaKey} href={imageFile} download>_</a>
                <Modal isOpen={modal} toggle={toggle} className={modalClassName} size="lg" draggable={false} centered={true} key={"M_" + props.modalMediaKey}>
                    <ModalHeader toggle={toggle} close={closeBtn}><i className={removalConfirmed ? "fas fa-trash-alt" : "fas fa-tags"}></i>&nbsp;{removalConfirmed ? modalHeaderTitle2 : modalHeaderTitle}</ModalHeader>
                    <ModalBody>
                        <Form className="resource-tag-form" autoComplete="off">
                            <Row>
                                <Col>
                                    {([system.roles.ROLE_ADMIN, system.roles.ROLE_MODERATOR].includes(props.userRole)) ? (
                                        <a href={imageFile} target="_new" title={t('app.pages.deviceInfo.modals.modalResourceTag.hints.openImage')}><Media object src={imageFile} className="device-item-image-form"></Media></a>
                                    ) : (
                                        <Media object src={imageFile} className="device-item-image-form"></Media>
                                    )}
                                    <div className="divider-small"></div>
                                    <Label className="device-item-image-label-form">{t('app.pages.deviceInfo.modals.modalResourceTag.strings.capturingDevice')} {modalMediaDevice}</Label>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="device-item-image-title-form">{t('app.pages.deviceInfo.modals.modalResourceTag.headlines.imageDetails')}</Label>
                                        <Label className="device-item-image-label-form">{t('app.pages.deviceInfo.modals.modalResourceTag.labels.fileName')}&nbsp;
                                            {([system.roles.ROLE_ADMIN, system.roles.ROLE_MODERATOR].includes(props.userRole)) ? (
                                                <a className="device-item-lnk-form underline" href={imageFile} download title={t('app.pages.deviceInfo.modals.modalResourceTag.hints.dnldImage')}>{props.modalMediaFilename}</a>
                                            ) : (
                                                <span className="underline">{props.modalMediaFilename}</span>
                                            )}
                                        </Label>
                                        <Label className="device-item-image-label-form">{t('app.pages.deviceInfo.modals.modalResourceTag.labels.date')} {timestampToDateTimeStr(props.modalMediaFilename)}</Label><br/>
                                        <Label className="device-item-image-label-form">{t('app.pages.deviceInfo.modals.modalResourceTag.labels.size')} {props.modalMediaFilesize}</Label><br/>
                                        <Label className="device-item-image-label-form">{t('app.pages.deviceInfo.modals.modalResourceTag.labels.currentTag')} {modalMediaCurrentTag}</Label>

                                        <div className="divider-small"></div>
                                        <FormGroup>
                                            <Label className="device-item-image-title-form">{t('app.pages.deviceInfo.modals.modalResourceTag.headlines.imageTagging')}</Label>

                                            <Dropdown className={tagFolder} isOpen={tagFolderDropdownOpen} toggle={toggleTagFolder}>
                                                {removalConfirmed ? (
                                                    <DropdownToggle className="tagFolder disabled readonly" caret disabled>
                                                        {tagFolder}
                                                    </DropdownToggle>
                                                ) : (
                                                    <DropdownToggle className="tagFolder" caret>
                                                        {tagFolder}
                                                    </DropdownToggle>
                                                )}
                                                <DropdownMenu>
                                                    <DropdownItem header>{t('app.pages.deviceInfo.modals.modalResourceTag.buttons.dropdown.selectTag.header')}</DropdownItem>
                                                    {props.modalMediaTags.map((item, index) => {
                                                        if (modalMediaCurrentTag !== item.name) {
                                                            return (
                                                                <DropdownItem onClick={() => {
                                                                        setTagFolder(item.name)
                                                                        setTagFolderType(item.folderType)
                                                                        setFieldValidationReset("tagFolder")
                                                                    }
                                                                } key={index}>{item.name}</DropdownItem>
                                                            )
                                                        }
                                                        else {
                                                            return (<div key={index}></div>)
                                                        }
                                                    })}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </FormGroup>
                                        {([system.roles.ROLE_ADMIN, system.roles.ROLE_MODERATOR].includes(userRole)) ? (
                                            <>
                                                <div className="divider-small"></div>
                                                <FormGroup>
                                                    <Label className="device-item-image-title-form">{t('app.pages.deviceInfo.modals.modalResourceTag.headlines.imageRemoval')}</Label>
                                                    <div>
                                                        <Checkbox className="checkbox-form" name="cb-confirm-removal" title={t('app.pages.deviceInfo.modals.modalResourceTag.strings.confirmRemoval')} defaultChecked={removalConfirmed} onChange={toggleRemovalConfirmed}></Checkbox>
                                                        <Label className="device-item-image-label-form cursor-pointer" style={{paddingLeft: '10px'}} onClick={toggleRemovalConfirmed}>{t('app.pages.deviceInfo.modals.modalResourceTag.strings.confirmRemoval')}</Label>
                                                    </div>
                                                </FormGroup>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {removalConfirmed ? (
                            <Button color="warning" className="btn-large" onClick={handleOnSubmit}>{t('app.pages.deviceInfo.modals.modalResourceTag.buttons.submitText2')}</Button>
                        ) : (
                            <Button color="warning" className="btn-large" onClick={handleOnSubmit}>{t('app.pages.deviceInfo.modals.modalResourceTag.buttons.submitText1')}</Button>
                        )}{' '}
                        <Button color="secondary" className="transparency btn-large" onClick={toggle}>{t('app.pages.deviceInfo.modals.modalResourceTag.buttons.cancelText')}</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    } catch (e) {
        console.log('Except: ' + e)

        return (
            <>
                <Media object src={require("../../../../../resources/images/no_image.png")} key={"E2_" + props.modalMediaKey} onClick={handleOnSelectImage} className="device-item-no-image"></Media>
                <Checkbox className="checkbox-media" name={"cb-image-" + props.modalMediaKey} data-name={"cb-image-" + props.modalMediaKey} data-id={props.modalMediaKey} title={t('app.pages.deviceInfo.modals.modalResourceTag.hints.selectImage')} defaultChecked={(selectedImageList.indexOf(props.modalMediaKey) > -1)} onChange={() => toggleCheckImage(props.modalMediaKey)}></Checkbox>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    // map store parameters to the underlying component (props)
    selectedImageList: state.device.deviceSelectedImageList || [],
    userRole: state.session.profile.roleName || 'Standard'
});

const mapDispatchToProps = dispatch => ({
    // wrapper to a thunk function, instead of calling store.dispatch(action)
    apiSelectDeviceImage: (id) =>
        dispatch(apiSelectDeviceImage(id)),
    apiUnselectDeviceImage: (id) =>
        dispatch(apiUnselectDeviceImage(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalResourceTag);